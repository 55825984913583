.max-width--10 {
	flex-basis: 10%;
	max-width: 10%;
}

.max-width--20 {
	flex-basis: 20%;
	max-width: 20%;
}

.max-width--30 {
	flex-basis: 30%;
	max-width: 30%;
}

.max-width--40 {
	flex-basis: 40%;
	max-width: 40%;
}

.max-width--50 {
	flex-basis: 50%;
	max-width: 50%;
}

.max-width--60 {
	flex-basis: 60%;
	max-width: 60%;
}

.max-width--70 {
	flex-basis: 70%;
	max-width: 70%;
}


.max-width--80 {
	flex-basis: 80%;
	max-width: 80%;
}

.max-width--90 {
	flex-basis: 90%;
	max-width: 90%;
}

.max-width--100 {
	flex-basis: 100%;
	max-width: 100%;
}
