/**
	ROOT FRAMEWORK
**/

//bootstrap
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/tooltip";

@import "custom/font";
@import "custom/variables";
@import "functions/mixins";
/**
	CUSTOM COMPONENTS
**/

//slick-slider
@import "../../node_modules/slick-carousel/slick/slick";


/**
	CUSTOM STYLE
**/

@import "custom/theme/textColor";
@import "custom/theme/background";
@import "custom/theme/style";
@import "custom/theme/spacing";
@import "custom/search";

@import "custom/common/grid";
@import "custom/common/animation";
@import "custom/common/minHeight";
@import "custom/common/overlay";
@import "custom/common/position";
@import "custom/common/width";
@import "custom/common/zindex";
@import "custom/common/button";

@import "custom/header/header";
@import "custom/header/navigation";

@import "custom/blocks/Hero/hero";
@import "custom/blocks/buttons";
@import "custom/blocks/divider/divider";
@import "custom/blocks/slider/partnerLogo";
@import "custom/blocks/slider/slick";
@import "custom/blocks/slider/banner/banner";
@import "custom/blocks/image/image";
@import "custom/blocks/testimonials/testimonials";
@import "custom/blocks/slider/imageslider";
@import "custom/blocks/news/news";
@import "custom/blocks/paging/paging";
@import "custom/blocks/footer/footer";
@import "custom/blocks/cards";
@import "custom/blocks/portfolio/portfolio";
@import "custom/blocks/forms/forms";
@import "custom/blocks/icons/iconService";
@import "custom/categories";
@import "custom/product";
@import "custom/modal";
