﻿/*======================================
  #Service Pods
======================================*/

.grid-item--service .gis-image {
  overflow: hidden;
  color: $g-primary;
}
.grid-item--service .gis-image i {
  font-size: 70px;
  margin-bottom: 5px;
  line-height: 1;
}
.grid-item--service .gis-text {
  margin-top: 10px;
}
.grid-item--service.grid-item--boxed {
  padding: 0;
}
.grid-item--service.grid-item--boxed .gis-text {
  margin: 0;
  padding: 20px 30px 30px;
}
.grid-item--service.grid-item--boxed .gis-image i {
  margin-top: 30px;
}
.grid-item--service.grid-item--service-compact {
  position: relative;
  padding-left: 60px;
}
.grid-item--service.grid-item--service-compact.text-center {
  padding: 0;
  display: flex;
  justify-content: center;
}
.grid-item--service.grid-item--service-compact.text-center article {
  position: relative;
  text-align: left;
  padding-left: 60px;
}
.grid-item--service.grid-item--service-compact.text-center article p {
  margin-bottom: 0;
}
.grid-item--service.grid-item--service-compact .gis-text {
  margin-top: 0;
  font-size: 14px;
  line-height: 20px;
}
.grid-item--service.grid-item--service-compact .gis-text h5 {
  font-size: 16px;
  margin-bottom: 0;
}
.grid-item--service.grid-item--service-compact .gis-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  text-align: center;
}
.grid-item--service.grid-item--service-compact .gis-image i {
  font-size: 30px;
  margin: 0;
}
.grid-item--service.grid-item--service-compact.grid-item--boxed {
  padding-left: 90px;
}
.grid-item--service.grid-item--service-compact.grid-item--boxed .gis-image {
  top: 30px;
  left: 30px;
}

/*======================================
  #Grid
======================================*/
.grid {
  position: relative;
}

.grid-item img {
  height: auto !important;
}

.row--flat .grid-item {
  margin-bottom: 0;
}

.grid-item--boxed {
  padding: 30px;
  background: white;
  box-shadow: 0 22px 43px -20px rgba(0, 0, 0, 0.15);
}

.block--open-padding .grid-item--boxed,
.white-bg .grid-item--boxed {
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: none;
}