﻿.divider-- {
	height: 1px;

	&1 {
		height: 1px;
	}

	&2 {
		height: 2px;
	}

	&3 {
		height: 3px;
	}

	&4 {
		height: 4px;
	}

	&5 {
		height: 5px;
	}

	&6 {
		height: 6px;
	}

	&7 {
		height: 7px;
	}

	&8 {
		height: 8px;
	}

	&9 {
		height: 9px;
	}

	&10 {
		height: 10px;
	}
}
