/*
 * Streamline AG - Variables
 */

// Retreive color Sass maps
@function theme-color($hs-key: "primary") {
	@return map-get($g-theme-colors, $hs-key);
}

@function accent-color($hs-key: "accent") {
	@return map-get($g-accent-colors, $hs-key);
}



/*
 * Theme
 */


// Typography
// =============================================================

// Font Families
$g-font-body: 'Lato', sans-serif;
$g-font-heading: 'Lato', sans-serif;


// Font Sizes
$g-font-size-base: 1rem !default;

// Font Weight
$g-font-weight-light: 100 !default;
$g-font-weight-normal: 300 !default;
$g-font-weight-bold: 600 !default;

$g-headings-font-weight: $g-font-weight-light !default;
$g-line-height: 1.6 !default;
$g-headings-line-height: 1.6 !default;
$g-paragraph-line-height: 1.8 !default;
$g-outline: 0px;


// Header (ATTETNTION these values have to match with the Theme-settings of the backoffice)
$g-headerHeight: 75px;
$g-headerPadding: 10px;
$g-scrollHeaderHeight: 60px;

$g-contentHeaderMarginTop: 196px;


// Breakpoints
// =========================================================

$g-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 1025px, xl: 1200px ) !default;


// Spacing
// =========================================================
$g-section-spacing: 4.25rem;

// Color
// =============================================================
// Main Colors
$g-light: #fff !default;
$g-dark: #333 !default;
$g-transparent: transparent !default;
$g-grey: #efefef !default;

// Theme Colors
$g-primary: #AF1328 !default;
$g-secondary: #205CB7 !default;

$g-success: green !default;
$g-info: blue !default;
$g-warning: yellow !default;
$g-danger: red !default;

//Social-Media
$g-facebook: #3b5998 !default;
$g-google: #d14130 !default;
$g-twitter: #1da1f2 !default;
$g-instagram: #3f729b !default;
$g-github: #24292e !default;


/*// Social Network Colors
$g-social-network-colors: () !default;
$g-social-network-colors: map-merge(( "facebook": $g-facebook, "google": $g-google, "twitter": $g-twitter, "instagram": $g-instagram, "github": $g-github ), $g-social-network-colors);*/


/*$g-theme-colors: () !default;
$g-theme-colors: map-merge(( "primary": $g-primary, "secondary": $g-secondary, "success": $g-success, "info": $g-info, "warning": $g-warning, "danger": $g-danger, "light": $g-light, "dark": $g-dark ), $g-theme-colors);

// Override Bootstrap
$theme-colors: $g-theme-colors;*/


// Links (Style anchor elements)
$g-link-color: $g-primary !default;
$g-link-color-hover: lighten($g-primary, 7.5%) !default;
$g-link-hover-decoration: none !default;


//Background
$g-bg-light: $g-light;
$g-bg-dark: $g-dark;
$g-bg-primary: $g-primary;
$g-bg-secondary: $g-secondary;
$g-bg-grey: $g-grey;


/*// Content Spacing
// =============================================================
$g-content-space-y: 4.25rem !default

$g-spacer: 1rem !default;
$g-spacers: () !default;
$g-spacers: map-merge(( 0: 0, 1: ($g-spacer * .25), 2: ($g-spacer * .5), 3: $g-spacer, 4: ($g-spacer * 1.5), 5: ($g-spacer * 2), 6: ($g-spacer * 2.5), 7: ($g-spacer * 3), 8: ($g-spacer * 3.5), 9: ($g-spacer * 4) ), $g-spacers);*/


/*// Box Shadow
// =============================================================

$g-box-shadow-sm: 0 .20rem 1.25rem 0 rgba($g-dark, 0.07) !default
$g-box-shadow-lg: 0 .4rem 2.2rem 0 rgba($g-dark, 0.1) !default*/



/*// Pagination
// =============================================================

// Colors
$g-pagination-color: $g-secondary !default;

// Border
$g-pagination-border-color: $g-grey !default;

// Hover
$g-pagination-hover-color: $g-light !default;
$g-pagination-hover-bg: $g-primary !default;
$g-pagination-hover-border-color: $g-primary !default;


// Active
$g-pagination-active-color: $g-light !default;
$g-pagination-active-bg: $g-primary !default;
$g-pagination-active-border-color: $g-primary !default;



// Border Radius
// =============================================================

$g-border-radius: .25rem !default;
$g-border-radius-pill: 6.25rem !default;
$g-border-radius-circle: 50% !default;*/



/*// Forms Inputs
// =============================================================

// Color
$g-input-color: $g-dark !default;
$g-input-group-addon-color: $g-gray-200 !default;

// Border Colors
$g-form-border-color: $g-grey !default;
$g-input-border-color: $g-grey !default;
$g-input-focus-border-color: lighten($g-primary, 30%) !default;

// Background Colors
$g-input-group-addon-bg: transparent !default;

// Focus Colors
$g-input-focus-color: $g-dark !default;
$g-input-placeholder-color: $g-gray-400 !default;

// Box Shadow
$g-input-focus-box-shadow: none !default;



// Forms States + Icon
// =============================================================

// Form Icon
$g-form-icon-color: $g-gray-200 !default;

// Forms States (e.g. Disabled)
$g-form-label-color-disabled: $g-text-muted !default;
$g-custom-control-indicator-disabled-bg: $g-gray-150 !default;



// Form Toggles
// =============================================================

// Unchecked (Off)
$g-form-toggle-color: $g-gray-150 !default;
$g-form-toggle-border-color: $g-gray-150 !default;
$g-form-toggle-circle-bg-color: $g-gray-150 !default;

// Checked (On)
$g-form-toggle-color-checked: $g-white !default;
$g-form-toggle-bg-color-checked: theme-color("primary") !default;
$g-form-toggle-border-color-checked: theme-color("primary") !default;
$g-form-toggle-circle-bg-color-checked: $g-white !default;

// Disabled
$g-form-toggle-color-disabled: $g-gray-100 !default;
$g-form-toggle-border-color-disabled: $g-gray-100 !default;
$g-form-toggle-circle-bg-color-disabled: $g-gray-100 !default;

// Disabled + Checked (On)
$g-form-toggle-bg-color-disabled-checked: $g-gray-100 !default;
$g-form-toggle-border-color-disabled-checked: $g-gray-100 !default;



// Close
// =============================================================

$g-close-font-size: $g-font-size-base * 2 !default;
$g-close-font-weight: $g-font-weight-light !default;
$g-close-color: $g-dark !default;
$g-close-text-shadow: none !default;



// Alert
// =============================================================

// Spacing
$g-alert-padding-y: 1rem !default;
$g-alert-padding-x: 1.25rem !default;

// Border
$g-alert-border-width: 0 !default;



// Progress Bars
// =============================================================

$g-progress-bg: $g-gray-50 !default;



// List Groups
// =============================================================

$g-list-group-border-color: $g-gray-100 !default;
$g-list-group-hover-bg: theme-color("light") !default;



// Blockquote
// =============================================================

// Spacing
$g-blockquote-padding-y: 1.5rem !default;
$g-blockquote-padding-x: 1.25rem !default;

// Background Color
$g-blockquote-bg: theme-color("light") !default;

// Border Color
$g-blockquote-border-color: theme-color("primary") !default;

// Content
$g-blockquote-small-color: $g-dark !default;
$g-blockquote-font-size: ($g-font-size-base * 1.25) !default;
$g-blockquote-font-weight: $g-font-weight-light !default;



// Card
// =============================================================

$g-card-cap-bg: theme-color("light") !default;
$g-card-border-color: $g-gray-100 !default;



// Modals
// =============================================================

// Background Color
$g-modal-backdrop-bg: $g-dark !default;
$g-modal-backdrop-opacity: .1 !default;

// Border Color
$g-modal-content-border-color: $g-gray-100 !default;

// Box Shadow
$g-modal-content-box-shadow-xs: 0 .25rem .6rem rgba(theme-color("dark"), .07) !default;
$g-modal-content-box-shadow-sm-up: 0 .5rem 1.625rem rgba(theme-color("dark"), .07) !default;

// Sizes
$g-modal-lg: 800px !default;
$g-modal-md: 500px !default;
$g-modal-sm: 350px !default;



// Popovers
// =============================================================

// Background Color
$g-popover-header-bg: theme-color("light") !default;

// Border Color
$g-popover-border-color: $g-gray-100 !default;

// Box Shadow
$g-popover-box-shadow: 0 .25rem .6rem rgba(theme-color("dark"), .07) !default;



// Navbar
// =============================================================

// Dark Mode Colors
$g-navbar-dark-color: rgba($g-white, .9) !default;
$g-navbar-dark-hover-color: $g-white !default;

// Onscroll
$navbar-bg-onscroll: lighten(theme-color("primary"), 5%) !default;



// Drop Down
// =============================================================

// $g-dropdown-link-active-bg:                                     transparent !default;
$g-dropdown-link-hover-bg: theme-color("light") !default;
$g-dropdown-border-color: $g-gray-100 !default;

// Dropdown menu container and contents.
$g-dropdown-min-width: 200px !default;
$g-dropdown-box-shadow: 0 .4rem 2.2rem 0 rgba(27,30,36, .1) !default;



// Nav Tabs
// =============================================================

// Active Color
$nav-tabs-link-active-color: $g-dark !default;

// Border Color
$g-nav-tabs-border-color: $g-gray-100 !default;



// Carousel
// =============================================================

$g-carousel-control-color: $g-white !default;
$g-carousel-control-size: 2rem !default;



// Tooltips
// =============================================================

$g-tooltip-max-width: 200px !default;
$g-tooltip-color: $g-white !default;
$g-tooltip-bg: theme-color("primary") !default;
$g-tooltip-opacity: 1 !default;
$g-tooltip-padding-y: .5rem !default;
$g-tooltip-padding-x: 1rem !default;


// Text Inputs + Textarea
$input-height: 4rem !default;
$input-border-color: $g-gray-200 !default;
$input-border: 1px solid $input-border-color !default;
$input-background: #fff !default;
$input-error-color: $g-danger !default;
$input-success-color: $g-success !default;
$input-focus-color: $g-blue !default;
$input-font-size: 1.3rem !default;
$input-margin-bottom: 20px;
$input-margin: 0 0 $input-margin-bottom 0 !default;
$input-padding: 0 !default;
$input-transition: all .3s !default;
$label-font-size: 1.3rem !default;
$input-disabled-color: rgba(0,0,0, .42) !default;
$input-disabled-solid-color: #949494 !default;
$input-disabled-border: 1px dotted $input-disabled-color !default;
$input-invalid-border: 1px solid $input-error-color !default;
$placeholder-text-color: lighten($input-border-color, 20%) !default;
$gutter-width: 1.5rem !default;
$dropdown-item-height: 50px !default;*/
