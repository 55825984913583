/*======================================
  #Hero
======================================*/
.hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 0 0 80px 0;
  max-width: 100%;
  margin-top: $g-contentHeaderMarginTop;

  @include media-breakpoint-down(md) {
    /*padding: 60px 0;*/
    margin-top: ($g-contentHeaderMarginTop - 60px);    
  }

  .hero-background {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
  }

  .hero-inner {
    flex-grow: 1;
    width: 100%;
    position: relative;
    z-index: 10;

    @include linear-gradient(#aa1427, #e42323, top);
    color: $g-light;
    max-width:650px;        
    text-transform: uppercase;

    h1 {
      font-size: 44px;
      margin-bottom: 0;
      margin-top:0;

      @include media-breakpoint-down(md) {
        font-size: 36px;
      }
    }

    .hero-text {
      font-weight: 300;
      font-size: 26px;
      line-height: 28px;
      margin: 20px 0 0;

      @include media-breakpoint-down(md) {
        font-size: 22px;
        line-height: 24px;
      }
    }

    .hero-text p:last-of-type {
      margin-bottom: 0;
    }

    .button-wrap {
      margin-top: 30px;

      .button + .button {
        margin-left: 10px;
      }
    }
  }
}

/*======================================
  #Hero animation
======================================*/
.hero--animation {
	overflow: hidden;

	.hero-background {
		transform: scale(1.4);
		transition: all 2s ease;
		opacity: 0;
	}

	.hero-inner {
		opacity: 0;
		transform: scale(0.90) translateZ(0);
		transition: all .7s ease .1s;

		.button-wrap .button {
			transform: scale(1.2) translateZ(0);
			opacity: 0;
			transition: opacity .5s ease .5s, background .3s ease 0s, color .3s ease 0s, -webkit-transform .5s ease .5s;
			transition: opacity .5s ease .5s, transform .5s ease .5s, background .3s ease 0s, color .3s ease 0s;
			transition: opacity .5s ease .5s, transform .5s ease .5s, background .3s ease 0s, color .3s ease 0s, -webkit-transform .5s ease .5s;
		}

		.button-wrap .button:nth-child(2) {
			transition: opacity .5s ease .7s, background .3s ease 0s, color .3s ease 0s, -webkit-transform .5s ease .7s;
			transition: opacity .5s ease .7s, transform .5s ease .7s, background .3s ease 0s, color .3s ease 0s;
			transition: opacity .5s ease .7s, transform .5s ease .7s, background .3s ease 0s, color .3s ease 0s, -webkit-transform .5s ease .7s;
		}

		.button-wrap .button:nth-child(3) {
			transition: opacity .5s ease .9s, background .3s ease 0s, color .3s ease 0s, -webkit-transform .5s ease .9s;
			transition: opacity .5s ease .9s, transform .5s ease .9s, background .3s ease 0s, color .3s ease 0s;
			transition: opacity .5s ease .9s, transform .5s ease .9s, background .3s ease 0s, color .3s ease 0s, -webkit-transform .5s ease .9s;
		}
	}
}

.page-loaded {
	.hero--animation {
		.hero-background {
			transform: scale(1);
			opacity: 1;
		}

		.hero-inner {
			opacity: 1;
			transform: scale(1) translateZ(0);
		}

		.button-wrap .button {
			transform: scale(1) translateZ(0);
			opacity: 1;
		}
	}
}


/*======================================
  #Scroll icon
======================================*/
.icon-scroll {
	position: absolute;
	bottom: 30px;
	left: 50%;
	color: white;
	z-index: 9;
	width: 30px;
	text-align: center;
	margin-left: -15px;
	animation-duration: 2.2s;
	animation-iteration-count: infinite;
	animation-name: scroll;

	.overlay--light {
		color: $g-light;
	}
}


/*======================================
  #Video
======================================*/
.hero-video {
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	top: 0;
	z-index: 2;
	overflow: hidden;

	.hero-video--player {
		position: absolute;
		min-width: 100%;
		min-height: 100%;
		object-fit: cover;
		object-position: center;
		font-family: 'object-fit: cover;';
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		/*@include media-breakpoint-down(sm) {
			display: none;
		}*/
	}
}

.video-mute {
	position: absolute;
	bottom: 30px;
	left: 30px;
	z-index: 12;
	color: #111;
	background-color: white;
	width: 40px;
	height: 40px;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	cursor: pointer;
	box-shadow: 0 0 0 rgba(255, 255, 255, 0.8);
	animation: pulse 1.35s infinite;
	transition: all .3s ease;

	&:active {
		transform: scale(0.5);
	}

	.fa-volume-off {
		display: none;
	}

	.sound-on {
		background-color: rgba(0, 0, 0, 0.3);
		box-shadow: none;
		animation: none;
		color: white;

		&:hover {
			background-color: rgba(0, 0, 0, 0.5);
		}

		.fa-volume-up {
			display: none;
		}

		.fa-volume-off {
			display: block;
		}
	}
}

