﻿/*======================================
  #Search
======================================*/
.search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);

  input {
    display: block;
    padding: 15px;
    color: #444;
    margin-bottom: 20px;
    font-size: 16px;
    width: 100%;
    border: 0;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.08);
    transition: all .25s ease;
    margin-bottom: 30px;
    outline: 0;
  }

  .search__close {
    position: absolute;
    top: 50%;
    right: 30px;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    transition: all .3s ease;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.8) rotate(90deg);
    background: transparent;
    border: 0;
    z-index: 10;
  }

  @media only screen and (max-width: 992px) {
    .search__close {
      width: 30px;
      height: 30px;
      font-size: 14px;
      right: 15px;
      margin-top: -15px;
    }
  }

  .search__stage {
    max-width: 600px;
    margin: 0 auto;
  }

  .search__form {
    display: flex;
    transition: all .3s ease;
    opacity: 0;
    transform: scale(0.9) translateX(50px);
    position: relative;

    .fa-search {
      position: absolute;
      top: 50%;
      left: 23px;
      opacity: .3;
      font-size: 18px;
      line-height: 1;
      z-index: 1;
      transform: translateY(-50%);
    }

    .search__input {
      margin-bottom: 0;
      font-size: 18px;
      padding-left: 55px;
      box-shadow: none !important;
      border: 0 !important;

      @include media-breakpoint-down(md) {
        max-width: 80%;
      }
    }
  }
}

.search__form .search__input:required:valid {
  box-shadow: none !important;
}

.search__form .search-btn {
  top: 0px;
  right: 10px;
  padding: 0;
  width: 60px;
  overflow: hidden;
  transition: all .3s ease;
}

.search__form .search-btn[disabled] {
  width: 0;
}

@media only screen and (max-width: 992px) {
  .search__form .search__input {
    font-size: 16px;
  }
}

.search-overlay {
  background-color: #000;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  transition: all .3s ease;
  cursor: pointer;
  visibility: hidden;
}

@media only screen and (max-width: 992px) {
  .search-visible {
    overflow: hidden;
  }
}

.search-visible .search {
  visibility: visible;
  opacity: 1;
}

.search-visible .search__close {
  opacity: 1;
  visibility: visible;
  transform: scale(1) rotate(0);
  transition-delay: .5s;
}

.search-visible .search__form {
  opacity: 1;
  transition-delay: .3s;
  transform: scale(1) translateX(0);
}

.search-visible .search-overlay {
  opacity: .3;
  visibility: visible;
  transition-duration: .6s;
}
/*======================================
  #Search Block
======================================*/
.search-block {
  overflow: hidden;
  position: relative;
  padding: 60px 0 50px !important;
}

.search-result-info {
  padding: 0 20px;
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
}

.search-block .search__form {
  visibility: visible;
  opacity: 1;
  transform: none;
  position: relative;
  z-index: 2;
}

.search-block .search__form input {
  box-shadow: 0 8px 20px -6px rgba(0, 0, 0, 0.1) !important;
}
/*======================================
  #Search Results
======================================*/
.search-result {
  background-color: white;
  padding: 40px;
  box-shadow: 0 22px 43px -20px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  margin-bottom: 10px;
}

.search-result:hover {
  box-shadow: 0 12px 23px -15px rgba(0, 0, 0, 0.1);
}

.search-result p:last-of-type {
  margin-bottom: 0;
}

@media only screen and (max-width: 768px) {
  .search-result {
    padding: 20px;
  }
}
/*======================================
  #Search - No Results
======================================*/
.search-empty {
  width: 100%;
  padding: 5vh 20px;
  text-align: center;
  color: #999;
}

.search-empty h3,
.search-empty h4,
.search-empty h5 {
  color: #999;
}

.search-empty > i {
  font-size: 60px;
  display: block;
  line-height: 1;
  opacity: .3;
  margin-bottom: 20px;
}
/*======================================
  #Search
======================================*/
.toggle-search a {
  font-size: 17px !important;
  text-align: center;
  padding: 0 !important;
}

.mobile-actions {
  display: none;
  right: 50px;
  position: absolute;
  top: 0;
}

@media only screen and (max-width: 992px) {
  .mobile-actions {
    display: block;
  }
}

.mobile-actions ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.mobile-actions ul li a {
  width: 50px;
  height: 50px;
  font-size: 19px;
  display: flex;
  color: white;
  line-height: 1;
  align-items: center;
  justify-content: center;
}

.top--light .mobile-actions ul li a {
  color: #111;
}

.top--scroll-dark .mobile-actions ul li a {
  color: #111;
}

.top--scroll-light .mobile-actions ul li a {
  color: white;
}

.scroll .top--light .mobile-actions ul li a {
  color: #111;
}

.scroll .top--dark .mobile-actions ul li a {
  color: white;
}


.search__form {
  a:before {
    font-family: FontAwesome;
    content: "\f061";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
  }
}

.search-btn {
  @include media-breakpoint-down(md) {
    line-height: 4rem !important;
  }
}
