﻿.grid-image {
	width: 50%;
	display: flex;
	flex-flow: row wrap;
	border: 5px solid rgba(255, 255, 255, 0.00);
	padding: 0;

	@include media-breakpoint-down(md) {
		order: 1;
		width: 100%;
		min-height: 400px;
		border: 5px solid rgba(255, 255, 255, 0.00);
	}

	span {
		padding-left: 5px;
	}

	a:hover {
		overflow: hidden;

		.grid-image-item {
			transform: scale(1.01);
		}
	}

	.grid-image-item {
		flex-grow: 1;
		width: 100%;
		min-height: 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
}


	.grid-image.grid-image--2 .grid-image-item {
		width: 50%;
	}

	.grid-image.grid-image--3 .grid-image-item {
		width: 50%;
		min-height: 50%;
	}

	.grid-image.grid-image--3 .grid-image-item:nth-child(1) {
		width: 100%;
	}

	.grid-image.grid-image--4 .grid-image-item {
		width: 50%;
		min-height: 50%;
	}

	.grid-image.grid-image--5 .grid-image-item:nth-child(1),
	.grid-image.grid-image--5 .grid-image-item:nth-child(2) {
		width: 50%;
		min-height: 60%;
	}

	.grid-image.grid-image--5 .grid-image-item {
		width: 33.3333333%;
		min-height: 40%;
	}

	.grid-image.grid-image--6 .grid-image-item {
		width: 33.3333333%;
		min-height: 50%;
	}


	.no-border {
		.grid-image {
			border: none;
		}

		.grid-image-item {
			border: none;
		}

		span {
			padding-left: 0px;
		}

		figure {
			padding: 0px !important;
		}
	}

	.bg--light {
		.grid-image {
			border-color: $g-light;
		}

		.grid-image-item {
			border-color: $g-light;
		}
	}

	.bg--dark {
		.grid-image {
			border-color: $g-dark;
		}

		.grid-image-item {
			border-color: $g-dark;
		}
	}

	.bg--grey {
		.grid-image {
			border-color: $g-grey;
		}

		.grid-image-item {
			border-color: $g-grey;
		}
	}

	.bg--primary {
		.grid-image {
			border-color: $g-primary;
		}

		.grid-image-item {
			border-color: $g-primary;
		}
	}

	.bg--secondary {
		.grid-image {
			border-color: $g-secondary;
		}

		.grid-image-item {
			border-color: $g-secondary;
		}
	}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$=".svg"] {
    width: 100%;
  }
}