/*
 * Streamline AG - Forms
 */

small{
  padding-top: 5px;
  font-size:.9rem;  
  display:block;
  font-style:italic;
}

.form-group {
  span {
    font-size: 1.1rem;
    font-weight: 700;
  }

  input{
    padding:0 10px;
  }
}

.alert{
  margin-top:10px;
  display:block;
  border-radius:0;
}

.invalid-text{
  color:red;
}

.form-wrapper {
  border-left: 1px solid $g-primary;
  border-right: 1px solid $g-primary;
  border-bottom: 1px solid $g-primary;

  .inner-form{
    padding: 10px;
  }

  .btn{
    min-width:150px;
  }
}

.form-group {
  span {
    display: block;
    margin-bottom: 6px;
    color: #666;
    font-size: 1rem;
  }

  input {
    font-size: 1rem;
    padding: 9.5px 8px;
    border: 1px solid #b3b3b3;
    background-color: #fff;
    max-width: 100%;
  }
}

.form-check {
  label {
    margin-left: 1.25rem;
  }
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
  padding-left: 1.25rem;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
