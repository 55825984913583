﻿.button--primary {
	font-size: 1.1rem;
	background-color: $g-primary;
	line-height: 1.1rem;
	color: white;
	padding: 10px 20px;
	border: 1px solid $g-primary;
	letter-spacing: .4px;
	border-radius:0px;

	&:hover {
		background-color: rgba(255, 255, 255, 0.00);
		color: $g-primary;
	}

  &:focus {
    outline:0;
  }

	&.inverted {
		background-color: rgba(255, 255, 255, 0.00);
		color: $g-primary;

		&:hover {
			background-color: $g-primary;
			color: white;
		}
	}
}

.button--secondary {
  font-size: 1.1rem;
  background-color: $g-secondary;
  line-height: 1.1rem;
  color: white;
  padding: 10px 20px;
  border: 1px solid $g-secondary;
  letter-spacing: .4px;
  border-radius: 0px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.00);
    color: $g-secondary;
  }

  &.inverted {
    background-color: rgba(255, 255, 255, 0.00);
    color: $g-secondary;

    &:hover {
      background-color: $g-secondary;
      color: white;
    }
  }
}

.button--black {
	font-size: 1.3rem;
	background-color: $g-dark;
	line-height: 3rem;
	color: white;
	padding: 10px 20px;
	border: 1px solid $g-dark;
	letter-spacing: .4px;
	border-radius: 0px;

	&:hover {
		background-color: rgba(255, 255, 255, 0.00);
		color: $g-dark;
	}

	&.inverted {
		background-color: rgba(255, 255, 255, 0.00);
		color: $g-dark;

		&:hover {
			background-color: $g-dark;
			color: white;
		}
	}
}

.button--white {
	font-size: 1.3rem;
	background-color: $g-light;
	line-height: 3rem;
	color: $g-dark;
	padding: 10px 20px;
	border: 1px solid $g-light;
	letter-spacing: .4px;
	border-radius: 0px;

	&:hover {
		background-color: rgba(255, 255, 255, 0.00);
		color: $g-light;
	}

	&.inverted {
		background-color: rgba(255, 255, 255, 0.00);
		color: $g-light;

		&:hover {
			background-color: $g-light;
			color: white;
		}
	}
}

.button + .button {
	margin-left: 10px;
}

button {
  &:hover, &:focus{
    outline:0;
  }
}