﻿.sectionimageslider {
  .js-slide, .grid-image-item, .slick-track, .slider {
    flex: 1;
    position: relative;
    min-height: 45vh;
  }

  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  .slick-dots {
    bottom: 5px;
  }

  .slick-dotted.slick-slider {
    margin-bottom: 0px;
  }

  .grid-image-item {
    background-size: cover;
    background-position: center;
  }
}

.slider-parent {
  min-width: 0;
  min-height: 0;
}
