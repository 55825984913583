﻿#footer {
  margin-top:40px;
  font-size: 1.1rem;
  background-color: $g-primary;
  color: white;

  ul{
    list-style:none;
    margin:0;
    padding:0;

    li{
      &:hover{
        font-weight:700;
      }
    }
  }

  a {
    color: white;
  }

  h4{
    font-weight:700;
  }

  .footer {
    padding: 10px 0;
  }

  .footer-bottom {
    padding-top: 15px;
    background-color: #95192A;

    label {
      margin-bottom: 0;
    }
  }

  .footer-text--right {
    text-align: right;
  }

  .footer-text--center {
    text-align: center;
  }

  .footer-text--left {
    text-align: left;
  }


  @include media-breakpoint-down(md) {
    h5 {
      margin-top: 1rem;
    }

    .mobile-text--left {
      text-align: left !important;

      .footer-text--right {
        text-align: left !important;
      }

      .footer-text--center {
        text-align: left !important;
      }
    }

    .mobile-text--center {
      text-align: center !important;

      .footer-text--left {
        text-align: center !important;
      }

      .footer-text--right {
        text-align: center !important;
      }
    }

    .mobile-text--right {
      text-align: right !important;

      .footer-text--center {
        text-align: right !important;
      }

      .footer-text--left {
        text-align: right !important;
      }
    }
  }

  &.bg--primary {
    a {
      color: $g-light !important;
    }
  }

  .dark {
    background-color: rgba(0, 0, 0, 0.15);
  }
}
