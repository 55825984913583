/*
 * Streamline AG - Custom style
 */

.bg--light {
	background-color: $g-light;
}

.bg--dark {
	background-color: $g-dark;
}

.bg--primary {
	background-color: $g-primary;
}

.bg--secondary {
	background-color: $g-secondary;
}

.bg--grey {
	background-color: $g-grey;
}




.background-image {
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	top: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	z-index: 1;
}
