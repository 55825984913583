﻿.min-height {
	&--10 {
		min-height: 10vh;
	}

	&--15 {
		min-height: 15vh;
	}

	&--20 {
		min-height: 20vh;
	}

	&--25 {
		min-height: 25vh;
	}

	&--30 {
		min-height: 30vh;
	}

	&--35 {
		min-height: 35vh;
	}

	&--40 {
		min-height: 40vh;
	}

	&--45 {
		min-height: 45vh;
	}

	&--50 {
		min-height: 50vh;
	}

	&--55 {
		min-height: 55vh;
	}

	&--60 {
		min-height: 60vh;
	}

	&--65 {
		min-height: 65vh;
	}

	&--70 {
		min-height: 70vh;
	}

	&--75 {
		min-height: 75vh;
	}

	&--80 {
		min-height: 80vh;
	}

	&--85 {
		min-height: 85vh;
	}

	&--90 {
		min-height: 90vh;
	}

	&--95 {
		min-height: 95vh;
	}

	&--100 {
		min-height: 100vh;
	}
}

.min-height-parent {
	min-height: inherit;
}

//flex col-height = parent
.flex-h1 {
	flex: 1;
	position: relative;
	min-height: inherit;
}

//no flex for the Grid section
.sectiongrid {
	.d-flex {
		display: block !important;
	}
}
