﻿/*------------------------------------
  Portfolio
------------------------------------*/
.u-portfolio-controls {
  &__item {
    display: inline-block;
    cursor: pointer;
  }


  @include media-breakpoint-up(lg) {
    &__item:not(:last-child)::after {
      color: $g-dark;
      margin: 0 7px 0 12px;
    }
  }

  a {
    color: #555;

    &.active {
      .card-title{
        background-color: $g-secondary;
      }
    }

    &:hover {
      color: $g-secondary;
    }
  }
}

.u-portfolio {
  img {
    transform: scale(1);
    transition: .3s ease-in-out;
    max-height: 90px;

    &:hover {
      opacity: 1;
      animation: flash 1.5s;
    }
  }

  @-webkit-keyframes flash {
    0% {
      opacity: .4;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes flash {
    0% {
      opacity: .4;
    }

    100% {
      opacity: 1;
    }
  }


  &__item {
    overflow: hidden;
    z-index: 2;
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
    transition: all .2s ease;
  }

  &__item:hover &__image {
    transform: translate3d(0, -60px, 0);
  }

  &__zoom {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-indent: -10000px;
  }
}

.u-portfolio {
  figure {
    a {
      display: block;
    }
  }
}
