﻿@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.8);
	}

	70% {
		box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

@keyframes scroll {
	0% {
		opacity: 1;
		transform: translateY(0) scale(1);
	}

	80% {
		opacity: 0;
		transform: translateY(36px) scale(0.5);
	}

	82% {
		opacity: 0;
		transform: translateY(-5px) scale(0.9);
	}
}
