﻿// Default Variables
$slick-font-family: "FontAwesome" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\f104" !default;
$slick-next-character: "\f105" !default;
$slick-dot-character: "\f111" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;


/* Slider */
.slick-list {
	.slick-loading & {
		background: #fff slick-image-url("../../assets/img/ajax-loader.gif") center center no-repeat;
	}
}

/* Icons */
@if $slick-font-family == "FontAwesome" {
	@font-face {
		font-family: "FontAwesome";
		src: url("../../assets/fonts/FontAwesome-webfont.eot");
		src: url("../../assets/fonts/FontAwesome-webfont.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/FontAwesome-webfont.woff") format("woff"), url("../../assets/fonts/FontAwesome-webfont.ttf") format("truetype"), url("../../assets/fonts/FontAwesome-webfont.svg#FontAwesome") format("svg");
		font-weight: normal;
		font-style: normal;
	}
}

/* Arrows */

.slick-prev,
.slick-next {
	position: absolute;
	display: block;
	height: auto;
	width: auto;
	line-height: 0px;
	font-size: 0px;
	cursor: pointer;
	background: transparent;
	color: transparent;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	padding: 0;
	border: none;
	outline: none;

	&:hover, &:focus {
		outline: none;
		background: transparent;
		color: transparent;

		&:before {
			opacity: $slick-opacity-on-hover;
		}
	}

	&.slick-disabled:before {
		opacity: $slick-opacity-not-active;
	}

	&:before {
		font-family: $slick-font-family;
		font-size: 20px;
		line-height: 1;
		color: $slick-arrow-color;
		opacity: $slick-opacity-default;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

.slick-prev {
	left: -25px;

	[dir="rtl"] & {
		left: auto;
		right: -25px;
	}

	&:before {
		content: $slick-prev-character;

		[dir="rtl"] & {
			content: $slick-next-character;
		}
	}
}

.slick-next {
	right: -25px;

	[dir="rtl"] & {
		left: -25px;
		right: auto;
	}

	&:before {
		content: $slick-next-character;

		[dir="rtl"] & {
			content: $slick-prev-character;
		}
	}
}

/* Dots */

.slick-dotted.slick-slider {
	margin-bottom: 30px;
}

.slick-dots {
	position: absolute;
	bottom: -25px;
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;
	width: 100%;

	li {
		position: relative;
		display: inline-block;
		height: 20px;
		width: 20px;
		margin: 0 5px;
		padding: 0;
		cursor: pointer;

		button {
			border: 0;
			background: transparent;
			display: block;
			height: 20px;
			width: 20px;
			outline: none;
			line-height: 0px;
			font-size: 0px;
			color: transparent;
			padding: 5px;
			cursor: pointer;

			&:hover, &:focus {
				outline: none;

				&:before {
					opacity: $slick-opacity-on-hover;
				}
			}

			&:before {
				position: absolute;
				top: 0;
				left: 0;
				content: $slick-dot-character;
				width: 20px;
				height: 20px;
				font-family: $slick-font-family;
				font-size: $slick-dot-size;
				line-height: 20px;
				text-align: center;
				color: $slick-dot-color;
				opacity: $slick-opacity-not-active;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
		}

		&.slick-active button:before {
			color: $slick-dot-color-active;
			opacity: $slick-opacity-default;
		}
	}
}




/*------------------------------------
Slick
------------------------------------*/



.slick-prev,
.slick-next {
	font-size: 0;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	color: white;
	border: 0;
	background: none;
	z-index: 1;
	outline: 0 !important;

	&:hover, &:focus {
		color: $g-primary;
	}

	&:before {
		font-size: 0;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		color: white;
		border: 0;
		background: none;
		z-index: 1;
		outline: 0 !important;
	}

	&:hover:after {
		color: $g-primary;
	}
}

.slick-prev {
	left: 20px;

	&:after {
		content: "\f104";
		font: 40px/1 $slick-font-family;
		outline: 0;
	}
}

.slick-next {
	right: 20px;
	text-align: right;

	&:after {
		content: "\f105";
		font: 40px/1 $slick-font-family;
		outline: 0;
	}
}

.js-slide {
	outline: 0;
}


@include media-breakpoint-down(sm) {
	.slick-prev {
		left: 0px;
	}

	.slick-next {
		right: 0px;
	}
}

.slick-dots li.slick-active button:before, .slick-dots li button:before {
	color: $g-grey;
	opacity: 1;
	font-size: 16px;
}


.slick-dots li.slick-active button:before {
	color: $g-primary;
}


.slider-img {
	background-size: cover;
	height: 800px !important;
}