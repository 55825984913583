﻿$banner-height: 500px;


.bannerSlider {
	padding: 0;
	margin: 0;
	height: 450px;

	.slide__image {
		&before {
			content: "";
			position: absolute;
			width: 100%;
			height: $banner-height;
			background: rgba(33,33,33,.7);
		}
	}

	.slick-slide {
		position: relative;

		img {
			width: 100%;
			height: $banner-height;
			object-fit: cover;
		}
	}

	.slide__text {
		position: absolute;
		z-index: 100;
		text-align: center;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);
	}

	.slide__text h2 {
		font-size: 48px;
		font-weight: 100;
		color: $g-primary;
	}
}


img.slide__image__top-position {
	object-position: center bottom;
}

