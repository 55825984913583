﻿.horizontal-end {
	position: relative;
	left: 100%;
	transform: translateX(-100%);
}

.horizontal-start {
	position: relative;
	left: 0;
}

.horizontal-center {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}

.vertical-end {
	position: relative;
	top: 100%;
	transform: translateY(-100%);
}

.vertical-start {
	position: relative;
	top: 0;
}


.vertical-center {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.vertical-center.horizontal-center {
	transform: translate(-50%, -50%);
}

.vertical-center.horizontal-end {
	transform: translate(-100%, -50%);
}

.vertical-end.horizontal-center {
	transform: translate(-50%, -100%);
}

.vertical-end.horizontal-end {
	transform: translate(-100%, -100%);
}
