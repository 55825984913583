﻿/*======================================
  #Categories
======================================*/
.categories {
  width: 100%;
  transition: transform .35s ease;

  ul {
    flex-flow: row wrap;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: block;
      position: relative;
      transition: all .2s ease;
      width: 100%;

      &:hover {
        background: rgba(255, 255, 255, 0.03);
      }

      a {
        color: #333;
        display: block;
        font-size: 1.1rem;
        transition: all .2s ease;
        position: relative;
        white-space: normal;
        line-height: $g-headerHeight + ($g-headerPadding * 2);
        padding: 0 20px;
        font-weight: 400;
        border-top: 1px solid rgba(255, 255, 255, 0.08);
        line-height: 1 !important;
        padding: 11px 10px;
        font-weight: 300;
      }
      //2.Level submenu-dropdown
      ul {
        position: absolute;
        top: 100%;
        left: 0;
        text-align: left;
        background-color: white;
        list-style: none;
        margin: 0;
        padding: 0;
        min-width: 250px;
        opacity: 0;
        visibility: hidden;
        transition: all .125s ease;

        li {
          display: block;
          transition: all .2s ease;
          position: relative;

          &:hover {
            background: rgba(255, 255, 255, 0.03);
          }

          a {
            display: block;
            line-height: 1 !important;
            font-size: 1.1rem;
            color: $g-dark;
            padding: 10px 20px;
            font-weight: 300;

            &:after {
              content: "";
              position: absolute;
              right: 20px;
              bottom: 0;
              left: 20px;
              height: 1px;
              background: rgba(0,0,0,.1);
            }

            i {
              font-size: 70%;
              color: rgba(255, 255, 255, 0.5);
              position: absolute;
              right: 15px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }

        .go-right {
          left: auto;
          right: 0;
        }
      }
    }
  }
}

.categories {
  .level-1 {
    text-align: left;

    &:hover {
      & > a {
        @include linear-gradient(#205cb7, #5486d2, top);
        color: white;
      }
    }

    & > a {
      color: $g-dark;
      letter-spacing: 2px;
      font-weight: 700;

      i {
        position: absolute;
        right: 10px;
      }

      i.fa-chevron-down {
        color: white;
      }
    }
  }

  .level-2 {
    &:hover {
      background: rgba(0, 0, 0, 0.25);

      a {
        font-weight: 700;
        color: white;
        letter-spacing: normal;
      }
    }
  }
}





  .categories > ul > li:hover {
    background-color: inherit;
  }

  .categories > ul > li:hover > a {
    color: $g-dark;
  }

  .categories > ul > li ul {
    position: static;
    visibility: visible;
    opacity: 1;
    display: none;
    transition: none;
  }

  .categories > ul > li ul li a {
    border-top: 1px solid rgba(255, 255, 255, 0.9);
    padding: 15px 25px;
    position: relative;
    background-color: rgba(0, 0, 0, 0.04);
  }

  .categories > ul > li ul li a > i {
    display: none;
  }

  .categories > ul > li ul li:hover {
    background: inherit;
  }

  .categories > ul > li ul li ul {
    background-color: rgba(255, 255, 255, 0.08);
  }

  .categories > ul > li ul li ul li:hover {
    background: none;
  }

  .categories > ul > li ul li ul li:hover a {
    color: white;
  }
  //3.Level submenu-dropdown
  .categories > ul > li ul ul {
    top: 0;
    left: 100%;
  }

  .categories > ul > li ul ul.go-right {
    left: auto;
    right: 100%;
  }



  .categories > ul > li:hover > ul,
  .categories > ul > li.touch-open > ul {
    opacity: 1;
    visibility: visible;
  }

  .categories > ul > li:hover > ul li:hover > ul,
  .categories > ul > li.touch-open > ul li:hover > ul,
  .categories > ul > li:hover > ul li.touch-open > ul,
  .categories > ul > li.touch-open > ul li.touch-open > ul {
    opacity: 1;
    visibility: visible;
  }


  .categories > ul > li > a.open,
  .categories > ul > li > a.active {
    @include linear-gradient(#205cb7, #5486d2, top);
    color: white;
    font-weight: 700;
    font-size: 1.1rem;
  }

  .categories > ul > li > ul > .active > a {
    font-weight: 700;
  }

  .top--light .categories > ul > li > ul > li > ul > .active > a {
    font-weight: 700;
  }

  .top--light .categories > ul > li a {
    color: $g-dark;
  }

  .top--light .categories > ul > li a i.fa-chevron-down,
  .top--light .categories > ul > li a .fa-chevron-right {
    color: rgba(0, 0, 0, 0.5);
  }

  .top--light .categories > ul > li:hover,
  .scroll .top--light .categories > ul > li:hover {
    background: rgba(0, 0, 0, 0.02);
  }

  .top--light .categories > ul > li ul {
    background: $g-secondary;
    box-shadow: 0 8px 20px -6px rgba(0, 0, 0, 0.15);
  }

  .top--light .categories > ul > li ul li a {
    color: $g-light;
  }

  .top--light .categories > ul > li ul li:hover {
    background: rgba(0, 0, 0, 0.02);
    color: $g-dark;
  }

  .top--scroll-light .categories > ul > li > a {
    color: white;
  }

  .top--scroll-light .categories > ul > li > a i.fa-chevron-down {
    color: rgba(255, 255, 255, 0.5);
  }


  .top--scroll-light .categories > ul > li > a {
    color: inherit;
  }


  .top--scroll-dark .categories > ul > li > a {
    color: #111;
  }

  .top--scroll-dark .categories > ul > li > a i.fa-chevron-down {
    color: rgba(0, 0, 0, 0.5);
  }


  .top--scroll-dark .categories > ul > li > a {
    color: inherit;
  }



  .top--dark .categories > ul > li > a {
    color: white;
  }

  .scroll .top--light .categories > ul > li > a {
    color: #111;
  }

  .scroll .top--light .categories > ul > li > a i.fa-chevron-down {
    color: rgba(0, 0, 0, 0.5);
  }

  .scroll .top--dark .categories > ul > li > a {
    color: white;
  }

  .scroll .top--dark .categories > ul > li > a i.fa-chevron-down {
    color: rgba(255, 255, 255, 0.5);
  }


  .top--light .categories {
    background: white;
  }

  .top--light .categories li.mobile-contact:nth-child(2) {
    border-left-color: rgba(0, 0, 0, 0.08);
  }

  .top--light .categories .expand {
    background-color: rgba(0, 0, 0, 0.04);
  }

  .top--light .categories > ul > li > a {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }

  .top--light .categories > ul > li.lang ul {
    border-bottom-color: rgba(0, 0, 0, 0.08);
  }

  .top--light .categories > ul > li.lang ul li {
    border-left-color: rgba(0, 0, 0, 0.08);
  }

  .top--light .categories > ul > li.lang ul li a {
    background-color: white !important;
  }

  .top--light .categories > ul > li:hover {
    background: inherit;
  }

  .top--light .categories > ul > li:hover > a {
    color: #111;
  }

  .top--light .categories > ul > li ul {
    box-shadow: none;
  }

  .top--light .categories > ul > li ul li a {
    background-color: rgba(0, 0, 0, 0.04);
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }

  .top--light .categories > ul > li ul li ul {
    background-color: rgba(0, 0, 0, 0.04);
  }

  .top--light .categories > ul > li ul li:hover {
    background: inherit;
  }

  .top--light .categories > ul > li ul li:hover a {
    color: #111;
  }


  .top--light .mobile-actions ul li a {
    color: #111;
  }

  .top--scroll-dark .mobile-actions ul li a {
    color: #111;
  }

  .top--scroll-light .mobile-actions ul li a {
    color: white;
  }

  .scroll .top--light .mobile-actions ul li a {
    color: #111;
  }

  .scroll .top--dark .mobile-actions ul li a {
    color: white;
  }



  .top--light .toggle-mobile-nav span {
    background-color: $g-dark;
  }

  .top--scroll-dark .toggle-mobile-nav span {
    background-color: $g-dark;
  }

  .top--scroll-light .toggle-mobile-nav span {
    background-color: $g-light;
  }

  .scroll .top--light .toggle-mobile-nav span {
    background-color: $g-dark;
  }

  .scroll .top--dark .toggle-mobile-nav span {
    background-color: $g-light;
  }

  .mobile-nav-visible .toggle-mobile-nav span:nth-child(1) {
    transform: rotate(45deg) translateY(-3px);
    transform-origin: top left;
  }

  .mobile-nav-visible .toggle-mobile-nav span:nth-child(2) {
    opacity: 0;
    transform: translateX(-20px);
  }

  .mobile-nav-visible .toggle-mobile-nav span:nth-child(3) {
    transform: rotate(-45deg) translateY(3px);
    transform-origin: bottom left;
  }


  .top--light .categories, .scroll .top--light .categories {
    .level-2, .level-3 {
      background-color: $g-secondary;
      border-bottom: 1px solid white;

      &:last-child {
        border-bottom: none;
      }


      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }

      a {
        color: white;
        font-size: 1.1rem;
        padding: 10px 20px;

        i.fa-chevron-right {
          color: white;
          margin-left: 10px;
        }
      }
    }
  }