/*
 * Streamline AG - Custom style
 */

html {
  font-size: $font-size-base;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font: {
    weight: 300;
    size: 1.2rem;
    family: $g-font-body;
  }

  overflow-x: hidden;
  line-height: $g-line-height;
  color: $g-dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga", "kern";
  text-rendering: optimizelegibility;
  min-height: 100vh;
}

section {
  position: relative;
}

a {
  color: $g-link-color;
  text-decoration: $g-link-hover-decoration;

  &:hover {
    color: $g-link-color-hover;
    text-decoration: $g-link-hover-decoration;
  }

  &:focus {
    outline: 0;
    text-decoration: $g-link-hover-decoration;
    box-shadow: none;
  }
}

p {
  line-height: $g-paragraph-line-height;

  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }
}

hr {
  border-top: 1px solid $g-primary !important;
  margin: 30px 0;
}

caption {
  caption-side: top;
  font-weight: 700;
}

figure {
  margin-bottom: 0;
}

.content {
  padding-top: 250px;
  min-height: 80vh;

  @include media-breakpoint-down(md) {
    padding-top: 150px;
  }
}

.overlay {
  position: absolute !important;
  height: 100%;
  width: 100%;
  bottom: 0;
  right: 0;
  transition: 0.2s;
}

.overlay-content {
  color: rgb(255, 255, 255);
  position: absolute !important;
  bottom: 50px;
  right: 80px;
  max-width: 50%;
  padding: 10px;
  background-color: rgba(210, 23, 48, .7);
}

iframe {
  width: 100%;
}


.thumb {
  max-width: 70%;
}

.content-2 {
  padding-top: 50px;
}

.minWidth {
  min-width: 130px;
}

.Box_Login_Title {
  //background-color: $g-primary;
  //@include linear-gradient(#aa1427, #e42323, top);
  color: $g-primary;
  //text-transform: uppercase;
  font-size: 1.4rem;
  padding: 5px 0px;
  font-weight: 700;
  margin: 1.3rem 0;
  letter-spacing: 1.5px;
}

.Brands_Title {
  @include linear-gradient(#205cb7, #5486d2, top);
  color: white;
  text-transform: uppercase;
  padding: 0 10px;
  font-weight: 700;
  margin-top: 1.3rem;
  font-size: 1.1rem;
}

.title {
  margin: 40px 0;
  color: $g-primary;
  text-transform: uppercase;
}

.h1 {
  margin-top: 1.3rem;
  letter-spacing: 1.5px;
}

img[data-sizes="auto"] {
  display: block;
  width: 100%;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  -webkit-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  transition: opacity 300ms;
}

hr {
  border-color: $g-grey;
}

[role=button] {
  cursor: pointer;
}

small {
  font-size: 100%;
}

/*.active{
  font-weight:600;
}*/

.catalogs {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .row {
    margin:0;
    border-bottom: 1px solid rgba(210, 23, 48, .7);

    a{
      padding-bottom:5px;
    }
  }

  img {
    max-width: 100px;
  }

  .Brands_Title {
    margin-top: 4.5rem;
  }

  .cat-menu {
    margin-top: 5rem;

    li {
      font-weight: 700;

      &:after {
        content: "|";
        color: $g-primary;
        padding: 0 5px;
      }

      &:last-child:after {
        content: '';
        padding: 0;
      }
    }
  }
}

.price-menu {
  span {
    a {
      text-transform:uppercase;
      font-weight: 700;
      padding:0 5px;
    }
  }
}



.TR {
  display: block;
}

.mypager {
  span {
    color: $g-primary !important;
    text-decoration: underline;
    font-weight: 700;
    padding: 5px;
  }

  a {
    color: $g-primary !important;
    padding: 5px;

    &:hover, &:focus {
      background-color: $g-primary;
      color: white !important;
      font-weight: 700;
    }
  }
}

.legende {
  font-size: 1rem;
}

.Product_Table {
  text-indent: 3px;
}

.news, .content {
  img {
    max-width: 100%;
    height: auto;
  }
}

.delivery {
  label {
    max-width: 70%;
  }


  input[type=radio], input[type=checkbox] {
    appearance: none;
    display: inline-block;
    position: relative;
    background-color: $g-grey;
    color: white;
    top: 0px;
    height: 25px;
    width: 25px;
    border: 0;
    border-radius: 50px;
    cursor: pointer;
    margin-right: 5px;
    outline: none;
    vertical-align: top;
  }

  input[type=radio]:checked::before {
    position: absolute;
    font: 16px/1 'Open Sans', sans-serif;
    left: 8px;
    top: 3px;
    content: '\02143';
    transform: rotate(40deg);
  }

  input[type=checkbox] {
    color: black;
  }


  input[type=checkbox]:checked::before {
    position: absolute;
    font: 16px/1 'Open Sans', sans-serif;
    left: 7px;
    top: 2px;
    content: '\02143';
    transform: rotate(40deg);
  }

  input[type=radio]:hover {
    background-color: $g-grey;
    color: $g-primary;
    border: 1px solid #b1b1b1;
  }

  input[type=radio]:checked {
    background-color: $g-grey;
    color: $g-primary;
  }


  input[type=checkbox] {
    border-radius: 0px;
    border: 1px solid black;
    background-color: white;
  }

  input[type=checkbox]:checked {
    border: 1px solid $g-primary;
    color: $g-primary;
  }

  input[type=checkbox]:hover {
    border: 1px solid $g-primary;
  }

  label {
    font: 15px/1.7 'Lato', sans-serif;
    color: #333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
  }
}
/*------------------------------------
  Headings
------------------------------------*/
.h1, .h2, .h3, .h4, .h5, .h6, .h7,
h1, h2, h3, h4, h5, h6 {
  line-height: $g-headings-line-height;
  letter-spacing: 1.5px;
}

h2, .h2 {
  font-size: 2.5rem;
}


@include media-breakpoint-down(sm) {
  h1, .h1 {
    font-size: $font-size-sm * 1.8;
  }

  h2, .h2 {
    font-size: $font-size-sm * 1.6;
  }

  h3, .h3 {
    font-size: $font-size-sm * 1.4;
  }

  h4, .h4 {
    font-size: $font-size-sm *1.2;
  }

  h5, .h5 {
    font-size: $font-size-sm *1.1;
  }

  h6, .h6 {
    font-size: $font-size-sm;
  }
}
/*------------------------------------
  Highlight Color
------------------------------------*/
::-moz-selection {
  color: $g-light;
  background-color: $g-primary;
}

::selection {
  color: $g-light;
  background-color: $g-primary;
}

.bg-primary ::-moz-selection {
  color: $g-primary;
  background-color: $g-light;
}

.bg-primary ::selection {
  color: $g-primary;
  background-color: $g-light;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}
