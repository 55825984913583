/*
 * Streamline AG - Cards
 */

.card-item {
  min-height: 20vh;
  transition: all .3s ease;

  @include media-breakpoint-down(sm) {
    min-height: 5vh;
  }

  .card-img {
    position: relative;
    background-size: cover;
    background-position: 50%;
    width:auto;

    &:hover {
      .card-title {
        background-color: $g-secondary;
      }
    }
  }

  .card-title {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: red;
    color: white;
    padding: 0 5px;
    text-align: center;
    margin-bottom: 0;

    h2 {
      font-size: 1.3rem;
      text-transform: uppercase;
      margin: 0;
      line-height: 2.2rem;
    }
  }
}