﻿.news {


  .card-columns {
    @include media-breakpoint-only(lg) {
      column-count: 2
    }

    @include media-breakpoint-only(md) {
      column-count: 2
    }
  }

  .card {
    border: 0 !important;
    border-radius: 0;
    box-shadow: 0 22px 43px -20px rgba(0,0,0,0.15);

    a:hover {
      .news-image {
        /*transform: scale(1.1);*/
      }
    }
  }
}

.news-wrapper {
	background-color: white;
	padding-left: 1rem;
	padding-right: 1rem;

  .Redlink{
    font-weight:700;
  }
}

.news-image {
	height: 280px !important;
	min-height: 280px;
	background-size: cover;
}

.news-date {
	font-size: 1rem;
	color: white;
	background-color: $g-secondary;
	width: 60px;

	span {
		line-height: 1.1rem;
		letter-spacing: .4px;
	}
}

.news-text {
	color: grey !important;
}

.news-title {
	color: $g-primary;
}

.news-footer {
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 0;
	padding-top: 0;
	background-color: transparent;
}

.news-tag {
	font-size: 1.3rem;
	background-color: $g-secondary;
	line-height: 3rem;
	color: white;
	padding: 3px 8px;
	border: 1px solid $g-secondary;
	letter-spacing: .4px;

	&:hover {
		background-color: white;
		color: $g-secondary;
		border: 1px solid $g-secondary;
	}
}


.news-more {
	font-size: 1.3rem;
	transition: all .3s ease;
	text-decoration: none;
	transition: all .3s ease;
	background-color: $g-primary;
	line-height: 3rem;
	color: white;
	padding: 3px 8px;
	color: #fff;
	border-radius: 0px;
	letter-spacing: .4px;

	&:hover, &:focus {
		background-color: transparent;
		border: 1px solid $g-primary;
		color: $g-primary;
	}
}

.news-image-overflow {
	overflow: hidden;
}

.news-footer-line {
	border-top: 1px solid #555 !important;
}

.news-category-item {
	border: 1px solid $g-secondary;
	background-color: white;
	color: $g-secondary;

	&:hover {
		background-color: $g-secondary;

		a {
			color: white !important;
		}
	}
}

.u-pagination-v1-4--active {
	color: $g-primary;
}
