﻿/*======================================
  #Product
======================================*/
.products {
  .card {
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.125) !important;

    .card-text > a {
      font-size: 1rem;
    }

    h5 > a, p > a {
      color: $g-dark;
    }

    &:hover, &:focus {
      h5 > a, p > a, img {
        color: $g-primary;
        outline: 0;
      }
    }

    .card-body {
      padding: 0.75rem;
    }

    .card-footer {
      border: none;
      font-size: 1rem;
      padding: 0.75rem;
    }

    &:hover {
      border: 1px solid $g-primary !important;
    }

    .fa {
      font-size: 1.3rem;
    }
  }

  .badge{
    a{
      color:white;
      text-decoration:underline;

      &:hover{
        text-decoration:none;
      }
    }
  }

  .badge-primary {
    color: #fff;
    background-color: $g-secondary;
    border-radius: 0;
    font-size: 100%;
    font-weight:400;
  }
}

  .vertical-center {
    text-align: center;
  }
