﻿.paging {
	padding: 30px 10px 0 10px;

	li {
		.page-button {
			padding: 0.85714rem 1.35714rem !important;
			font-size: 1.3rem;
			line-height: 3rem;
			border: 1px solid $g-secondary;
			letter-spacing: .4px;
			transition: all .3s ease;
			background-color: white;
			color: $g-secondary;


			&:hover {
				background-color: $g-secondary;
				color: white;
			}
		}

		.page-button.active {
			background-color: $g-secondary;
			color: white;
			pointer-events: none;
			cursor: default;
		}
	}
}
