﻿.testimonial-item {
	position: relative;
	overflow: hidden;
	margin: 10px;
	min-width: 200px;
	max-width: 600px;
	width: 80%;
	text-align: left;
	box-shadow: none !important;
}

.testimonial-item * {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
	transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.testimonial-item img {
	max-width: 100%;
	vertical-align: middle;
	height: 90px;
	width: 90px;
	border-radius: 50%;
	margin: 40px 0 0 10px;
}

.testimonial-item blockquote {
	display: block;
	border-radius: 8px;
	position: relative;
	background-color: #fafafa;
	padding: 25px 50px 30px 50px;
	font-size: 0.8em;
	font-weight: 500;
	margin: 0;
	line-height: 1.6em;
	font-style: italic;
}

.testimonial-item blockquote:before {
	content: "\201C";
	position: absolute;
	font-size: 50px;
	//opacity: 0.3;
	font-style: normal;
	top: 25px;
	left: 20px;
	color: $g-primary
}


.testimonial-item .arrow {
	top: 100%;
	width: 0;
	height: 0;
	border-left: 0 solid transparent;
	border-right: 25px solid transparent;
	border-top: 25px solid #fafafa;
	margin: 0;
	position: absolute;
}

.testimonial-item .author {
	position: absolute;
	bottom: 45px;
	padding: 0 10px 0 120px;
	margin: 0;
	text-transform: uppercase;
	-webkit-transform: translateY(50%);
	transform: translateY(50%);
}

.testimonial-item .author h5 {
	opacity: 0.8;
	margin: 0;
	font-weight: 800;
}

.testimonial-item .author span {
	font-weight: 400;
	text-transform: none;
}

.bg--grey {
	.testimonial-item {
		blockquote {
			background-color: $g-light;
		}

		.arrow {
			border-top: 25px solid $g-light;
		}

		.author {
			color: $g-light;
		}

		.slick-arrow {
			color: $g-light;
		}
	}

	.slick-prev,
	.slick-next {
		color: $g-dark;


		&:hover, &:focus {
			color: $g-primary;
		}

		&:hover:after {
			color: $g-primary;
		}
	}

	li {
		button {
			&:before {
				color: $g-dark;
				opacity: $slick-opacity-not-active;
			}
		}

		&.slick-active button:before {
			color: $g-primary;
			opacity: $slick-opacity-default;
		}
	}
}


.bg--dark {
	.testimonial-item {
		blockquote {
			background-color: $g-light;
		}

		.arrow {
			border-top: 25px solid $g-light;
		}

		.author {
			color: $g-light;
		}

		.slick-arrow {
			color: $g-light;
		}
	}

	li {
		button {
			&:before {
				color: $g-light;
				opacity: $slick-opacity-not-active;
			}
		}

		&.slick-active button:before {
			color: $g-primary;
			opacity: $slick-opacity-default;
		}
	}
}

.bg--light {
	.testimonial-item {
		blockquote {
			background-color: $g-dark;
		}

		.arrow {
			border-top: 25px solid $g-dark;
		}

		.author {
			color: $g-dark;
		}

		.slick-arrow {
			color: $g-dark;
		}
	}

	li {
		button {
			&:before {
				color: $g-dark;
				opacity: $slick-opacity-not-active;
			}
		}

		&.slick-active button:before {
			color: $g-primary;
			opacity: $slick-opacity-default;
		}
	}
}

.bg--primary {
	.testimonial-item {
		blockquote {
			background-color: $g-light;
		}

		.arrow {
			border-top: 25px solid $g-light;
		}

		.author {
			color: $g-light;
		}
	}

	.slick-prev,
	.slick-next {
		color: $g-light;


		&:hover, &:focus {
			color: $g-dark;
		}

		&:hover:after {
			color: $g-dark;
		}
	}

	li {
		button {
			&:before {
				color: $g-light;
				opacity: $slick-opacity-not-active;
			}
		}

		&.slick-active button:before {
			color: $g-light;
			opacity: $slick-opacity-default;
		}
	}
}

.bg--secondary {
	.testimonial-item {
		blockquote {
			background-color: $g-light;
		}

		.arrow {
			border-top: 25px solid $g-light;
		}

		.author {
			color: $g-light;
		}

		.slick-arrow {
			color: $g-light;
		}
	}

	li {
		button {
			&:before {
				color: $g-light;
				opacity: $slick-opacity-not-active;
			}
		}

		&.slick-active button:before {
			color: $g-light;
			opacity: $slick-opacity-default;
		}
	}
}
//SLIDER
.slider-testimonialsSlider {
	/*max-width: 700px;*/
}
