/*
 * Streamline AG - Custom style
 */
.text--black {
	color: $g-dark;

	.grid-image {
		a {
			color: $g-dark;
		}
	}
}

.text--white {
	color: $g-light;

	.grid-image {
		a {
			color: $g-light;
		}
	}
}

.text--primary {
	color: $g-primary;

	.grid-image {
		a {
			color: $g-primary;
		}
	}
}

.text--secondary {
	color: $g-secondary;

	.grid-image {
		a {
			color: $g-secondary;
		}
	}
}
