@charset "UTF-8";
/**
	ROOT FRAMEWORK
**/
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700");
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

/*
 * Streamline AG - Font
 */
/*
 * FONT-AWESOME 4.7
 */
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -15%; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-fw {
  width: 1.28571em;
  text-align: center; }

.fa-ul {
  padding-left: 0;
  margin-left: 2.14286em;
  list-style-type: none; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  position: absolute;
  left: -2.14286em;
  width: 2.14286em;
  top: 0.14286em;
  text-align: center; }
  .fa-li.fa-lg {
    left: -1.85714em; }

.fa-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #eee;
  border-radius: .1em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left {
  margin-right: .3em; }

.fa.fa-pull-right {
  margin-left: .3em; }

/* Deprecated as of 4.4.0 */
.pull-right {
  float: right; }

.pull-left {
  float: left; }

.fa.pull-left {
  margin-right: .3em; }

.fa.pull-right {
  margin-left: .3em; }

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear; }

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8); }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1); }

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1); }

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none; }

.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle; }

.fa-stack-1x, .fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center; }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #fff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-glass:before {
  content: ""; }

.fa-music:before {
  content: ""; }

.fa-search:before {
  content: ""; }

.fa-envelope-o:before {
  content: ""; }

.fa-heart:before {
  content: ""; }

.fa-star:before {
  content: ""; }

.fa-star-o:before {
  content: ""; }

.fa-user:before {
  content: ""; }

.fa-film:before {
  content: ""; }

.fa-th-large:before {
  content: ""; }

.fa-th:before {
  content: ""; }

.fa-th-list:before {
  content: ""; }

.fa-check:before {
  content: ""; }

.fa-remove:before,
.fa-close:before,
.fa-times:before {
  content: ""; }

.fa-search-plus:before {
  content: ""; }

.fa-search-minus:before {
  content: ""; }

.fa-power-off:before {
  content: ""; }

.fa-signal:before {
  content: ""; }

.fa-gear:before,
.fa-cog:before {
  content: ""; }

.fa-trash-o:before {
  content: ""; }

.fa-home:before {
  content: ""; }

.fa-file-o:before {
  content: ""; }

.fa-clock-o:before {
  content: ""; }

.fa-road:before {
  content: ""; }

.fa-download:before {
  content: ""; }

.fa-arrow-circle-o-down:before {
  content: ""; }

.fa-arrow-circle-o-up:before {
  content: ""; }

.fa-inbox:before {
  content: ""; }

.fa-play-circle-o:before {
  content: ""; }

.fa-rotate-right:before,
.fa-repeat:before {
  content: ""; }

.fa-refresh:before {
  content: ""; }

.fa-list-alt:before {
  content: ""; }

.fa-lock:before {
  content: ""; }

.fa-flag:before {
  content: ""; }

.fa-headphones:before {
  content: ""; }

.fa-volume-off:before {
  content: ""; }

.fa-volume-down:before {
  content: ""; }

.fa-volume-up:before {
  content: ""; }

.fa-qrcode:before {
  content: ""; }

.fa-barcode:before {
  content: ""; }

.fa-tag:before {
  content: ""; }

.fa-tags:before {
  content: ""; }

.fa-book:before {
  content: ""; }

.fa-bookmark:before {
  content: ""; }

.fa-print:before {
  content: ""; }

.fa-camera:before {
  content: ""; }

.fa-font:before {
  content: ""; }

.fa-bold:before {
  content: ""; }

.fa-italic:before {
  content: ""; }

.fa-text-height:before {
  content: ""; }

.fa-text-width:before {
  content: ""; }

.fa-align-left:before {
  content: ""; }

.fa-align-center:before {
  content: ""; }

.fa-align-right:before {
  content: ""; }

.fa-align-justify:before {
  content: ""; }

.fa-list:before {
  content: ""; }

.fa-dedent:before,
.fa-outdent:before {
  content: ""; }

.fa-indent:before {
  content: ""; }

.fa-video-camera:before {
  content: ""; }

.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
  content: ""; }

.fa-pencil:before {
  content: ""; }

.fa-map-marker:before {
  content: ""; }

.fa-adjust:before {
  content: ""; }

.fa-tint:before {
  content: ""; }

.fa-edit:before,
.fa-pencil-square-o:before {
  content: ""; }

.fa-share-square-o:before {
  content: ""; }

.fa-check-square-o:before {
  content: ""; }

.fa-arrows:before {
  content: ""; }

.fa-step-backward:before {
  content: ""; }

.fa-fast-backward:before {
  content: ""; }

.fa-backward:before {
  content: ""; }

.fa-play:before {
  content: ""; }

.fa-pause:before {
  content: ""; }

.fa-stop:before {
  content: ""; }

.fa-forward:before {
  content: ""; }

.fa-fast-forward:before {
  content: ""; }

.fa-step-forward:before {
  content: ""; }

.fa-eject:before {
  content: ""; }

.fa-chevron-left:before {
  content: ""; }

.fa-chevron-right:before {
  content: ""; }

.fa-plus-circle:before {
  content: ""; }

.fa-minus-circle:before {
  content: ""; }

.fa-times-circle:before {
  content: ""; }

.fa-check-circle:before {
  content: ""; }

.fa-question-circle:before {
  content: ""; }

.fa-info-circle:before {
  content: ""; }

.fa-crosshairs:before {
  content: ""; }

.fa-times-circle-o:before {
  content: ""; }

.fa-check-circle-o:before {
  content: ""; }

.fa-ban:before {
  content: ""; }

.fa-arrow-left:before {
  content: ""; }

.fa-arrow-right:before {
  content: ""; }

.fa-arrow-up:before {
  content: ""; }

.fa-arrow-down:before {
  content: ""; }

.fa-mail-forward:before,
.fa-share:before {
  content: ""; }

.fa-expand:before {
  content: ""; }

.fa-compress:before {
  content: ""; }

.fa-plus:before {
  content: ""; }

.fa-minus:before {
  content: ""; }

.fa-asterisk:before {
  content: ""; }

.fa-exclamation-circle:before {
  content: ""; }

.fa-gift:before {
  content: ""; }

.fa-leaf:before {
  content: ""; }

.fa-fire:before {
  content: ""; }

.fa-eye:before {
  content: ""; }

.fa-eye-slash:before {
  content: ""; }

.fa-warning:before,
.fa-exclamation-triangle:before {
  content: ""; }

.fa-plane:before {
  content: ""; }

.fa-calendar:before {
  content: ""; }

.fa-random:before {
  content: ""; }

.fa-comment:before {
  content: ""; }

.fa-magnet:before {
  content: ""; }

.fa-chevron-up:before {
  content: ""; }

.fa-chevron-down:before {
  content: ""; }

.fa-retweet:before {
  content: ""; }

.fa-shopping-cart:before {
  content: ""; }

.fa-folder:before {
  content: ""; }

.fa-folder-open:before {
  content: ""; }

.fa-arrows-v:before {
  content: ""; }

.fa-arrows-h:before {
  content: ""; }

.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: ""; }

.fa-twitter-square:before {
  content: ""; }

.fa-facebook-square:before {
  content: ""; }

.fa-camera-retro:before {
  content: ""; }

.fa-key:before {
  content: ""; }

.fa-gears:before,
.fa-cogs:before {
  content: ""; }

.fa-comments:before {
  content: ""; }

.fa-thumbs-o-up:before {
  content: ""; }

.fa-thumbs-o-down:before {
  content: ""; }

.fa-star-half:before {
  content: ""; }

.fa-heart-o:before {
  content: ""; }

.fa-sign-out:before {
  content: ""; }

.fa-linkedin-square:before {
  content: ""; }

.fa-thumb-tack:before {
  content: ""; }

.fa-external-link:before {
  content: ""; }

.fa-sign-in:before {
  content: ""; }

.fa-trophy:before {
  content: ""; }

.fa-github-square:before {
  content: ""; }

.fa-upload:before {
  content: ""; }

.fa-lemon-o:before {
  content: ""; }

.fa-phone:before {
  content: ""; }

.fa-square-o:before {
  content: ""; }

.fa-bookmark-o:before {
  content: ""; }

.fa-phone-square:before {
  content: ""; }

.fa-twitter:before {
  content: ""; }

.fa-facebook-f:before,
.fa-facebook:before {
  content: ""; }

.fa-github:before {
  content: ""; }

.fa-unlock:before {
  content: ""; }

.fa-credit-card:before {
  content: ""; }

.fa-feed:before,
.fa-rss:before {
  content: ""; }

.fa-hdd-o:before {
  content: ""; }

.fa-bullhorn:before {
  content: ""; }

.fa-bell:before {
  content: ""; }

.fa-certificate:before {
  content: ""; }

.fa-hand-o-right:before {
  content: ""; }

.fa-hand-o-left:before {
  content: ""; }

.fa-hand-o-up:before {
  content: ""; }

.fa-hand-o-down:before {
  content: ""; }

.fa-arrow-circle-left:before {
  content: ""; }

.fa-arrow-circle-right:before {
  content: ""; }

.fa-arrow-circle-up:before {
  content: ""; }

.fa-arrow-circle-down:before {
  content: ""; }

.fa-globe:before {
  content: ""; }

.fa-wrench:before {
  content: ""; }

.fa-tasks:before {
  content: ""; }

.fa-filter:before {
  content: ""; }

.fa-briefcase:before {
  content: ""; }

.fa-arrows-alt:before {
  content: ""; }

.fa-group:before,
.fa-users:before {
  content: ""; }

.fa-chain:before,
.fa-link:before {
  content: ""; }

.fa-cloud:before {
  content: ""; }

.fa-flask:before {
  content: ""; }

.fa-cut:before,
.fa-scissors:before {
  content: ""; }

.fa-copy:before,
.fa-files-o:before {
  content: ""; }

.fa-paperclip:before {
  content: ""; }

.fa-save:before,
.fa-floppy-o:before {
  content: ""; }

.fa-square:before {
  content: ""; }

.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
  content: ""; }

.fa-list-ul:before {
  content: ""; }

.fa-list-ol:before {
  content: ""; }

.fa-strikethrough:before {
  content: ""; }

.fa-underline:before {
  content: ""; }

.fa-table:before {
  content: ""; }

.fa-magic:before {
  content: ""; }

.fa-truck:before {
  content: ""; }

.fa-pinterest:before {
  content: ""; }

.fa-pinterest-square:before {
  content: ""; }

.fa-google-plus-square:before {
  content: ""; }

.fa-google-plus:before {
  content: ""; }

.fa-money:before {
  content: ""; }

.fa-caret-down:before {
  content: ""; }

.fa-caret-up:before {
  content: ""; }

.fa-caret-left:before {
  content: ""; }

.fa-caret-right:before {
  content: ""; }

.fa-columns:before {
  content: ""; }

.fa-unsorted:before,
.fa-sort:before {
  content: ""; }

.fa-sort-down:before,
.fa-sort-desc:before {
  content: ""; }

.fa-sort-up:before,
.fa-sort-asc:before {
  content: ""; }

.fa-envelope:before {
  content: ""; }

.fa-linkedin:before {
  content: ""; }

.fa-rotate-left:before,
.fa-undo:before {
  content: ""; }

.fa-legal:before,
.fa-gavel:before {
  content: ""; }

.fa-dashboard:before,
.fa-tachometer:before {
  content: ""; }

.fa-comment-o:before {
  content: ""; }

.fa-comments-o:before {
  content: ""; }

.fa-flash:before,
.fa-bolt:before {
  content: ""; }

.fa-sitemap:before {
  content: ""; }

.fa-umbrella:before {
  content: ""; }

.fa-paste:before,
.fa-clipboard:before {
  content: ""; }

.fa-lightbulb-o:before {
  content: ""; }

.fa-exchange:before {
  content: ""; }

.fa-cloud-download:before {
  content: ""; }

.fa-cloud-upload:before {
  content: ""; }

.fa-user-md:before {
  content: ""; }

.fa-stethoscope:before {
  content: ""; }

.fa-suitcase:before {
  content: ""; }

.fa-bell-o:before {
  content: ""; }

.fa-coffee:before {
  content: ""; }

.fa-cutlery:before {
  content: ""; }

.fa-file-text-o:before {
  content: ""; }

.fa-building-o:before {
  content: ""; }

.fa-hospital-o:before {
  content: ""; }

.fa-ambulance:before {
  content: ""; }

.fa-medkit:before {
  content: ""; }

.fa-fighter-jet:before {
  content: ""; }

.fa-beer:before {
  content: ""; }

.fa-h-square:before {
  content: ""; }

.fa-plus-square:before {
  content: ""; }

.fa-angle-double-left:before {
  content: ""; }

.fa-angle-double-right:before {
  content: ""; }

.fa-angle-double-up:before {
  content: ""; }

.fa-angle-double-down:before {
  content: ""; }

.fa-angle-left:before {
  content: ""; }

.fa-angle-right:before {
  content: ""; }

.fa-angle-up:before {
  content: ""; }

.fa-angle-down:before {
  content: ""; }

.fa-desktop:before {
  content: ""; }

.fa-laptop:before {
  content: ""; }

.fa-tablet:before {
  content: ""; }

.fa-mobile-phone:before,
.fa-mobile:before {
  content: ""; }

.fa-circle-o:before {
  content: ""; }

.fa-quote-left:before {
  content: ""; }

.fa-quote-right:before {
  content: ""; }

.fa-spinner:before {
  content: ""; }

.fa-circle:before {
  content: ""; }

.fa-mail-reply:before,
.fa-reply:before {
  content: ""; }

.fa-github-alt:before {
  content: ""; }

.fa-folder-o:before {
  content: ""; }

.fa-folder-open-o:before {
  content: ""; }

.fa-smile-o:before {
  content: ""; }

.fa-frown-o:before {
  content: ""; }

.fa-meh-o:before {
  content: ""; }

.fa-gamepad:before {
  content: ""; }

.fa-keyboard-o:before {
  content: ""; }

.fa-flag-o:before {
  content: ""; }

.fa-flag-checkered:before {
  content: ""; }

.fa-terminal:before {
  content: ""; }

.fa-code:before {
  content: ""; }

.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: ""; }

.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: ""; }

.fa-location-arrow:before {
  content: ""; }

.fa-crop:before {
  content: ""; }

.fa-code-fork:before {
  content: ""; }

.fa-unlink:before,
.fa-chain-broken:before {
  content: ""; }

.fa-question:before {
  content: ""; }

.fa-info:before {
  content: ""; }

.fa-exclamation:before {
  content: ""; }

.fa-superscript:before {
  content: ""; }

.fa-subscript:before {
  content: ""; }

.fa-eraser:before {
  content: ""; }

.fa-puzzle-piece:before {
  content: ""; }

.fa-microphone:before {
  content: ""; }

.fa-microphone-slash:before {
  content: ""; }

.fa-shield:before {
  content: ""; }

.fa-calendar-o:before {
  content: ""; }

.fa-fire-extinguisher:before {
  content: ""; }

.fa-rocket:before {
  content: ""; }

.fa-maxcdn:before {
  content: ""; }

.fa-chevron-circle-left:before {
  content: ""; }

.fa-chevron-circle-right:before {
  content: ""; }

.fa-chevron-circle-up:before {
  content: ""; }

.fa-chevron-circle-down:before {
  content: ""; }

.fa-html5:before {
  content: ""; }

.fa-css3:before {
  content: ""; }

.fa-anchor:before {
  content: ""; }

.fa-unlock-alt:before {
  content: ""; }

.fa-bullseye:before {
  content: ""; }

.fa-ellipsis-h:before {
  content: ""; }

.fa-ellipsis-v:before {
  content: ""; }

.fa-rss-square:before {
  content: ""; }

.fa-play-circle:before {
  content: ""; }

.fa-ticket:before {
  content: ""; }

.fa-minus-square:before {
  content: ""; }

.fa-minus-square-o:before {
  content: ""; }

.fa-level-up:before {
  content: ""; }

.fa-level-down:before {
  content: ""; }

.fa-check-square:before {
  content: ""; }

.fa-pencil-square:before {
  content: ""; }

.fa-external-link-square:before {
  content: ""; }

.fa-share-square:before {
  content: ""; }

.fa-compass:before {
  content: ""; }

.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: ""; }

.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: ""; }

.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: ""; }

.fa-euro:before,
.fa-eur:before {
  content: ""; }

.fa-gbp:before {
  content: ""; }

.fa-dollar:before,
.fa-usd:before {
  content: ""; }

.fa-rupee:before,
.fa-inr:before {
  content: ""; }

.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: ""; }

.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: ""; }

.fa-won:before,
.fa-krw:before {
  content: ""; }

.fa-bitcoin:before,
.fa-btc:before {
  content: ""; }

.fa-file:before {
  content: ""; }

.fa-file-text:before {
  content: ""; }

.fa-sort-alpha-asc:before {
  content: ""; }

.fa-sort-alpha-desc:before {
  content: ""; }

.fa-sort-amount-asc:before {
  content: ""; }

.fa-sort-amount-desc:before {
  content: ""; }

.fa-sort-numeric-asc:before {
  content: ""; }

.fa-sort-numeric-desc:before {
  content: ""; }

.fa-thumbs-up:before {
  content: ""; }

.fa-thumbs-down:before {
  content: ""; }

.fa-youtube-square:before {
  content: ""; }

.fa-youtube:before {
  content: ""; }

.fa-xing:before {
  content: ""; }

.fa-xing-square:before {
  content: ""; }

.fa-youtube-play:before {
  content: ""; }

.fa-dropbox:before {
  content: ""; }

.fa-stack-overflow:before {
  content: ""; }

.fa-instagram:before {
  content: ""; }

.fa-flickr:before {
  content: ""; }

.fa-adn:before {
  content: ""; }

.fa-bitbucket:before {
  content: ""; }

.fa-bitbucket-square:before {
  content: ""; }

.fa-tumblr:before {
  content: ""; }

.fa-tumblr-square:before {
  content: ""; }

.fa-long-arrow-down:before {
  content: ""; }

.fa-long-arrow-up:before {
  content: ""; }

.fa-long-arrow-left:before {
  content: ""; }

.fa-long-arrow-right:before {
  content: ""; }

.fa-apple:before {
  content: ""; }

.fa-windows:before {
  content: ""; }

.fa-android:before {
  content: ""; }

.fa-linux:before {
  content: ""; }

.fa-dribbble:before {
  content: ""; }

.fa-skype:before {
  content: ""; }

.fa-foursquare:before {
  content: ""; }

.fa-trello:before {
  content: ""; }

.fa-female:before {
  content: ""; }

.fa-male:before {
  content: ""; }

.fa-gittip:before,
.fa-gratipay:before {
  content: ""; }

.fa-sun-o:before {
  content: ""; }

.fa-moon-o:before {
  content: ""; }

.fa-archive:before {
  content: ""; }

.fa-bug:before {
  content: ""; }

.fa-vk:before {
  content: ""; }

.fa-weibo:before {
  content: ""; }

.fa-renren:before {
  content: ""; }

.fa-pagelines:before {
  content: ""; }

.fa-stack-exchange:before {
  content: ""; }

.fa-arrow-circle-o-right:before {
  content: ""; }

.fa-arrow-circle-o-left:before {
  content: ""; }

.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: ""; }

.fa-dot-circle-o:before {
  content: ""; }

.fa-wheelchair:before {
  content: ""; }

.fa-vimeo-square:before {
  content: ""; }

.fa-turkish-lira:before,
.fa-try:before {
  content: ""; }

.fa-plus-square-o:before {
  content: ""; }

.fa-space-shuttle:before {
  content: ""; }

.fa-slack:before {
  content: ""; }

.fa-envelope-square:before {
  content: ""; }

.fa-wordpress:before {
  content: ""; }

.fa-openid:before {
  content: ""; }

.fa-institution:before,
.fa-bank:before,
.fa-university:before {
  content: ""; }

.fa-mortar-board:before,
.fa-graduation-cap:before {
  content: ""; }

.fa-yahoo:before {
  content: ""; }

.fa-google:before {
  content: ""; }

.fa-reddit:before {
  content: ""; }

.fa-reddit-square:before {
  content: ""; }

.fa-stumbleupon-circle:before {
  content: ""; }

.fa-stumbleupon:before {
  content: ""; }

.fa-delicious:before {
  content: ""; }

.fa-digg:before {
  content: ""; }

.fa-pied-piper-pp:before {
  content: ""; }

.fa-pied-piper-alt:before {
  content: ""; }

.fa-drupal:before {
  content: ""; }

.fa-joomla:before {
  content: ""; }

.fa-language:before {
  content: ""; }

.fa-fax:before {
  content: ""; }

.fa-building:before {
  content: ""; }

.fa-child:before {
  content: ""; }

.fa-paw:before {
  content: ""; }

.fa-spoon:before {
  content: ""; }

.fa-cube:before {
  content: ""; }

.fa-cubes:before {
  content: ""; }

.fa-behance:before {
  content: ""; }

.fa-behance-square:before {
  content: ""; }

.fa-steam:before {
  content: ""; }

.fa-steam-square:before {
  content: ""; }

.fa-recycle:before {
  content: ""; }

.fa-automobile:before,
.fa-car:before {
  content: ""; }

.fa-cab:before,
.fa-taxi:before {
  content: ""; }

.fa-tree:before {
  content: ""; }

.fa-spotify:before {
  content: ""; }

.fa-deviantart:before {
  content: ""; }

.fa-soundcloud:before {
  content: ""; }

.fa-database:before {
  content: ""; }

.fa-file-pdf-o:before {
  content: ""; }

.fa-file-word-o:before {
  content: ""; }

.fa-file-excel-o:before {
  content: ""; }

.fa-file-powerpoint-o:before {
  content: ""; }

.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
  content: ""; }

.fa-file-zip-o:before,
.fa-file-archive-o:before {
  content: ""; }

.fa-file-sound-o:before,
.fa-file-audio-o:before {
  content: ""; }

.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: ""; }

.fa-file-code-o:before {
  content: ""; }

.fa-vine:before {
  content: ""; }

.fa-codepen:before {
  content: ""; }

.fa-jsfiddle:before {
  content: ""; }

.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
  content: ""; }

.fa-circle-o-notch:before {
  content: ""; }

.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before {
  content: ""; }

.fa-ge:before,
.fa-empire:before {
  content: ""; }

.fa-git-square:before {
  content: ""; }

.fa-git:before {
  content: ""; }

.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
  content: ""; }

.fa-tencent-weibo:before {
  content: ""; }

.fa-qq:before {
  content: ""; }

.fa-wechat:before,
.fa-weixin:before {
  content: ""; }

.fa-send:before,
.fa-paper-plane:before {
  content: ""; }

.fa-send-o:before,
.fa-paper-plane-o:before {
  content: ""; }

.fa-history:before {
  content: ""; }

.fa-circle-thin:before {
  content: ""; }

.fa-header:before {
  content: ""; }

.fa-paragraph:before {
  content: ""; }

.fa-sliders:before {
  content: ""; }

.fa-share-alt:before {
  content: ""; }

.fa-share-alt-square:before {
  content: ""; }

.fa-bomb:before {
  content: ""; }

.fa-soccer-ball-o:before,
.fa-futbol-o:before {
  content: ""; }

.fa-tty:before {
  content: ""; }

.fa-binoculars:before {
  content: ""; }

.fa-plug:before {
  content: ""; }

.fa-slideshare:before {
  content: ""; }

.fa-twitch:before {
  content: ""; }

.fa-yelp:before {
  content: ""; }

.fa-newspaper-o:before {
  content: ""; }

.fa-wifi:before {
  content: ""; }

.fa-calculator:before {
  content: ""; }

.fa-paypal:before {
  content: ""; }

.fa-google-wallet:before {
  content: ""; }

.fa-cc-visa:before {
  content: ""; }

.fa-cc-mastercard:before {
  content: ""; }

.fa-cc-discover:before {
  content: ""; }

.fa-cc-amex:before {
  content: ""; }

.fa-cc-paypal:before {
  content: ""; }

.fa-cc-stripe:before {
  content: ""; }

.fa-bell-slash:before {
  content: ""; }

.fa-bell-slash-o:before {
  content: ""; }

.fa-trash:before {
  content: ""; }

.fa-copyright:before {
  content: ""; }

.fa-at:before {
  content: ""; }

.fa-eyedropper:before {
  content: ""; }

.fa-paint-brush:before {
  content: ""; }

.fa-birthday-cake:before {
  content: ""; }

.fa-area-chart:before {
  content: ""; }

.fa-pie-chart:before {
  content: ""; }

.fa-line-chart:before {
  content: ""; }

.fa-lastfm:before {
  content: ""; }

.fa-lastfm-square:before {
  content: ""; }

.fa-toggle-off:before {
  content: ""; }

.fa-toggle-on:before {
  content: ""; }

.fa-bicycle:before {
  content: ""; }

.fa-bus:before {
  content: ""; }

.fa-ioxhost:before {
  content: ""; }

.fa-angellist:before {
  content: ""; }

.fa-cc:before {
  content: ""; }

.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
  content: ""; }

.fa-meanpath:before {
  content: ""; }

.fa-buysellads:before {
  content: ""; }

.fa-connectdevelop:before {
  content: ""; }

.fa-dashcube:before {
  content: ""; }

.fa-forumbee:before {
  content: ""; }

.fa-leanpub:before {
  content: ""; }

.fa-sellsy:before {
  content: ""; }

.fa-shirtsinbulk:before {
  content: ""; }

.fa-simplybuilt:before {
  content: ""; }

.fa-skyatlas:before {
  content: ""; }

.fa-cart-plus:before {
  content: ""; }

.fa-cart-arrow-down:before {
  content: ""; }

.fa-diamond:before {
  content: ""; }

.fa-ship:before {
  content: ""; }

.fa-user-secret:before {
  content: ""; }

.fa-motorcycle:before {
  content: ""; }

.fa-street-view:before {
  content: ""; }

.fa-heartbeat:before {
  content: ""; }

.fa-venus:before {
  content: ""; }

.fa-mars:before {
  content: ""; }

.fa-mercury:before {
  content: ""; }

.fa-intersex:before,
.fa-transgender:before {
  content: ""; }

.fa-transgender-alt:before {
  content: ""; }

.fa-venus-double:before {
  content: ""; }

.fa-mars-double:before {
  content: ""; }

.fa-venus-mars:before {
  content: ""; }

.fa-mars-stroke:before {
  content: ""; }

.fa-mars-stroke-v:before {
  content: ""; }

.fa-mars-stroke-h:before {
  content: ""; }

.fa-neuter:before {
  content: ""; }

.fa-genderless:before {
  content: ""; }

.fa-facebook-official:before {
  content: ""; }

.fa-pinterest-p:before {
  content: ""; }

.fa-whatsapp:before {
  content: ""; }

.fa-server:before {
  content: ""; }

.fa-user-plus:before {
  content: ""; }

.fa-user-times:before {
  content: ""; }

.fa-hotel:before,
.fa-bed:before {
  content: ""; }

.fa-viacoin:before {
  content: ""; }

.fa-train:before {
  content: ""; }

.fa-subway:before {
  content: ""; }

.fa-medium:before {
  content: ""; }

.fa-yc:before,
.fa-y-combinator:before {
  content: ""; }

.fa-optin-monster:before {
  content: ""; }

.fa-opencart:before {
  content: ""; }

.fa-expeditedssl:before {
  content: ""; }

.fa-battery-4:before,
.fa-battery:before,
.fa-battery-full:before {
  content: ""; }

.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: ""; }

.fa-battery-2:before,
.fa-battery-half:before {
  content: ""; }

.fa-battery-1:before,
.fa-battery-quarter:before {
  content: ""; }

.fa-battery-0:before,
.fa-battery-empty:before {
  content: ""; }

.fa-mouse-pointer:before {
  content: ""; }

.fa-i-cursor:before {
  content: ""; }

.fa-object-group:before {
  content: ""; }

.fa-object-ungroup:before {
  content: ""; }

.fa-sticky-note:before {
  content: ""; }

.fa-sticky-note-o:before {
  content: ""; }

.fa-cc-jcb:before {
  content: ""; }

.fa-cc-diners-club:before {
  content: ""; }

.fa-clone:before {
  content: ""; }

.fa-balance-scale:before {
  content: ""; }

.fa-hourglass-o:before {
  content: ""; }

.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: ""; }

.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: ""; }

.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: ""; }

.fa-hourglass:before {
  content: ""; }

.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: ""; }

.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
  content: ""; }

.fa-hand-scissors-o:before {
  content: ""; }

.fa-hand-lizard-o:before {
  content: ""; }

.fa-hand-spock-o:before {
  content: ""; }

.fa-hand-pointer-o:before {
  content: ""; }

.fa-hand-peace-o:before {
  content: ""; }

.fa-trademark:before {
  content: ""; }

.fa-registered:before {
  content: ""; }

.fa-creative-commons:before {
  content: ""; }

.fa-gg:before {
  content: ""; }

.fa-gg-circle:before {
  content: ""; }

.fa-tripadvisor:before {
  content: ""; }

.fa-odnoklassniki:before {
  content: ""; }

.fa-odnoklassniki-square:before {
  content: ""; }

.fa-get-pocket:before {
  content: ""; }

.fa-wikipedia-w:before {
  content: ""; }

.fa-safari:before {
  content: ""; }

.fa-chrome:before {
  content: ""; }

.fa-firefox:before {
  content: ""; }

.fa-opera:before {
  content: ""; }

.fa-internet-explorer:before {
  content: ""; }

.fa-tv:before,
.fa-television:before {
  content: ""; }

.fa-contao:before {
  content: ""; }

.fa-500px:before {
  content: ""; }

.fa-amazon:before {
  content: ""; }

.fa-calendar-plus-o:before {
  content: ""; }

.fa-calendar-minus-o:before {
  content: ""; }

.fa-calendar-times-o:before {
  content: ""; }

.fa-calendar-check-o:before {
  content: ""; }

.fa-industry:before {
  content: ""; }

.fa-map-pin:before {
  content: ""; }

.fa-map-signs:before {
  content: ""; }

.fa-map-o:before {
  content: ""; }

.fa-map:before {
  content: ""; }

.fa-commenting:before {
  content: ""; }

.fa-commenting-o:before {
  content: ""; }

.fa-houzz:before {
  content: ""; }

.fa-vimeo:before {
  content: ""; }

.fa-black-tie:before {
  content: ""; }

.fa-fonticons:before {
  content: ""; }

.fa-reddit-alien:before {
  content: ""; }

.fa-edge:before {
  content: ""; }

.fa-credit-card-alt:before {
  content: ""; }

.fa-codiepie:before {
  content: ""; }

.fa-modx:before {
  content: ""; }

.fa-fort-awesome:before {
  content: ""; }

.fa-usb:before {
  content: ""; }

.fa-product-hunt:before {
  content: ""; }

.fa-mixcloud:before {
  content: ""; }

.fa-scribd:before {
  content: ""; }

.fa-pause-circle:before {
  content: ""; }

.fa-pause-circle-o:before {
  content: ""; }

.fa-stop-circle:before {
  content: ""; }

.fa-stop-circle-o:before {
  content: ""; }

.fa-shopping-bag:before {
  content: ""; }

.fa-shopping-basket:before {
  content: ""; }

.fa-hashtag:before {
  content: ""; }

.fa-bluetooth:before {
  content: ""; }

.fa-bluetooth-b:before {
  content: ""; }

.fa-percent:before {
  content: ""; }

.fa-gitlab:before {
  content: ""; }

.fa-wpbeginner:before {
  content: ""; }

.fa-wpforms:before {
  content: ""; }

.fa-envira:before {
  content: ""; }

.fa-universal-access:before {
  content: ""; }

.fa-wheelchair-alt:before {
  content: ""; }

.fa-question-circle-o:before {
  content: ""; }

.fa-blind:before {
  content: ""; }

.fa-audio-description:before {
  content: ""; }

.fa-volume-control-phone:before {
  content: ""; }

.fa-braille:before {
  content: ""; }

.fa-assistive-listening-systems:before {
  content: ""; }

.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before {
  content: ""; }

.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before {
  content: ""; }

.fa-glide:before {
  content: ""; }

.fa-glide-g:before {
  content: ""; }

.fa-signing:before,
.fa-sign-language:before {
  content: ""; }

.fa-low-vision:before {
  content: ""; }

.fa-viadeo:before {
  content: ""; }

.fa-viadeo-square:before {
  content: ""; }

.fa-snapchat:before {
  content: ""; }

.fa-snapchat-ghost:before {
  content: ""; }

.fa-snapchat-square:before {
  content: ""; }

.fa-pied-piper:before {
  content: ""; }

.fa-first-order:before {
  content: ""; }

.fa-yoast:before {
  content: ""; }

.fa-themeisle:before {
  content: ""; }

.fa-google-plus-circle:before,
.fa-google-plus-official:before {
  content: ""; }

.fa-fa:before,
.fa-font-awesome:before {
  content: ""; }

.fa-handshake-o:before {
  content: ""; }

.fa-envelope-open:before {
  content: ""; }

.fa-envelope-open-o:before {
  content: ""; }

.fa-linode:before {
  content: ""; }

.fa-address-book:before {
  content: ""; }

.fa-address-book-o:before {
  content: ""; }

.fa-vcard:before,
.fa-address-card:before {
  content: ""; }

.fa-vcard-o:before,
.fa-address-card-o:before {
  content: ""; }

.fa-user-circle:before {
  content: ""; }

.fa-user-circle-o:before {
  content: ""; }

.fa-user-o:before {
  content: ""; }

.fa-id-badge:before {
  content: ""; }

.fa-drivers-license:before,
.fa-id-card:before {
  content: ""; }

.fa-drivers-license-o:before,
.fa-id-card-o:before {
  content: ""; }

.fa-quora:before {
  content: ""; }

.fa-free-code-camp:before {
  content: ""; }

.fa-telegram:before {
  content: ""; }

.fa-thermometer-4:before,
.fa-thermometer:before,
.fa-thermometer-full:before {
  content: ""; }

.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
  content: ""; }

.fa-thermometer-2:before,
.fa-thermometer-half:before {
  content: ""; }

.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
  content: ""; }

.fa-thermometer-0:before,
.fa-thermometer-empty:before {
  content: ""; }

.fa-shower:before {
  content: ""; }

.fa-bathtub:before,
.fa-s15:before,
.fa-bath:before {
  content: ""; }

.fa-podcast:before {
  content: ""; }

.fa-window-maximize:before {
  content: ""; }

.fa-window-minimize:before {
  content: ""; }

.fa-window-restore:before {
  content: ""; }

.fa-times-rectangle:before,
.fa-window-close:before {
  content: ""; }

.fa-times-rectangle-o:before,
.fa-window-close-o:before {
  content: ""; }

.fa-bandcamp:before {
  content: ""; }

.fa-grav:before {
  content: ""; }

.fa-etsy:before {
  content: ""; }

.fa-imdb:before {
  content: ""; }

.fa-ravelry:before {
  content: ""; }

.fa-eercast:before {
  content: ""; }

.fa-microchip:before {
  content: ""; }

.fa-snowflake-o:before {
  content: ""; }

.fa-superpowers:before {
  content: ""; }

.fa-wpexplorer:before {
  content: ""; }

.fa-meetup:before {
  content: ""; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto; }

/*
 * GOOGLE-WEBFONT
 */
/*
 * Streamline AG - Variables
 */
/*
 * Theme
 */
/*// Social Network Colors
$g-social-network-colors: () !default;
$g-social-network-colors: map-merge(( "facebook": $g-facebook, "google": $g-google, "twitter": $g-twitter, "instagram": $g-instagram, "github": $g-github ), $g-social-network-colors);*/
/*$g-theme-colors: () !default;
$g-theme-colors: map-merge(( "primary": $g-primary, "secondary": $g-secondary, "success": $g-success, "info": $g-info, "warning": $g-warning, "danger": $g-danger, "light": $g-light, "dark": $g-dark ), $g-theme-colors);

// Override Bootstrap
$theme-colors: $g-theme-colors;*/
/*// Content Spacing
// =============================================================
$g-content-space-y: 4.25rem !default

$g-spacer: 1rem !default;
$g-spacers: () !default;
$g-spacers: map-merge(( 0: 0, 1: ($g-spacer * .25), 2: ($g-spacer * .5), 3: $g-spacer, 4: ($g-spacer * 1.5), 5: ($g-spacer * 2), 6: ($g-spacer * 2.5), 7: ($g-spacer * 3), 8: ($g-spacer * 3.5), 9: ($g-spacer * 4) ), $g-spacers);*/
/*// Box Shadow
// =============================================================

$g-box-shadow-sm: 0 .20rem 1.25rem 0 rgba($g-dark, 0.07) !default
$g-box-shadow-lg: 0 .4rem 2.2rem 0 rgba($g-dark, 0.1) !default*/
/*// Pagination
// =============================================================

// Colors
$g-pagination-color: $g-secondary !default;

// Border
$g-pagination-border-color: $g-grey !default;

// Hover
$g-pagination-hover-color: $g-light !default;
$g-pagination-hover-bg: $g-primary !default;
$g-pagination-hover-border-color: $g-primary !default;


// Active
$g-pagination-active-color: $g-light !default;
$g-pagination-active-bg: $g-primary !default;
$g-pagination-active-border-color: $g-primary !default;



// Border Radius
// =============================================================

$g-border-radius: .25rem !default;
$g-border-radius-pill: 6.25rem !default;
$g-border-radius-circle: 50% !default;*/
/*// Forms Inputs
// =============================================================

// Color
$g-input-color: $g-dark !default;
$g-input-group-addon-color: $g-gray-200 !default;

// Border Colors
$g-form-border-color: $g-grey !default;
$g-input-border-color: $g-grey !default;
$g-input-focus-border-color: lighten($g-primary, 30%) !default;

// Background Colors
$g-input-group-addon-bg: transparent !default;

// Focus Colors
$g-input-focus-color: $g-dark !default;
$g-input-placeholder-color: $g-gray-400 !default;

// Box Shadow
$g-input-focus-box-shadow: none !default;



// Forms States + Icon
// =============================================================

// Form Icon
$g-form-icon-color: $g-gray-200 !default;

// Forms States (e.g. Disabled)
$g-form-label-color-disabled: $g-text-muted !default;
$g-custom-control-indicator-disabled-bg: $g-gray-150 !default;



// Form Toggles
// =============================================================

// Unchecked (Off)
$g-form-toggle-color: $g-gray-150 !default;
$g-form-toggle-border-color: $g-gray-150 !default;
$g-form-toggle-circle-bg-color: $g-gray-150 !default;

// Checked (On)
$g-form-toggle-color-checked: $g-white !default;
$g-form-toggle-bg-color-checked: theme-color("primary") !default;
$g-form-toggle-border-color-checked: theme-color("primary") !default;
$g-form-toggle-circle-bg-color-checked: $g-white !default;

// Disabled
$g-form-toggle-color-disabled: $g-gray-100 !default;
$g-form-toggle-border-color-disabled: $g-gray-100 !default;
$g-form-toggle-circle-bg-color-disabled: $g-gray-100 !default;

// Disabled + Checked (On)
$g-form-toggle-bg-color-disabled-checked: $g-gray-100 !default;
$g-form-toggle-border-color-disabled-checked: $g-gray-100 !default;



// Close
// =============================================================

$g-close-font-size: $g-font-size-base * 2 !default;
$g-close-font-weight: $g-font-weight-light !default;
$g-close-color: $g-dark !default;
$g-close-text-shadow: none !default;



// Alert
// =============================================================

// Spacing
$g-alert-padding-y: 1rem !default;
$g-alert-padding-x: 1.25rem !default;

// Border
$g-alert-border-width: 0 !default;



// Progress Bars
// =============================================================

$g-progress-bg: $g-gray-50 !default;



// List Groups
// =============================================================

$g-list-group-border-color: $g-gray-100 !default;
$g-list-group-hover-bg: theme-color("light") !default;



// Blockquote
// =============================================================

// Spacing
$g-blockquote-padding-y: 1.5rem !default;
$g-blockquote-padding-x: 1.25rem !default;

// Background Color
$g-blockquote-bg: theme-color("light") !default;

// Border Color
$g-blockquote-border-color: theme-color("primary") !default;

// Content
$g-blockquote-small-color: $g-dark !default;
$g-blockquote-font-size: ($g-font-size-base * 1.25) !default;
$g-blockquote-font-weight: $g-font-weight-light !default;



// Card
// =============================================================

$g-card-cap-bg: theme-color("light") !default;
$g-card-border-color: $g-gray-100 !default;



// Modals
// =============================================================

// Background Color
$g-modal-backdrop-bg: $g-dark !default;
$g-modal-backdrop-opacity: .1 !default;

// Border Color
$g-modal-content-border-color: $g-gray-100 !default;

// Box Shadow
$g-modal-content-box-shadow-xs: 0 .25rem .6rem rgba(theme-color("dark"), .07) !default;
$g-modal-content-box-shadow-sm-up: 0 .5rem 1.625rem rgba(theme-color("dark"), .07) !default;

// Sizes
$g-modal-lg: 800px !default;
$g-modal-md: 500px !default;
$g-modal-sm: 350px !default;



// Popovers
// =============================================================

// Background Color
$g-popover-header-bg: theme-color("light") !default;

// Border Color
$g-popover-border-color: $g-gray-100 !default;

// Box Shadow
$g-popover-box-shadow: 0 .25rem .6rem rgba(theme-color("dark"), .07) !default;



// Navbar
// =============================================================

// Dark Mode Colors
$g-navbar-dark-color: rgba($g-white, .9) !default;
$g-navbar-dark-hover-color: $g-white !default;

// Onscroll
$navbar-bg-onscroll: lighten(theme-color("primary"), 5%) !default;



// Drop Down
// =============================================================

// $g-dropdown-link-active-bg:                                     transparent !default;
$g-dropdown-link-hover-bg: theme-color("light") !default;
$g-dropdown-border-color: $g-gray-100 !default;

// Dropdown menu container and contents.
$g-dropdown-min-width: 200px !default;
$g-dropdown-box-shadow: 0 .4rem 2.2rem 0 rgba(27,30,36, .1) !default;



// Nav Tabs
// =============================================================

// Active Color
$nav-tabs-link-active-color: $g-dark !default;

// Border Color
$g-nav-tabs-border-color: $g-gray-100 !default;



// Carousel
// =============================================================

$g-carousel-control-color: $g-white !default;
$g-carousel-control-size: 2rem !default;



// Tooltips
// =============================================================

$g-tooltip-max-width: 200px !default;
$g-tooltip-color: $g-white !default;
$g-tooltip-bg: theme-color("primary") !default;
$g-tooltip-opacity: 1 !default;
$g-tooltip-padding-y: .5rem !default;
$g-tooltip-padding-x: 1rem !default;


// Text Inputs + Textarea
$input-height: 4rem !default;
$input-border-color: $g-gray-200 !default;
$input-border: 1px solid $input-border-color !default;
$input-background: #fff !default;
$input-error-color: $g-danger !default;
$input-success-color: $g-success !default;
$input-focus-color: $g-blue !default;
$input-font-size: 1.3rem !default;
$input-margin-bottom: 20px;
$input-margin: 0 0 $input-margin-bottom 0 !default;
$input-padding: 0 !default;
$input-transition: all .3s !default;
$label-font-size: 1.3rem !default;
$input-disabled-color: rgba(0,0,0, .42) !default;
$input-disabled-solid-color: #949494 !default;
$input-disabled-border: 1px dotted $input-disabled-color !default;
$input-invalid-border: 1px solid $input-error-color !default;
$placeholder-text-color: lighten($input-border-color, 20%) !default;
$gutter-width: 1.5rem !default;
$dropdown-item-height: 50px !default;*/
/*@import "mixins/content-centered";*/
/**
	CUSTOM COMPONENTS
**/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/**
	CUSTOM STYLE
**/
/*
 * Streamline AG - Custom style
 */
.text--black {
  color: #333; }
  .text--black .grid-image a {
    color: #333; }

.text--white {
  color: #fff; }
  .text--white .grid-image a {
    color: #fff; }

.text--primary {
  color: #AF1328; }
  .text--primary .grid-image a {
    color: #AF1328; }

.text--secondary {
  color: #205CB7; }
  .text--secondary .grid-image a {
    color: #205CB7; }

/*
 * Streamline AG - Custom style
 */
.bg--light {
  background-color: #fff; }

.bg--dark {
  background-color: #333; }

.bg--primary {
  background-color: #AF1328; }

.bg--secondary {
  background-color: #205CB7; }

.bg--grey {
  background-color: #efefef; }

.background-image {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1; }

/*
 * Streamline AG - Custom style
 */
html {
  font-size: 1rem;
  scroll-behavior: smooth;
  overflow-x: hidden; }

body {
  font-weight: 300;
  font-size: 1.2rem;
  font-family: "Lato", sans-serif;
  overflow-x: hidden;
  line-height: 1.6;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga", "kern";
  text-rendering: optimizelegibility;
  min-height: 100vh; }

section {
  position: relative; }

a {
  color: #AF1328;
  text-decoration: none; }
  a:hover {
    color: #d21730;
    text-decoration: none; }
  a:focus {
    outline: 0;
    text-decoration: none;
    box-shadow: none; }

p {
  line-height: 1.8; }
  @media (max-width: 1024px) {
    p {
      font-size: 1rem; } }

hr {
  border-top: 1px solid #AF1328 !important;
  margin: 30px 0; }

caption {
  caption-side: top;
  font-weight: 700; }

figure {
  margin-bottom: 0; }

.content {
  padding-top: 250px;
  min-height: 80vh; }
  @media (max-width: 1024px) {
    .content {
      padding-top: 150px; } }

.overlay {
  position: absolute !important;
  height: 100%;
  width: 100%;
  bottom: 0;
  right: 0;
  transition: 0.2s; }

.overlay-content {
  color: white;
  position: absolute !important;
  bottom: 50px;
  right: 80px;
  max-width: 50%;
  padding: 10px;
  background-color: rgba(210, 23, 48, 0.7); }

iframe {
  width: 100%; }

.thumb {
  max-width: 70%; }

.content-2 {
  padding-top: 50px; }

.minWidth {
  min-width: 130px; }

.Box_Login_Title {
  color: #AF1328;
  font-size: 1.4rem;
  padding: 5px 0px;
  font-weight: 700;
  margin: 1.3rem 0;
  letter-spacing: 1.5px; }

.Brands_Title {
  background: #5486d2;
  background: -moz-linear-gradient(top, #205cb7 0%, #5486d2 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #205cb7), color-stop(100%, #5486d2));
  background: -webkit-linear-gradient(top, #205cb7 0%, #5486d2 100%);
  background: -o-linear-gradient(top, #205cb7 0%, #5486d2 100%);
  background: linear-gradient(to top, #205cb7 0%, #5486d2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#205cb7, endColorstr=#5486d2);
  color: white;
  text-transform: uppercase;
  padding: 0 10px;
  font-weight: 700;
  margin-top: 1.3rem;
  font-size: 1.1rem; }

.title {
  margin: 40px 0;
  color: #AF1328;
  text-transform: uppercase; }

.h1 {
  margin-top: 1.3rem;
  letter-spacing: 1.5px; }

img[data-sizes="auto"] {
  display: block;
  width: 100%; }

.lazyload,
.lazyloading {
  opacity: 0; }

.lazyloaded {
  opacity: 1;
  -webkit-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  transition: opacity 300ms; }

hr {
  border-color: #efefef; }

[role=button] {
  cursor: pointer; }

small {
  font-size: 100%; }

/*.active{
  font-weight:600;
}*/
.catalogs ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.catalogs .row {
  margin: 0;
  border-bottom: 1px solid rgba(210, 23, 48, 0.7); }
  .catalogs .row a {
    padding-bottom: 5px; }

.catalogs img {
  max-width: 100px; }

.catalogs .Brands_Title {
  margin-top: 4.5rem; }

.catalogs .cat-menu {
  margin-top: 5rem; }
  .catalogs .cat-menu li {
    font-weight: 700; }
    .catalogs .cat-menu li:after {
      content: "|";
      color: #AF1328;
      padding: 0 5px; }
    .catalogs .cat-menu li:last-child:after {
      content: '';
      padding: 0; }

.price-menu span a {
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 5px; }

.TR {
  display: block; }

.mypager span {
  color: #AF1328 !important;
  text-decoration: underline;
  font-weight: 700;
  padding: 5px; }

.mypager a {
  color: #AF1328 !important;
  padding: 5px; }
  .mypager a:hover, .mypager a:focus {
    background-color: #AF1328;
    color: white !important;
    font-weight: 700; }

.legende {
  font-size: 1rem; }

.Product_Table {
  text-indent: 3px; }

.news img, .content img {
  max-width: 100%;
  height: auto; }

.delivery label {
  max-width: 70%; }

.delivery input[type=radio], .delivery input[type=checkbox] {
  appearance: none;
  display: inline-block;
  position: relative;
  background-color: #efefef;
  color: white;
  top: 0px;
  height: 25px;
  width: 25px;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 5px;
  outline: none;
  vertical-align: top; }

.delivery input[type=radio]:checked::before {
  position: absolute;
  font: 16px/1 'Open Sans', sans-serif;
  left: 8px;
  top: 3px;
  content: '\02143';
  transform: rotate(40deg); }

.delivery input[type=checkbox] {
  color: black; }

.delivery input[type=checkbox]:checked::before {
  position: absolute;
  font: 16px/1 'Open Sans', sans-serif;
  left: 7px;
  top: 2px;
  content: '\02143';
  transform: rotate(40deg); }

.delivery input[type=radio]:hover {
  background-color: #efefef;
  color: #AF1328;
  border: 1px solid #b1b1b1; }

.delivery input[type=radio]:checked {
  background-color: #efefef;
  color: #AF1328; }

.delivery input[type=checkbox] {
  border-radius: 0px;
  border: 1px solid black;
  background-color: white; }

.delivery input[type=checkbox]:checked {
  border: 1px solid #AF1328;
  color: #AF1328; }

.delivery input[type=checkbox]:hover {
  border: 1px solid #AF1328; }

.delivery label {
  font: 15px/1.7 'Lato', sans-serif;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer; }

/*------------------------------------
  Headings
------------------------------------*/
.h1, .h2, .h3, .h4, .h5, .h6, .h7,
h1, h2, h3, h4, h5, h6 {
  line-height: 1.6;
  letter-spacing: 1.5px; }

h2, .h2 {
  font-size: 2.5rem; }

@media (max-width: 767px) {
  h1, .h1 {
    font-size: 1.575rem; }
  h2, .h2 {
    font-size: 1.4rem; }
  h3, .h3 {
    font-size: 1.225rem; }
  h4, .h4 {
    font-size: 1.05rem; }
  h5, .h5 {
    font-size: 0.9625rem; }
  h6, .h6 {
    font-size: 0.875rem; } }

/*------------------------------------
  Highlight Color
------------------------------------*/
::-moz-selection {
  color: #fff;
  background-color: #AF1328; }

::selection {
  color: #fff;
  background-color: #AF1328; }

.bg-primary ::-moz-selection {
  color: #AF1328;
  background-color: #fff; }

.bg-primary ::selection {
  color: #AF1328;
  background-color: #fff; }

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms; }

.section-spacing {
  padding-top: 4.25rem;
  padding-bottom: 4.25rem; }

.section-spacer {
  padding-top: 4.25rem; }

/*======================================
  #Search
======================================*/
.search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05); }
  .search input {
    display: block;
    padding: 15px;
    color: #444;
    margin-bottom: 20px;
    font-size: 16px;
    width: 100%;
    border: 0;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.08);
    transition: all .25s ease;
    margin-bottom: 30px;
    outline: 0; }
  .search .search__close {
    position: absolute;
    top: 50%;
    right: 30px;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    transition: all .3s ease;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.8) rotate(90deg);
    background: transparent;
    border: 0;
    z-index: 10; }
  @media only screen and (max-width: 992px) {
    .search .search__close {
      width: 30px;
      height: 30px;
      font-size: 14px;
      right: 15px;
      margin-top: -15px; } }
  .search .search__stage {
    max-width: 600px;
    margin: 0 auto; }
  .search .search__form {
    display: flex;
    transition: all .3s ease;
    opacity: 0;
    transform: scale(0.9) translateX(50px);
    position: relative; }
    .search .search__form .fa-search {
      position: absolute;
      top: 50%;
      left: 23px;
      opacity: .3;
      font-size: 18px;
      line-height: 1;
      z-index: 1;
      transform: translateY(-50%); }
    .search .search__form .search__input {
      margin-bottom: 0;
      font-size: 18px;
      padding-left: 55px;
      box-shadow: none !important;
      border: 0 !important; }
      @media (max-width: 1024px) {
        .search .search__form .search__input {
          max-width: 80%; } }

.search__form .search__input:required:valid {
  box-shadow: none !important; }

.search__form .search-btn {
  top: 0px;
  right: 10px;
  padding: 0;
  width: 60px;
  overflow: hidden;
  transition: all .3s ease; }

.search__form .search-btn[disabled] {
  width: 0; }

@media only screen and (max-width: 992px) {
  .search__form .search__input {
    font-size: 16px; } }

.search-overlay {
  background-color: #000;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  transition: all .3s ease;
  cursor: pointer;
  visibility: hidden; }

@media only screen and (max-width: 992px) {
  .search-visible {
    overflow: hidden; } }

.search-visible .search {
  visibility: visible;
  opacity: 1; }

.search-visible .search__close {
  opacity: 1;
  visibility: visible;
  transform: scale(1) rotate(0);
  transition-delay: .5s; }

.search-visible .search__form {
  opacity: 1;
  transition-delay: .3s;
  transform: scale(1) translateX(0); }

.search-visible .search-overlay {
  opacity: .3;
  visibility: visible;
  transition-duration: .6s; }

/*======================================
  #Search Block
======================================*/
.search-block {
  overflow: hidden;
  position: relative;
  padding: 60px 0 50px !important; }

.search-result-info {
  padding: 0 20px;
  text-align: center;
  font-size: 14px;
  margin-top: 20px; }

.search-block .search__form {
  visibility: visible;
  opacity: 1;
  transform: none;
  position: relative;
  z-index: 2; }

.search-block .search__form input {
  box-shadow: 0 8px 20px -6px rgba(0, 0, 0, 0.1) !important; }

/*======================================
  #Search Results
======================================*/
.search-result {
  background-color: white;
  padding: 40px;
  box-shadow: 0 22px 43px -20px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  margin-bottom: 10px; }

.search-result:hover {
  box-shadow: 0 12px 23px -15px rgba(0, 0, 0, 0.1); }

.search-result p:last-of-type {
  margin-bottom: 0; }

@media only screen and (max-width: 768px) {
  .search-result {
    padding: 20px; } }

/*======================================
  #Search - No Results
======================================*/
.search-empty {
  width: 100%;
  padding: 5vh 20px;
  text-align: center;
  color: #999; }

.search-empty h3,
.search-empty h4,
.search-empty h5 {
  color: #999; }

.search-empty > i {
  font-size: 60px;
  display: block;
  line-height: 1;
  opacity: .3;
  margin-bottom: 20px; }

/*======================================
  #Search
======================================*/
.toggle-search a {
  font-size: 17px !important;
  text-align: center;
  padding: 0 !important; }

.mobile-actions {
  display: none;
  right: 50px;
  position: absolute;
  top: 0; }

@media only screen and (max-width: 992px) {
  .mobile-actions {
    display: block; } }

.mobile-actions ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex; }

.mobile-actions ul li a {
  width: 50px;
  height: 50px;
  font-size: 19px;
  display: flex;
  color: white;
  line-height: 1;
  align-items: center;
  justify-content: center; }

.top--light .mobile-actions ul li a {
  color: #111; }

.top--scroll-dark .mobile-actions ul li a {
  color: #111; }

.top--scroll-light .mobile-actions ul li a {
  color: white; }

.scroll .top--light .mobile-actions ul li a {
  color: #111; }

.scroll .top--dark .mobile-actions ul li a {
  color: white; }

.search__form a:before {
  font-family: FontAwesome;
  content: "\f061";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle; }

@media (max-width: 1024px) {
  .search-btn {
    line-height: 4rem !important; } }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.8); }
  70% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0); } }

@keyframes scroll {
  0% {
    opacity: 1;
    transform: translateY(0) scale(1); }
  80% {
    opacity: 0;
    transform: translateY(36px) scale(0.5); }
  82% {
    opacity: 0;
    transform: translateY(-5px) scale(0.9); } }

.min-height--10 {
  min-height: 10vh; }

.min-height--15 {
  min-height: 15vh; }

.min-height--20 {
  min-height: 20vh; }

.min-height--25 {
  min-height: 25vh; }

.min-height--30 {
  min-height: 30vh; }

.min-height--35 {
  min-height: 35vh; }

.min-height--40 {
  min-height: 40vh; }

.min-height--45 {
  min-height: 45vh; }

.min-height--50 {
  min-height: 50vh; }

.min-height--55 {
  min-height: 55vh; }

.min-height--60 {
  min-height: 60vh; }

.min-height--65 {
  min-height: 65vh; }

.min-height--70 {
  min-height: 70vh; }

.min-height--75 {
  min-height: 75vh; }

.min-height--80 {
  min-height: 80vh; }

.min-height--85 {
  min-height: 85vh; }

.min-height--90 {
  min-height: 90vh; }

.min-height--95 {
  min-height: 95vh; }

.min-height--100 {
  min-height: 100vh; }

.min-height-parent {
  min-height: inherit; }

.flex-h1 {
  flex: 1;
  position: relative;
  min-height: inherit; }

.sectiongrid .d-flex {
  display: block !important; }

/*======================================
  #Overlay
======================================*/
.overlay--dark {
  background: #333;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 5;
  transition: opacity .4s ease; }

.overlay--light {
  background: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 5;
  transition: opacity .4s ease; }

.opacity--0 {
  opacity: 0; }

.opacity--5 {
  opacity: .05; }

.opacity--10 {
  opacity: .1; }

.opacity--15 {
  opacity: .15; }

.opacity--20 {
  opacity: .2; }

.opacity--25 {
  opacity: .25; }

.opacity--30 {
  opacity: .3; }

.opacity--35 {
  opacity: .35; }

.opacity--40 {
  opacity: .4; }

.opacity--45 {
  opacity: .45; }

.opacity--50 {
  opacity: .5; }

.opacity--55 {
  opacity: .55; }

.opacity--60 {
  opacity: .6; }

.opacity--65 {
  opacity: .65; }

.opacity--70 {
  opacity: .7; }

.opacity--75 {
  opacity: .75; }

.opacity--80 {
  opacity: .8; }

.opacity--85 {
  opacity: .85; }

.opacity--90 {
  opacity: .9; }

.opacity--95 {
  opacity: .95; }

.opacity--100 {
  opacity: 1; }

.horizontal-end {
  position: relative;
  left: 100%;
  transform: translateX(-100%); }

.horizontal-start {
  position: relative;
  left: 0; }

.horizontal-center {
  position: relative;
  left: 50%;
  transform: translateX(-50%); }

.vertical-end {
  position: relative;
  top: 100%;
  transform: translateY(-100%); }

.vertical-start {
  position: relative;
  top: 0; }

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%); }

.vertical-center.horizontal-center {
  transform: translate(-50%, -50%); }

.vertical-center.horizontal-end {
  transform: translate(-100%, -50%); }

.vertical-end.horizontal-center {
  transform: translate(-50%, -100%); }

.vertical-end.horizontal-end {
  transform: translate(-100%, -100%); }

.max-width--10 {
  flex-basis: 10%;
  max-width: 10%; }

.max-width--20 {
  flex-basis: 20%;
  max-width: 20%; }

.max-width--30 {
  flex-basis: 30%;
  max-width: 30%; }

.max-width--40 {
  flex-basis: 40%;
  max-width: 40%; }

.max-width--50 {
  flex-basis: 50%;
  max-width: 50%; }

.max-width--60 {
  flex-basis: 60%;
  max-width: 60%; }

.max-width--70 {
  flex-basis: 70%;
  max-width: 70%; }

.max-width--80 {
  flex-basis: 80%;
  max-width: 80%; }

.max-width--90 {
  flex-basis: 90%;
  max-width: 90%; }

.max-width--100 {
  flex-basis: 100%;
  max-width: 100%; }

/*======================================
  #z-index
======================================*/
.z-index-content {
  z-index: 10; }

.button--primary {
  font-size: 1.1rem;
  background-color: #AF1328;
  line-height: 1.1rem;
  color: white;
  padding: 10px 20px;
  border: 1px solid #AF1328;
  letter-spacing: .4px;
  border-radius: 0px; }
  .button--primary:hover {
    background-color: rgba(255, 255, 255, 0);
    color: #AF1328; }
  .button--primary:focus {
    outline: 0; }
  .button--primary.inverted {
    background-color: rgba(255, 255, 255, 0);
    color: #AF1328; }
    .button--primary.inverted:hover {
      background-color: #AF1328;
      color: white; }

.button--secondary {
  font-size: 1.1rem;
  background-color: #205CB7;
  line-height: 1.1rem;
  color: white;
  padding: 10px 20px;
  border: 1px solid #205CB7;
  letter-spacing: .4px;
  border-radius: 0px; }
  .button--secondary:hover {
    background-color: rgba(255, 255, 255, 0);
    color: #205CB7; }
  .button--secondary.inverted {
    background-color: rgba(255, 255, 255, 0);
    color: #205CB7; }
    .button--secondary.inverted:hover {
      background-color: #205CB7;
      color: white; }

.button--black {
  font-size: 1.3rem;
  background-color: #333;
  line-height: 3rem;
  color: white;
  padding: 10px 20px;
  border: 1px solid #333;
  letter-spacing: .4px;
  border-radius: 0px; }
  .button--black:hover {
    background-color: rgba(255, 255, 255, 0);
    color: #333; }
  .button--black.inverted {
    background-color: rgba(255, 255, 255, 0);
    color: #333; }
    .button--black.inverted:hover {
      background-color: #333;
      color: white; }

.button--white {
  font-size: 1.3rem;
  background-color: #fff;
  line-height: 3rem;
  color: #333;
  padding: 10px 20px;
  border: 1px solid #fff;
  letter-spacing: .4px;
  border-radius: 0px; }
  .button--white:hover {
    background-color: rgba(255, 255, 255, 0);
    color: #fff; }
  .button--white.inverted {
    background-color: rgba(255, 255, 255, 0);
    color: #fff; }
    .button--white.inverted:hover {
      background-color: #fff;
      color: white; }

.button + .button {
  margin-left: 10px; }

button:hover, button:focus {
  outline: 0; }

/*======================================
  #Header
======================================*/
.top {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 500;
  transition: all .4s ease; }
  .top--light.transparent--bg {
    background: transparent;
    box-shadow: none; }
    .top--light.transparent--bg .logo--light {
      display: none; }
    .top--light.transparent--bg .logo--dark {
      display: block; }
  .top--dark.transparent--bg {
    background: transparent;
    box-shadow: none; }
    .top--dark.transparent--bg .logo--light {
      display: block; }
    .top--dark.transparent--bg .logo--dark {
      display: none; }
  .top .top-inner {
    margin: 0 auto;
    padding: 0 40px; }
    @media (max-width: 1024px) {
      .top .top-inner {
        padding: 0 20px; } }
  .top ul {
    list-style: none; }
  @media (max-width: 1199px) {
    .top .container {
      max-width: none; } }

.top--light {
  background: #fff;
  color: #333;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.5); }
  .top--light .logo--light {
    display: block;
    padding-left: 20px; }
    @media (max-width: 1024px) {
      .top--light .logo--light {
        padding-left: 0px; } }
  .top--light .logo--dark {
    display: none; }

.top--dark {
  background: #333;
  color: #fff; }
  .top--dark .logo--dark {
    display: block; }
  .top--dark .logo--light {
    display: none; }

.lang {
  justify-content: flex-end; }
  .lang input {
    padding-right: 5px;
    width: 25px;
    height: 25px; }
    .lang input:focus {
      outline: none; }

/*======================================
  #Scroll
======================================*/
.scroll .top--light {
  background: #fff;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.5); }
  @media (max-width: 1024px) {
    .scroll .top--light .inner-wrapper {
      padding-top: .8rem !important; } }
  .scroll .top--light .logo--light {
    display: block; }
  .scroll .top--light .logo--dark {
    display: none; }
  .scroll .top--light .logo {
    padding-left: 10px; }
    @media (max-width: 1024px) {
      .scroll .top--light .logo {
        padding-left: 0px; } }
    .scroll .top--light .logo img {
      height: 60px;
      transform: scale(0.9) translateZ(0);
      transition: all .7s ease .1s; }
      @media (max-width: 1024px) {
        .scroll .top--light .logo img {
          height: 40px;
          transform: none; } }

.scroll .top--dark {
  background: #333;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.5); }
  .scroll .top--dark .logo--light {
    display: none; }
  .scroll .top--dark .logo--dark {
    display: block; }

.scroll-light .logo--light {
  display: none; }

.scroll-light .logo--dark {
  display: block; }

.scroll-dark .logo--light {
  display: block; }

.scroll-dark .logo--dark {
  display: none; }

/*======================================
  #Logo
======================================*/
.logo {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all .3s ease;
  z-index: 5; }
  .logo img {
    height: 80px;
    display: block;
    max-width: 100%; }
    @media (max-width: 1024px) {
      .logo img {
        height: 50px; } }

/*======================================
  #Actions
======================================*/
.top ul {
  list-style: none;
  margin: 0; }

.top a {
  line-height: 6rem;
  outline: none; }

@media (max-width: 1024px) {
  .top .search-list .toggle-search {
    line-height: 4rem; } }

@media (max-width: 1024px) {
  .top .search-list a {
    line-height: 3rem; } }

.top .btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1.1rem; }

.actions a:last-child {
  padding-right: 0; }

@media (max-width: 1199px) {
  .actions {
    position: absolute;
    bottom: 0px;
    right: 50px; }
    .actions a {
      line-height: 5.2rem; } }
    @media (max-width: 1199px) and (max-width: 1024px) {
      .actions a {
        line-height: 3rem; } }

@media (max-width: 1199px) {
      .actions a i {
        font-size: 1.2rem; }
    .actions .btn {
      padding: 0.4rem; } }

.scroll .actions a, .scroll .search-list a {
  line-height: 3rem; }

@media (max-width: 1024px) {
  .search-list {
    line-height: 3rem; } }

/*======================================
  #No-Hero
======================================*/
.no-hero .hero {
  min-height: 125px;
  padding: 0px; }
  .no-hero .hero .hero-inner {
    display: none; }

/*======================================
  #Main navigation
======================================*/
.main-nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0; }
  .main-nav ul li {
    display: block;
    position: relative;
    transition: all .2s ease; }
    .main-nav ul li:hover {
      background: rgba(255, 255, 255, 0.03); }
    .main-nav ul li a {
      color: #333;
      display: block;
      font-size: 1.5rem;
      transition: all .2s ease;
      position: relative;
      white-space: nowrap;
      line-height: 95px;
      padding: 0 20px;
      font-weight: 400; }
      .main-nav ul li a i.fa-chevron-down {
        font-size: 70%;
        color: rgba(255, 255, 255, 0.5);
        margin-left: 3px; }
    .main-nav ul li ul {
      position: absolute;
      top: 100%;
      left: 0;
      text-align: left;
      background-color: #333;
      list-style: none;
      margin: 0;
      padding: 0;
      min-width: 250px;
      opacity: 0;
      visibility: hidden;
      transition: all .125s ease; }
      .main-nav ul li ul li {
        display: block;
        transition: all .2s ease;
        position: relative; }
        .main-nav ul li ul li:hover {
          background: rgba(255, 255, 255, 0.03); }
        .main-nav ul li ul li a {
          display: block;
          line-height: 1 !important;
          font-size: 1.2rem;
          color: white;
          padding: 10px 20px;
          font-weight: 300; }
          .main-nav ul li ul li a i {
            font-size: 70%;
            color: rgba(255, 255, 255, 0.5);
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%); }
      .main-nav ul li ul .go-right {
        left: auto;
        right: 0; }

.main-nav .expand {
  display: none; }
  @media (max-width: 1024px) {
    .main-nav .expand {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 50px;
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.08); }
      .main-nav .expand i {
        transition: transform 0.2s linear; }
      .main-nav .expand.open i {
        transform: rotate(45deg);
        color: #AF1328; } }

@media (max-width: 1024px) {
  .main-nav {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    top: 125px;
    background-color: #333;
    overflow-y: auto;
    transform: translateX(100%);
    transition: transform .35s ease; }
    .main-nav ul {
      flex-flow: row wrap; }
      .main-nav ul li {
        width: 100%; }
        .main-nav ul li a {
          border-top: 1px solid rgba(255, 255, 255, 0.08);
          line-height: 1 !important;
          padding: 15px 25px;
          font-weight: 300; }
          .main-nav ul li a i.fa-chevron-down {
            display: none; } }

.top--light.transparent--bg .main-nav .level-1:hover {
  background: rgba(255, 255, 255, 0.03); }

.top--light.transparent--bg .main-nav .level-1 a {
  color: #fff; }
  .top--light.transparent--bg .main-nav .level-1 a i.fa-chevron-down {
    color: #fff; }

.top--light.transparent--bg .main-nav .level-2 a, .top--light.transparent--bg .main-nav .level-3 a {
  color: #333; }
  .top--light.transparent--bg .main-nav .level-2 a i.fa-chevron-down, .top--light.transparent--bg .main-nav .level-3 a i.fa-chevron-down {
    color: #333; }

.mobile-nav-visible .top--light .main-nav .level-1:hover {
  background: rgba(255, 255, 255, 0.03); }

.mobile-nav-visible .top--light .main-nav .level-1 a {
  color: #333; }
  .mobile-nav-visible .top--light .main-nav .level-1 a i.fa-chevron-down {
    color: #333; }

.mobile-nav-visible .top--light .main-nav .level-1 .level-2 a, .mobile-nav-visible .top--light .main-nav .level-1 .level-3 a {
  color: #fff; }

.scroll .top--light .main-nav .level-1 a {
  color: #333;
  line-height: 60px; }
  .scroll .top--light .main-nav .level-1 a i.fa-chevron-down {
    color: #333; }

.top--dark.transparent--bg .main-nav .level-1:hover {
  background: rgba(255, 255, 255, 0.03); }

.top--dark.transparent--bg .main-nav .level-1 a {
  color: #333; }
  .top--dark.transparent--bg .main-nav .level-1 a i.fa-chevron-down {
    color: #333; }

.top--dark.transparent--bg .main-nav .level-2 a, .top--dark.transparent--bg .main-nav .level-3 a {
  color: #fff; }
  .top--dark.transparent--bg .main-nav .level-2 a i.fa-chevron-down, .top--dark.transparent--bg .main-nav .level-3 a i.fa-chevron-down {
    color: #fff; }

.scroll .top--dark .main-nav .level-1:hover {
  background: rgba(255, 255, 255, 0.03); }

.scroll .top--dark .main-nav .level-1 a {
  color: #fff; }
  .scroll .top--dark .main-nav .level-1 a i.fa-chevron-down {
    color: #fff; }

.mobile-nav-visible .top--dark .main-nav .level-1:hover {
  background: rgba(255, 255, 255, 0.03); }

.mobile-nav-visible .top--dark .main-nav .level-1 a {
  color: #fff; }
  .mobile-nav-visible .top--dark .main-nav .level-1 a i.fa-chevron-down {
    color: #fff; }

@media (max-width: 1024px) {
  .main-nav li.mobile-contact {
    display: block;
    width: 50%;
    flex-grow: 1; }
  .main-nav li.mobile-contact a {
    text-align: center;
    padding: 15px 0;
    font-weight: normal; }
  .main-nav li.mobile-contact a i {
    margin-right: 3px;
    font-size: 100%; }
  .main-nav li.mobile-contact:nth-child(2) {
    border-left: 1px solid rgba(255, 255, 255, 0.08); }
  .main-nav > ul > li:hover {
    background-color: inherit; }
  .main-nav > ul > li:hover > a {
    color: white; }
  .main-nav > ul > li ul {
    position: static;
    visibility: visible;
    opacity: 1;
    display: none;
    transition: none; }
  .main-nav > ul > li ul li a {
    background-color: rgba(255, 255, 255, 0.08);
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    padding: 15px 25px;
    position: relative; }
  .main-nav > ul > li ul li a > i {
    display: none; }
  .main-nav > ul > li ul li:hover {
    background: inherit; }
  .main-nav > ul > li ul li:hover a {
    color: white; }
  .main-nav > ul > li ul li ul {
    background-color: rgba(255, 255, 255, 0.08); }
  .main-nav > ul > li ul li ul li:hover {
    background: none; }
  .main-nav > ul > li ul li ul li:hover a {
    color: white; } }

@media (max-width: 1024px) {
  .mobile-nav-visible {
    overflow: hidden; }
  .mobile-nav-visible .main-nav {
    transform: translateX(0); } }

/*.main-nav > ul > li:hover,
	.main-nav > ul > li.touch-open {
		background: lighten($g-light, 7.5%);
	}*/
/*.main-nav > ul > li.btn {
		padding: 0 0 0 10px;
		display: flex;
		align-items: center;
	}

	.main-nav > ul > li.btn > a {
		line-height: 1 !important;
		padding: 10px 20px;
	}

	.main-nav > ul > li.btn:hover {
		background: transparent !important;
	}

	@media only screen and (max-width: 992px) {
		.main-nav > ul > li.btn {
			padding: 20px 20px 0;
			display: block;
			text-align: center;
		}

		.main-nav > ul > li.btn > a {
			display: inline-block;
		}
	}

	.main-nav > ul > li.lang > a {
		padding: 0;
	}

	.main-nav > ul > li.lang > a span {
		display: none;
	}

	.main-nav > ul > li.lang > a img {
		max-width: 18px;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.main-nav > ul > li.lang ul li a img {
		max-width: 18px;
		position: relative;
		bottom: -1px;
		margin-right: 4px;
	}

	@media only screen and (max-width: 992px) {
		.main-nav > ul > li.lang > a {
			display: none;
		}

		.main-nav > ul > li.lang ul {
			display: block;
			display: flex;
			flex-flow: row wrap;
			border-bottom: 1px solid rgba(255, 255, 255, 0.08);
		}

		.main-nav > ul > li.lang ul li {
			display: block;
			width: 50%;
			flex-grow: 1;
			border-left: 1px solid rgba(255, 255, 255, 0.08);
		}

		.main-nav > ul > li.lang ul li a {
			text-align: center !important;
			background-color: #111 !important;
			padding: 15px 0;
		}
	}*/
.main-nav > ul > li ul ul {
  top: 0;
  left: 100%; }

.main-nav > ul > li ul ul.go-right {
  left: auto;
  right: 100%; }

.main-nav > ul > li:hover > ul,
.main-nav > ul > li.touch-open > ul {
  opacity: 1;
  visibility: visible; }

.main-nav > ul > li:hover > ul li:hover > ul,
.main-nav > ul > li.touch-open > ul li:hover > ul,
.main-nav > ul > li:hover > ul li.touch-open > ul,
.main-nav > ul > li.touch-open > ul li.touch-open > ul {
  opacity: 1;
  visibility: visible; }

.top--light .main-nav > ul > .active > a {
  color: #AF1328; }

.top--light .main-nav > ul > li > ul > .active > a {
  font-weight: 700; }

.top--light .main-nav > ul > li > ul > li > ul > .active > a {
  font-weight: 700; }

.top--light .main-nav > ul > li a {
  color: #333; }

.top--light .main-nav > ul > li a i.fa-chevron-down,
.top--light .main-nav > ul > li a .fa-chevron-right {
  color: rgba(0, 0, 0, 0.5); }

.top--light .main-nav > ul > li:hover,
.scroll .top--light .main-nav > ul > li:hover {
  background: rgba(0, 0, 0, 0.02); }

.top--light .main-nav > ul > li ul {
  background: #205CB7;
  box-shadow: 0 8px 20px -6px rgba(0, 0, 0, 0.15); }

.top--light .main-nav > ul > li ul li a {
  color: #fff; }

.top--light .main-nav > ul > li ul li:hover {
  background: rgba(0, 0, 0, 0.02);
  color: #333; }

.top--scroll-light .main-nav > ul > li > a {
  color: white; }

.top--scroll-light .main-nav > ul > li > a i.fa-chevron-down {
  color: rgba(255, 255, 255, 0.5); }

@media (max-width: 1024px) {
  .top--scroll-light .main-nav > ul > li > a {
    color: inherit; } }

.top--scroll-dark .main-nav > ul > li > a {
  color: #111; }

.top--scroll-dark .main-nav > ul > li > a i.fa-chevron-down {
  color: rgba(0, 0, 0, 0.5); }

@media (max-width: 1024px) {
  .top--scroll-dark .main-nav > ul > li > a {
    color: inherit; } }

@media (max-width: 1024px) {
  .top--dark .main-nav > ul > li > a {
    color: white; } }

.scroll .top--light .main-nav > ul > li > a {
  color: #111; }

.scroll .top--light .main-nav > ul > li > a i.fa-chevron-down {
  color: rgba(0, 0, 0, 0.5); }

.scroll .top--dark .main-nav > ul > li > a {
  color: white; }

.scroll .top--dark .main-nav > ul > li > a i.fa-chevron-down {
  color: rgba(255, 255, 255, 0.5); }

@media (max-width: 1024px) {
  .top--light .main-nav {
    background: white; }
  .top--light .main-nav li.mobile-contact:nth-child(2) {
    border-left-color: rgba(0, 0, 0, 0.08); }
  .top--light .main-nav .expand {
    background-color: rgba(0, 0, 0, 0.04); }
  .top--light .main-nav > ul > li > a {
    border-top: 1px solid rgba(0, 0, 0, 0.08); }
  .top--light .main-nav > ul > li.lang ul {
    border-bottom-color: rgba(0, 0, 0, 0.08); }
  .top--light .main-nav > ul > li.lang ul li {
    border-left-color: rgba(0, 0, 0, 0.08); }
  .top--light .main-nav > ul > li.lang ul li a {
    background-color: white !important; }
  .top--light .main-nav > ul > li:hover {
    background: inherit; }
  .top--light .main-nav > ul > li:hover > a {
    color: #111; }
  .top--light .main-nav > ul > li ul {
    box-shadow: none; }
  .top--light .main-nav > ul > li ul li a {
    background-color: rgba(0, 0, 0, 0.04);
    border-top: 1px solid rgba(0, 0, 0, 0.08); }
  .top--light .main-nav > ul > li ul li ul {
    background-color: rgba(0, 0, 0, 0.04); }
  .top--light .main-nav > ul > li ul li:hover {
    background: inherit; }
  .top--light .main-nav > ul > li ul li:hover a {
    color: #111; } }

@media (min-width: 1025px) {
  .main-nav > ul > li ul {
    display: block !important; } }

/*======================================
  #Search
======================================*/
.toggle-search a {
  font-size: 1.2rem !important;
  text-align: center;
  padding: 0 !important; }

.mobile-actions {
  display: none;
  right: 50px;
  position: absolute;
  top: 0; }
  @media (max-width: 1024px) {
    .mobile-actions .mobile-actions {
      display: block; } }

.mobile-actions ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex; }

.mobile-actions ul li a {
  width: 50px;
  height: 50px;
  font-size: 19px !important;
  display: flex;
  color: white;
  line-height: 1;
  align-items: center;
  justify-content: center; }

.top--light .mobile-actions ul li a {
  color: #111; }

.top--scroll-dark .mobile-actions ul li a {
  color: #111; }

.top--scroll-light .mobile-actions ul li a {
  color: white; }

.scroll .top--light .mobile-actions ul li a {
  color: #111; }

.scroll .top--dark .mobile-actions ul li a {
  color: white; }

/*======================================
  #Toggle mobile nav (Hamburger)
======================================*/
.toggle-mobile-nav {
  width: 30px;
  position: relative;
  margin: 22px 0;
  display: none;
  z-index: 4;
  cursor: pointer; }
  @media (max-width: 1024px) {
    .toggle-mobile-nav {
      display: block; } }
  .toggle-mobile-nav span {
    display: block;
    height: 3px;
    border-radius: 4px;
    background-color: #333;
    margin-bottom: 5px;
    transition: all .4s; }
    .toggle-mobile-nav span:nth-child(2) {
      width: 80%; }
    .toggle-mobile-nav span:last-child {
      margin-bottom: 0;
      width: 95%; }

.top--light .toggle-mobile-nav span {
  background-color: #333; }

.top--scroll-dark .toggle-mobile-nav span {
  background-color: #333; }

.top--scroll-light .toggle-mobile-nav span {
  background-color: #fff; }

.scroll .top--light .toggle-mobile-nav span {
  background-color: #333; }

.scroll .top--dark .toggle-mobile-nav span {
  background-color: #fff; }

.mobile-nav-visible .toggle-mobile-nav span:nth-child(1) {
  transform: rotate(45deg) translateY(-3px);
  transform-origin: top left; }

.mobile-nav-visible .toggle-mobile-nav span:nth-child(2) {
  opacity: 0;
  transform: translateX(-20px); }

.mobile-nav-visible .toggle-mobile-nav span:nth-child(3) {
  transform: rotate(-45deg) translateY(3px);
  transform-origin: bottom left; }

.top--light .main-nav .level-2, .top--light .main-nav .level-3, .scroll .top--light .main-nav .level-2, .scroll .top--light .main-nav .level-3 {
  background-color: #205CB7;
  border-bottom: 1px solid white; }
  .top--light .main-nav .level-2:last-child, .top--light .main-nav .level-3:last-child, .scroll .top--light .main-nav .level-2:last-child, .scroll .top--light .main-nav .level-3:last-child {
    border-bottom: none; }
  .top--light .main-nav .level-2:hover, .top--light .main-nav .level-3:hover, .scroll .top--light .main-nav .level-2:hover, .scroll .top--light .main-nav .level-3:hover {
    background: rgba(255, 255, 255, 0.1); }
  .top--light .main-nav .level-2 a, .top--light .main-nav .level-3 a, .scroll .top--light .main-nav .level-2 a, .scroll .top--light .main-nav .level-3 a {
    color: white;
    font-size: 1.1rem;
    padding: 10px 20px; }
    .top--light .main-nav .level-2 a i.fa-chevron-right, .top--light .main-nav .level-3 a i.fa-chevron-right, .scroll .top--light .main-nav .level-2 a i.fa-chevron-right, .scroll .top--light .main-nav .level-3 a i.fa-chevron-right {
      color: white;
      margin-left: 10px; }

@media (max-width: 1024px) {
  .scroll .main-nav {
    top: 103px; } }

/*======================================
  #Hero
======================================*/
.hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 0 0 80px 0;
  max-width: 100%;
  margin-top: 196px; }
  @media (max-width: 1024px) {
    .hero {
      /*padding: 60px 0;*/
      margin-top: 136px; } }
  .hero .hero-background {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1; }
  .hero .hero-inner {
    flex-grow: 1;
    width: 100%;
    position: relative;
    z-index: 10;
    background: #e42323;
    background: -moz-linear-gradient(top, #aa1427 0%, #e42323 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #aa1427), color-stop(100%, #e42323));
    background: -webkit-linear-gradient(top, #aa1427 0%, #e42323 100%);
    background: -o-linear-gradient(top, #aa1427 0%, #e42323 100%);
    background: linear-gradient(to top, #aa1427 0%, #e42323 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#aa1427, endColorstr=#e42323);
    color: #fff;
    max-width: 650px;
    text-transform: uppercase; }
    .hero .hero-inner h1 {
      font-size: 44px;
      margin-bottom: 0;
      margin-top: 0; }
      @media (max-width: 1024px) {
        .hero .hero-inner h1 {
          font-size: 36px; } }
    .hero .hero-inner .hero-text {
      font-weight: 300;
      font-size: 26px;
      line-height: 28px;
      margin: 20px 0 0; }
      @media (max-width: 1024px) {
        .hero .hero-inner .hero-text {
          font-size: 22px;
          line-height: 24px; } }
    .hero .hero-inner .hero-text p:last-of-type {
      margin-bottom: 0; }
    .hero .hero-inner .button-wrap {
      margin-top: 30px; }
      .hero .hero-inner .button-wrap .button + .button {
        margin-left: 10px; }

/*======================================
  #Hero animation
======================================*/
.hero--animation {
  overflow: hidden; }
  .hero--animation .hero-background {
    transform: scale(1.4);
    transition: all 2s ease;
    opacity: 0; }
  .hero--animation .hero-inner {
    opacity: 0;
    transform: scale(0.9) translateZ(0);
    transition: all .7s ease .1s; }
    .hero--animation .hero-inner .button-wrap .button {
      transform: scale(1.2) translateZ(0);
      opacity: 0;
      transition: opacity .5s ease .5s, background .3s ease 0s, color .3s ease 0s, -webkit-transform .5s ease .5s;
      transition: opacity .5s ease .5s, transform .5s ease .5s, background .3s ease 0s, color .3s ease 0s;
      transition: opacity .5s ease .5s, transform .5s ease .5s, background .3s ease 0s, color .3s ease 0s, -webkit-transform .5s ease .5s; }
    .hero--animation .hero-inner .button-wrap .button:nth-child(2) {
      transition: opacity .5s ease .7s, background .3s ease 0s, color .3s ease 0s, -webkit-transform .5s ease .7s;
      transition: opacity .5s ease .7s, transform .5s ease .7s, background .3s ease 0s, color .3s ease 0s;
      transition: opacity .5s ease .7s, transform .5s ease .7s, background .3s ease 0s, color .3s ease 0s, -webkit-transform .5s ease .7s; }
    .hero--animation .hero-inner .button-wrap .button:nth-child(3) {
      transition: opacity .5s ease .9s, background .3s ease 0s, color .3s ease 0s, -webkit-transform .5s ease .9s;
      transition: opacity .5s ease .9s, transform .5s ease .9s, background .3s ease 0s, color .3s ease 0s;
      transition: opacity .5s ease .9s, transform .5s ease .9s, background .3s ease 0s, color .3s ease 0s, -webkit-transform .5s ease .9s; }

.page-loaded .hero--animation .hero-background {
  transform: scale(1);
  opacity: 1; }

.page-loaded .hero--animation .hero-inner {
  opacity: 1;
  transform: scale(1) translateZ(0); }

.page-loaded .hero--animation .button-wrap .button {
  transform: scale(1) translateZ(0);
  opacity: 1; }

/*======================================
  #Scroll icon
======================================*/
.icon-scroll {
  position: absolute;
  bottom: 30px;
  left: 50%;
  color: white;
  z-index: 9;
  width: 30px;
  text-align: center;
  margin-left: -15px;
  animation-duration: 2.2s;
  animation-iteration-count: infinite;
  animation-name: scroll; }
  .icon-scroll .overlay--light {
    color: #fff; }

/*======================================
  #Video
======================================*/
.hero-video {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: 2;
  overflow: hidden; }
  .hero-video .hero-video--player {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    object-position: center;
    font-family: 'object-fit: cover;';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*@include media-breakpoint-down(sm) {
			display: none;
		}*/ }

.video-mute {
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 12;
  color: #111;
  background-color: white;
  width: 40px;
  height: 40px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0.8);
  animation: pulse 1.35s infinite;
  transition: all .3s ease; }
  .video-mute:active {
    transform: scale(0.5); }
  .video-mute .fa-volume-off {
    display: none; }
  .video-mute .sound-on {
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: none;
    animation: none;
    color: white; }
    .video-mute .sound-on:hover {
      background-color: rgba(0, 0, 0, 0.5); }
    .video-mute .sound-on .fa-volume-up {
      display: none; }
    .video-mute .sound-on .fa-volume-off {
      display: block; }

/*
 * Streamline AG - Buttons
 */
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: none; }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.divider-- {
  height: 1px; }
  .divider--1 {
    height: 1px; }
  .divider--2 {
    height: 2px; }
  .divider--3 {
    height: 3px; }
  .divider--4 {
    height: 4px; }
  .divider--5 {
    height: 5px; }
  .divider--6 {
    height: 6px; }
  .divider--7 {
    height: 7px; }
  .divider--8 {
    height: 8px; }
  .divider--9 {
    height: 9px; }
  .divider--10 {
    height: 10px; }

/*------------------------------------
  Clients
------------------------------------*/
.u-clients {
  outline: none; }
  .u-clients__item {
    border-width: 1px 1px 1px 1px;
    border-color: #efefef;
    border-style: solid;
    padding: 2rem 0; }
  .u-clients__image {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
    filter: grey;
    -webkit-filter: grayscale(100%);
    opacity: .3;
    transition: all 0.3s ease; }
    .u-clients__image:hover {
      filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
      -webkit-filter: grayscale(0%);
      opacity: 1; }

.sectionpartnerslider .container-fluid {
  padding: 0; }

/* Slider */
.slick-loading .slick-list {
  background: #fff slick-image-url("../../assets/img/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "FontAwesome";
  src: url("../../assets/fonts/FontAwesome-webfont.eot");
  src: url("../../assets/fonts/FontAwesome-webfont.eot?#iefix") format("embedded-opentype"), url("../../assets/fonts/FontAwesome-webfont.woff") format("woff"), url("../../assets/fonts/FontAwesome-webfont.ttf") format("truetype"), url("../../assets/fonts/FontAwesome-webfont.svg#FontAwesome") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: auto;
  width: auto;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "FontAwesome";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: ""; }
    [dir="rtl"] .slick-prev:before {
      content: ""; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: ""; }
    [dir="rtl"] .slick-next:before {
      content: ""; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 20px;
        height: 20px;
        font-family: "FontAwesome";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/*------------------------------------
Slick
------------------------------------*/
.slick-prev,
.slick-next {
  font-size: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  border: 0;
  background: none;
  z-index: 1;
  outline: 0 !important; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: #AF1328; }
  .slick-prev:before,
  .slick-next:before {
    font-size: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    border: 0;
    background: none;
    z-index: 1;
    outline: 0 !important; }
  .slick-prev:hover:after,
  .slick-next:hover:after {
    color: #AF1328; }

.slick-prev {
  left: 20px; }
  .slick-prev:after {
    content: "\f104";
    font: 40px/1 "FontAwesome";
    outline: 0; }

.slick-next {
  right: 20px;
  text-align: right; }
  .slick-next:after {
    content: "\f105";
    font: 40px/1 "FontAwesome";
    outline: 0; }

.js-slide {
  outline: 0; }

@media (max-width: 767px) {
  .slick-prev {
    left: 0px; }
  .slick-next {
    right: 0px; } }

.slick-dots li.slick-active button:before, .slick-dots li button:before {
  color: #efefef;
  opacity: 1;
  font-size: 16px; }

.slick-dots li.slick-active button:before {
  color: #AF1328; }

.slider-img {
  background-size: cover;
  height: 800px !important; }

.bannerSlider {
  padding: 0;
  margin: 0;
  height: 450px; }
  .bannerSlider .slide__imagebefore {
    content: "";
    position: absolute;
    width: 100%;
    height: 500px;
    background: rgba(33, 33, 33, 0.7); }
  .bannerSlider .slick-slide {
    position: relative; }
    .bannerSlider .slick-slide img {
      width: 100%;
      height: 500px;
      object-fit: cover; }
  .bannerSlider .slide__text {
    position: absolute;
    z-index: 100;
    text-align: center;
    width: 100%;
    top: 50%;
    transform: translateY(-50%); }
  .bannerSlider .slide__text h2 {
    font-size: 48px;
    font-weight: 100;
    color: #AF1328; }

img.slide__image__top-position {
  object-position: center bottom; }

.grid-image {
  width: 50%;
  display: flex;
  flex-flow: row wrap;
  border: 5px solid rgba(255, 255, 255, 0);
  padding: 0; }
  @media (max-width: 1024px) {
    .grid-image {
      order: 1;
      width: 100%;
      min-height: 400px;
      border: 5px solid rgba(255, 255, 255, 0); } }
  .grid-image span {
    padding-left: 5px; }
  .grid-image a:hover {
    overflow: hidden; }
    .grid-image a:hover .grid-image-item {
      transform: scale(1.01); }
  .grid-image .grid-image-item {
    flex-grow: 1;
    width: 100%;
    min-height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }

.grid-image.grid-image--2 .grid-image-item {
  width: 50%; }

.grid-image.grid-image--3 .grid-image-item {
  width: 50%;
  min-height: 50%; }

.grid-image.grid-image--3 .grid-image-item:nth-child(1) {
  width: 100%; }

.grid-image.grid-image--4 .grid-image-item {
  width: 50%;
  min-height: 50%; }

.grid-image.grid-image--5 .grid-image-item:nth-child(1),
.grid-image.grid-image--5 .grid-image-item:nth-child(2) {
  width: 50%;
  min-height: 60%; }

.grid-image.grid-image--5 .grid-image-item {
  width: 33.3333333%;
  min-height: 40%; }

.grid-image.grid-image--6 .grid-image-item {
  width: 33.3333333%;
  min-height: 50%; }

.no-border .grid-image {
  border: none; }

.no-border .grid-image-item {
  border: none; }

.no-border span {
  padding-left: 0px; }

.no-border figure {
  padding: 0px !important; }

.bg--light .grid-image {
  border-color: #fff; }

.bg--light .grid-image-item {
  border-color: #fff; }

.bg--dark .grid-image {
  border-color: #333; }

.bg--dark .grid-image-item {
  border-color: #333; }

.bg--grey .grid-image {
  border-color: #efefef; }

.bg--grey .grid-image-item {
  border-color: #efefef; }

.bg--primary .grid-image {
  border-color: #AF1328; }

.bg--primary .grid-image-item {
  border-color: #AF1328; }

.bg--secondary .grid-image {
  border-color: #205CB7; }

.bg--secondary .grid-image-item {
  border-color: #205CB7; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$=".svg"] {
    width: 100%; } }

.testimonial-item {
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 200px;
  max-width: 600px;
  width: 80%;
  text-align: left;
  box-shadow: none !important; }

.testimonial-item * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9); }

.testimonial-item img {
  max-width: 100%;
  vertical-align: middle;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  margin: 40px 0 0 10px; }

.testimonial-item blockquote {
  display: block;
  border-radius: 8px;
  position: relative;
  background-color: #fafafa;
  padding: 25px 50px 30px 50px;
  font-size: 0.8em;
  font-weight: 500;
  margin: 0;
  line-height: 1.6em;
  font-style: italic; }

.testimonial-item blockquote:before {
  content: "\201C";
  position: absolute;
  font-size: 50px;
  font-style: normal;
  top: 25px;
  left: 20px;
  color: #AF1328; }

.testimonial-item .arrow {
  top: 100%;
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid #fafafa;
  margin: 0;
  position: absolute; }

.testimonial-item .author {
  position: absolute;
  bottom: 45px;
  padding: 0 10px 0 120px;
  margin: 0;
  text-transform: uppercase;
  -webkit-transform: translateY(50%);
  transform: translateY(50%); }

.testimonial-item .author h5 {
  opacity: 0.8;
  margin: 0;
  font-weight: 800; }

.testimonial-item .author span {
  font-weight: 400;
  text-transform: none; }

.bg--grey .testimonial-item blockquote {
  background-color: #fff; }

.bg--grey .testimonial-item .arrow {
  border-top: 25px solid #fff; }

.bg--grey .testimonial-item .author {
  color: #fff; }

.bg--grey .testimonial-item .slick-arrow {
  color: #fff; }

.bg--grey .slick-prev,
.bg--grey .slick-next {
  color: #333; }
  .bg--grey .slick-prev:hover, .bg--grey .slick-prev:focus,
  .bg--grey .slick-next:hover,
  .bg--grey .slick-next:focus {
    color: #AF1328; }
  .bg--grey .slick-prev:hover:after,
  .bg--grey .slick-next:hover:after {
    color: #AF1328; }

.bg--grey li button:before {
  color: #333;
  opacity: 0.25; }

.bg--grey li.slick-active button:before {
  color: #AF1328;
  opacity: 0.75; }

.bg--dark .testimonial-item blockquote {
  background-color: #fff; }

.bg--dark .testimonial-item .arrow {
  border-top: 25px solid #fff; }

.bg--dark .testimonial-item .author {
  color: #fff; }

.bg--dark .testimonial-item .slick-arrow {
  color: #fff; }

.bg--dark li button:before {
  color: #fff;
  opacity: 0.25; }

.bg--dark li.slick-active button:before {
  color: #AF1328;
  opacity: 0.75; }

.bg--light .testimonial-item blockquote {
  background-color: #333; }

.bg--light .testimonial-item .arrow {
  border-top: 25px solid #333; }

.bg--light .testimonial-item .author {
  color: #333; }

.bg--light .testimonial-item .slick-arrow {
  color: #333; }

.bg--light li button:before {
  color: #333;
  opacity: 0.25; }

.bg--light li.slick-active button:before {
  color: #AF1328;
  opacity: 0.75; }

.bg--primary .testimonial-item blockquote {
  background-color: #fff; }

.bg--primary .testimonial-item .arrow {
  border-top: 25px solid #fff; }

.bg--primary .testimonial-item .author {
  color: #fff; }

.bg--primary .slick-prev,
.bg--primary .slick-next {
  color: #fff; }
  .bg--primary .slick-prev:hover, .bg--primary .slick-prev:focus,
  .bg--primary .slick-next:hover,
  .bg--primary .slick-next:focus {
    color: #333; }
  .bg--primary .slick-prev:hover:after,
  .bg--primary .slick-next:hover:after {
    color: #333; }

.bg--primary li button:before {
  color: #fff;
  opacity: 0.25; }

.bg--primary li.slick-active button:before {
  color: #fff;
  opacity: 0.75; }

.bg--secondary .testimonial-item blockquote {
  background-color: #fff; }

.bg--secondary .testimonial-item .arrow {
  border-top: 25px solid #fff; }

.bg--secondary .testimonial-item .author {
  color: #fff; }

.bg--secondary .testimonial-item .slick-arrow {
  color: #fff; }

.bg--secondary li button:before {
  color: #fff;
  opacity: 0.25; }

.bg--secondary li.slick-active button:before {
  color: #fff;
  opacity: 0.75; }

.slider-testimonialsSlider {
  /*max-width: 700px;*/ }

.sectionimageslider .js-slide, .sectionimageslider .grid-image-item, .sectionimageslider .slick-track, .sectionimageslider .slider {
  flex: 1;
  position: relative;
  min-height: 45vh; }

.sectionimageslider .container-fluid {
  padding-left: 0;
  padding-right: 0; }

.sectionimageslider .slick-dots {
  bottom: 5px; }

.sectionimageslider .slick-dotted.slick-slider {
  margin-bottom: 0px; }

.sectionimageslider .grid-image-item {
  background-size: cover;
  background-position: center; }

.slider-parent {
  min-width: 0;
  min-height: 0; }

@media (min-width: 1025px) and (max-width: 1199px) {
  .news .card-columns {
    column-count: 2; } }

@media (min-width: 768px) and (max-width: 1024px) {
  .news .card-columns {
    column-count: 2; } }

.news .card {
  border: 0 !important;
  border-radius: 0;
  box-shadow: 0 22px 43px -20px rgba(0, 0, 0, 0.15); }
  .news .card a:hover .news-image {
    /*transform: scale(1.1);*/ }

.news-wrapper {
  background-color: white;
  padding-left: 1rem;
  padding-right: 1rem; }
  .news-wrapper .Redlink {
    font-weight: 700; }

.news-image {
  height: 280px !important;
  min-height: 280px;
  background-size: cover; }

.news-date {
  font-size: 1rem;
  color: white;
  background-color: #205CB7;
  width: 60px; }
  .news-date span {
    line-height: 1.1rem;
    letter-spacing: .4px; }

.news-text {
  color: grey !important; }

.news-title {
  color: #AF1328; }

.news-footer {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
  background-color: transparent; }

.news-tag {
  font-size: 1.3rem;
  background-color: #205CB7;
  line-height: 3rem;
  color: white;
  padding: 3px 8px;
  border: 1px solid #205CB7;
  letter-spacing: .4px; }
  .news-tag:hover {
    background-color: white;
    color: #205CB7;
    border: 1px solid #205CB7; }

.news-more {
  font-size: 1.3rem;
  transition: all .3s ease;
  text-decoration: none;
  transition: all .3s ease;
  background-color: #AF1328;
  line-height: 3rem;
  color: white;
  padding: 3px 8px;
  color: #fff;
  border-radius: 0px;
  letter-spacing: .4px; }
  .news-more:hover, .news-more:focus {
    background-color: transparent;
    border: 1px solid #AF1328;
    color: #AF1328; }

.news-image-overflow {
  overflow: hidden; }

.news-footer-line {
  border-top: 1px solid #555 !important; }

.news-category-item {
  border: 1px solid #205CB7;
  background-color: white;
  color: #205CB7; }
  .news-category-item:hover {
    background-color: #205CB7; }
    .news-category-item:hover a {
      color: white !important; }

.u-pagination-v1-4--active {
  color: #AF1328; }

.paging {
  padding: 30px 10px 0 10px; }
  .paging li .page-button {
    padding: 0.85714rem 1.35714rem !important;
    font-size: 1.3rem;
    line-height: 3rem;
    border: 1px solid #205CB7;
    letter-spacing: .4px;
    transition: all .3s ease;
    background-color: white;
    color: #205CB7; }
    .paging li .page-button:hover {
      background-color: #205CB7;
      color: white; }
  .paging li .page-button.active {
    background-color: #205CB7;
    color: white;
    pointer-events: none;
    cursor: default; }

#footer {
  margin-top: 40px;
  font-size: 1.1rem;
  background-color: #AF1328;
  color: white; }
  #footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    #footer ul li:hover {
      font-weight: 700; }
  #footer a {
    color: white; }
  #footer h4 {
    font-weight: 700; }
  #footer .footer {
    padding: 10px 0; }
  #footer .footer-bottom {
    padding-top: 15px;
    background-color: #95192A; }
    #footer .footer-bottom label {
      margin-bottom: 0; }
  #footer .footer-text--right {
    text-align: right; }
  #footer .footer-text--center {
    text-align: center; }
  #footer .footer-text--left {
    text-align: left; }
  @media (max-width: 1024px) {
    #footer h5 {
      margin-top: 1rem; }
    #footer .mobile-text--left {
      text-align: left !important; }
      #footer .mobile-text--left .footer-text--right {
        text-align: left !important; }
      #footer .mobile-text--left .footer-text--center {
        text-align: left !important; }
    #footer .mobile-text--center {
      text-align: center !important; }
      #footer .mobile-text--center .footer-text--left {
        text-align: center !important; }
      #footer .mobile-text--center .footer-text--right {
        text-align: center !important; }
    #footer .mobile-text--right {
      text-align: right !important; }
      #footer .mobile-text--right .footer-text--center {
        text-align: right !important; }
      #footer .mobile-text--right .footer-text--left {
        text-align: right !important; } }
  #footer.bg--primary a {
    color: #fff !important; }
  #footer .dark {
    background-color: rgba(0, 0, 0, 0.15); }

/*
 * Streamline AG - Cards
 */
.card-item {
  min-height: 20vh;
  transition: all .3s ease; }
  @media (max-width: 767px) {
    .card-item {
      min-height: 5vh; } }
  .card-item .card-img {
    position: relative;
    background-size: cover;
    background-position: 50%;
    width: auto; }
    .card-item .card-img:hover .card-title {
      background-color: #205CB7; }
  .card-item .card-title {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: red;
    color: white;
    padding: 0 5px;
    text-align: center;
    margin-bottom: 0; }
    .card-item .card-title h2 {
      font-size: 1.3rem;
      text-transform: uppercase;
      margin: 0;
      line-height: 2.2rem; }

/*------------------------------------
  Portfolio
------------------------------------*/
.u-portfolio-controls__item {
  display: inline-block;
  cursor: pointer; }

@media (min-width: 1025px) {
  .u-portfolio-controls__item:not(:last-child)::after {
    color: #333;
    margin: 0 7px 0 12px; } }

.u-portfolio-controls a {
  color: #555; }
  .u-portfolio-controls a.active .card-title {
    background-color: #205CB7; }
  .u-portfolio-controls a:hover {
    color: #205CB7; }

.u-portfolio img {
  transform: scale(1);
  transition: .3s ease-in-out;
  max-height: 90px; }
  .u-portfolio img:hover {
    opacity: 1;
    animation: flash 1.5s; }

@-webkit-keyframes flash {
  0% {
    opacity: .4; }
  100% {
    opacity: 1; } }

@keyframes flash {
  0% {
    opacity: .4; }
  100% {
    opacity: 1; } }

.u-portfolio__item {
  overflow: hidden;
  z-index: 2; }

.u-portfolio__image {
  display: block;
  width: 100%;
  height: auto;
  transition: all .2s ease; }

.u-portfolio__item:hover .u-portfolio__image {
  transform: translate3d(0, -60px, 0); }

.u-portfolio__zoom {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-indent: -10000px; }

.u-portfolio figure a {
  display: block; }

/*
 * Streamline AG - Forms
 */
small {
  padding-top: 5px;
  font-size: .9rem;
  display: block;
  font-style: italic; }

.form-group span {
  font-size: 1.1rem;
  font-weight: 700; }

.form-group input {
  padding: 0 10px; }

.alert {
  margin-top: 10px;
  display: block;
  border-radius: 0; }

.invalid-text {
  color: red; }

.form-wrapper {
  border-left: 1px solid #AF1328;
  border-right: 1px solid #AF1328;
  border-bottom: 1px solid #AF1328; }
  .form-wrapper .inner-form {
    padding: 10px; }
  .form-wrapper .btn {
    min-width: 150px; }

.form-group span {
  display: block;
  margin-bottom: 6px;
  color: #666;
  font-size: 1rem; }

.form-group input {
  font-size: 1rem;
  padding: 9.5px 8px;
  border: 1px solid #b3b3b3;
  background-color: #fff;
  max-width: 100%; }

.form-check label {
  margin-left: 1.25rem; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
  padding-left: 1.25rem; }

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1; }

/*======================================
  #Service Pods
======================================*/
.grid-item--service .gis-image {
  overflow: hidden;
  color: #AF1328; }

.grid-item--service .gis-image i {
  font-size: 70px;
  margin-bottom: 5px;
  line-height: 1; }

.grid-item--service .gis-text {
  margin-top: 10px; }

.grid-item--service.grid-item--boxed {
  padding: 0; }

.grid-item--service.grid-item--boxed .gis-text {
  margin: 0;
  padding: 20px 30px 30px; }

.grid-item--service.grid-item--boxed .gis-image i {
  margin-top: 30px; }

.grid-item--service.grid-item--service-compact {
  position: relative;
  padding-left: 60px; }

.grid-item--service.grid-item--service-compact.text-center {
  padding: 0;
  display: flex;
  justify-content: center; }

.grid-item--service.grid-item--service-compact.text-center article {
  position: relative;
  text-align: left;
  padding-left: 60px; }

.grid-item--service.grid-item--service-compact.text-center article p {
  margin-bottom: 0; }

.grid-item--service.grid-item--service-compact .gis-text {
  margin-top: 0;
  font-size: 14px;
  line-height: 20px; }

.grid-item--service.grid-item--service-compact .gis-text h5 {
  font-size: 16px;
  margin-bottom: 0; }

.grid-item--service.grid-item--service-compact .gis-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  text-align: center; }

.grid-item--service.grid-item--service-compact .gis-image i {
  font-size: 30px;
  margin: 0; }

.grid-item--service.grid-item--service-compact.grid-item--boxed {
  padding-left: 90px; }

.grid-item--service.grid-item--service-compact.grid-item--boxed .gis-image {
  top: 30px;
  left: 30px; }

/*======================================
  #Grid
======================================*/
.grid {
  position: relative; }

.grid-item img {
  height: auto !important; }

.row--flat .grid-item {
  margin-bottom: 0; }

.grid-item--boxed {
  padding: 30px;
  background: white;
  box-shadow: 0 22px 43px -20px rgba(0, 0, 0, 0.15); }

.block--open-padding .grid-item--boxed,
.white-bg .grid-item--boxed {
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: none; }

/*======================================
  #Categories
======================================*/
.categories {
  width: 100%;
  transition: transform .35s ease; }
  .categories ul {
    flex-flow: row wrap;
    list-style: none;
    margin: 0;
    padding: 0; }
    .categories ul li {
      display: block;
      position: relative;
      transition: all .2s ease;
      width: 100%; }
      .categories ul li:hover {
        background: rgba(255, 255, 255, 0.03); }
      .categories ul li a {
        color: #333;
        display: block;
        font-size: 1.1rem;
        transition: all .2s ease;
        position: relative;
        white-space: normal;
        line-height: 95px;
        padding: 0 20px;
        font-weight: 400;
        border-top: 1px solid rgba(255, 255, 255, 0.08);
        line-height: 1 !important;
        padding: 11px 10px;
        font-weight: 300; }
      .categories ul li ul {
        position: absolute;
        top: 100%;
        left: 0;
        text-align: left;
        background-color: white;
        list-style: none;
        margin: 0;
        padding: 0;
        min-width: 250px;
        opacity: 0;
        visibility: hidden;
        transition: all .125s ease; }
        .categories ul li ul li {
          display: block;
          transition: all .2s ease;
          position: relative; }
          .categories ul li ul li:hover {
            background: rgba(255, 255, 255, 0.03); }
          .categories ul li ul li a {
            display: block;
            line-height: 1 !important;
            font-size: 1.1rem;
            color: #333;
            padding: 10px 20px;
            font-weight: 300; }
            .categories ul li ul li a:after {
              content: "";
              position: absolute;
              right: 20px;
              bottom: 0;
              left: 20px;
              height: 1px;
              background: rgba(0, 0, 0, 0.1); }
            .categories ul li ul li a i {
              font-size: 70%;
              color: rgba(255, 255, 255, 0.5);
              position: absolute;
              right: 15px;
              top: 50%;
              transform: translateY(-50%); }
        .categories ul li ul .go-right {
          left: auto;
          right: 0; }

.categories .level-1 {
  text-align: left; }
  .categories .level-1:hover > a {
    background: #5486d2;
    background: -moz-linear-gradient(top, #205cb7 0%, #5486d2 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #205cb7), color-stop(100%, #5486d2));
    background: -webkit-linear-gradient(top, #205cb7 0%, #5486d2 100%);
    background: -o-linear-gradient(top, #205cb7 0%, #5486d2 100%);
    background: linear-gradient(to top, #205cb7 0%, #5486d2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#205cb7, endColorstr=#5486d2);
    color: white; }
  .categories .level-1 > a {
    color: #333;
    letter-spacing: 2px;
    font-weight: 700; }
    .categories .level-1 > a i {
      position: absolute;
      right: 10px; }
    .categories .level-1 > a i.fa-chevron-down {
      color: white; }

.categories .level-2:hover {
  background: rgba(0, 0, 0, 0.25); }
  .categories .level-2:hover a {
    font-weight: 700;
    color: white;
    letter-spacing: normal; }

.categories > ul > li:hover {
  background-color: inherit; }

.categories > ul > li:hover > a {
  color: #333; }

.categories > ul > li ul {
  position: static;
  visibility: visible;
  opacity: 1;
  display: none;
  transition: none; }

.categories > ul > li ul li a {
  border-top: 1px solid rgba(255, 255, 255, 0.9);
  padding: 15px 25px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.04); }

.categories > ul > li ul li a > i {
  display: none; }

.categories > ul > li ul li:hover {
  background: inherit; }

.categories > ul > li ul li ul {
  background-color: rgba(255, 255, 255, 0.08); }

.categories > ul > li ul li ul li:hover {
  background: none; }

.categories > ul > li ul li ul li:hover a {
  color: white; }

.categories > ul > li ul ul {
  top: 0;
  left: 100%; }

.categories > ul > li ul ul.go-right {
  left: auto;
  right: 100%; }

.categories > ul > li:hover > ul,
.categories > ul > li.touch-open > ul {
  opacity: 1;
  visibility: visible; }

.categories > ul > li:hover > ul li:hover > ul,
.categories > ul > li.touch-open > ul li:hover > ul,
.categories > ul > li:hover > ul li.touch-open > ul,
.categories > ul > li.touch-open > ul li.touch-open > ul {
  opacity: 1;
  visibility: visible; }

.categories > ul > li > a.open,
.categories > ul > li > a.active {
  background: #5486d2;
  background: -moz-linear-gradient(top, #205cb7 0%, #5486d2 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #205cb7), color-stop(100%, #5486d2));
  background: -webkit-linear-gradient(top, #205cb7 0%, #5486d2 100%);
  background: -o-linear-gradient(top, #205cb7 0%, #5486d2 100%);
  background: linear-gradient(to top, #205cb7 0%, #5486d2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#205cb7, endColorstr=#5486d2);
  color: white;
  font-weight: 700;
  font-size: 1.1rem; }

.categories > ul > li > ul > .active > a {
  font-weight: 700; }

.top--light .categories > ul > li > ul > li > ul > .active > a {
  font-weight: 700; }

.top--light .categories > ul > li a {
  color: #333; }

.top--light .categories > ul > li a i.fa-chevron-down,
.top--light .categories > ul > li a .fa-chevron-right {
  color: rgba(0, 0, 0, 0.5); }

.top--light .categories > ul > li:hover,
.scroll .top--light .categories > ul > li:hover {
  background: rgba(0, 0, 0, 0.02); }

.top--light .categories > ul > li ul {
  background: #205CB7;
  box-shadow: 0 8px 20px -6px rgba(0, 0, 0, 0.15); }

.top--light .categories > ul > li ul li a {
  color: #fff; }

.top--light .categories > ul > li ul li:hover {
  background: rgba(0, 0, 0, 0.02);
  color: #333; }

.top--scroll-light .categories > ul > li > a {
  color: white; }

.top--scroll-light .categories > ul > li > a i.fa-chevron-down {
  color: rgba(255, 255, 255, 0.5); }

.top--scroll-light .categories > ul > li > a {
  color: inherit; }

.top--scroll-dark .categories > ul > li > a {
  color: #111; }

.top--scroll-dark .categories > ul > li > a i.fa-chevron-down {
  color: rgba(0, 0, 0, 0.5); }

.top--scroll-dark .categories > ul > li > a {
  color: inherit; }

.top--dark .categories > ul > li > a {
  color: white; }

.scroll .top--light .categories > ul > li > a {
  color: #111; }

.scroll .top--light .categories > ul > li > a i.fa-chevron-down {
  color: rgba(0, 0, 0, 0.5); }

.scroll .top--dark .categories > ul > li > a {
  color: white; }

.scroll .top--dark .categories > ul > li > a i.fa-chevron-down {
  color: rgba(255, 255, 255, 0.5); }

.top--light .categories {
  background: white; }

.top--light .categories li.mobile-contact:nth-child(2) {
  border-left-color: rgba(0, 0, 0, 0.08); }

.top--light .categories .expand {
  background-color: rgba(0, 0, 0, 0.04); }

.top--light .categories > ul > li > a {
  border-top: 1px solid rgba(0, 0, 0, 0.08); }

.top--light .categories > ul > li.lang ul {
  border-bottom-color: rgba(0, 0, 0, 0.08); }

.top--light .categories > ul > li.lang ul li {
  border-left-color: rgba(0, 0, 0, 0.08); }

.top--light .categories > ul > li.lang ul li a {
  background-color: white !important; }

.top--light .categories > ul > li:hover {
  background: inherit; }

.top--light .categories > ul > li:hover > a {
  color: #111; }

.top--light .categories > ul > li ul {
  box-shadow: none; }

.top--light .categories > ul > li ul li a {
  background-color: rgba(0, 0, 0, 0.04);
  border-top: 1px solid rgba(0, 0, 0, 0.08); }

.top--light .categories > ul > li ul li ul {
  background-color: rgba(0, 0, 0, 0.04); }

.top--light .categories > ul > li ul li:hover {
  background: inherit; }

.top--light .categories > ul > li ul li:hover a {
  color: #111; }

.top--light .mobile-actions ul li a {
  color: #111; }

.top--scroll-dark .mobile-actions ul li a {
  color: #111; }

.top--scroll-light .mobile-actions ul li a {
  color: white; }

.scroll .top--light .mobile-actions ul li a {
  color: #111; }

.scroll .top--dark .mobile-actions ul li a {
  color: white; }

.top--light .toggle-mobile-nav span {
  background-color: #333; }

.top--scroll-dark .toggle-mobile-nav span {
  background-color: #333; }

.top--scroll-light .toggle-mobile-nav span {
  background-color: #fff; }

.scroll .top--light .toggle-mobile-nav span {
  background-color: #333; }

.scroll .top--dark .toggle-mobile-nav span {
  background-color: #fff; }

.mobile-nav-visible .toggle-mobile-nav span:nth-child(1) {
  transform: rotate(45deg) translateY(-3px);
  transform-origin: top left; }

.mobile-nav-visible .toggle-mobile-nav span:nth-child(2) {
  opacity: 0;
  transform: translateX(-20px); }

.mobile-nav-visible .toggle-mobile-nav span:nth-child(3) {
  transform: rotate(-45deg) translateY(3px);
  transform-origin: bottom left; }

.top--light .categories .level-2, .top--light .categories .level-3, .scroll .top--light .categories .level-2, .scroll .top--light .categories .level-3 {
  background-color: #205CB7;
  border-bottom: 1px solid white; }
  .top--light .categories .level-2:last-child, .top--light .categories .level-3:last-child, .scroll .top--light .categories .level-2:last-child, .scroll .top--light .categories .level-3:last-child {
    border-bottom: none; }
  .top--light .categories .level-2:hover, .top--light .categories .level-3:hover, .scroll .top--light .categories .level-2:hover, .scroll .top--light .categories .level-3:hover {
    background: rgba(255, 255, 255, 0.1); }
  .top--light .categories .level-2 a, .top--light .categories .level-3 a, .scroll .top--light .categories .level-2 a, .scroll .top--light .categories .level-3 a {
    color: white;
    font-size: 1.1rem;
    padding: 10px 20px; }
    .top--light .categories .level-2 a i.fa-chevron-right, .top--light .categories .level-3 a i.fa-chevron-right, .scroll .top--light .categories .level-2 a i.fa-chevron-right, .scroll .top--light .categories .level-3 a i.fa-chevron-right {
      color: white;
      margin-left: 10px; }

/*======================================
  #Product
======================================*/
.products .card {
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.125) !important; }
  .products .card .card-text > a {
    font-size: 1rem; }
  .products .card h5 > a, .products .card p > a {
    color: #333; }
  .products .card:hover h5 > a, .products .card:hover p > a, .products .card:hover img, .products .card:focus h5 > a, .products .card:focus p > a, .products .card:focus img {
    color: #AF1328;
    outline: 0; }
  .products .card .card-body {
    padding: 0.75rem; }
  .products .card .card-footer {
    border: none;
    font-size: 1rem;
    padding: 0.75rem; }
  .products .card:hover {
    border: 1px solid #AF1328 !important; }
  .products .card .fa {
    font-size: 1.3rem; }

.products .badge a {
  color: white;
  text-decoration: underline; }
  .products .badge a:hover {
    text-decoration: none; }

.products .badge-primary {
  color: #fff;
  background-color: #205CB7;
  border-radius: 0;
  font-size: 100%;
  font-weight: 400; }

.vertical-center {
  text-align: center; }

/*======================================
  #Modal
======================================*/
.modalBackground {
  background-color: rgba(255, 255, 255, 0.7); }
