﻿//
// Linear Gradient
//

// e.g.@include linear-gradient($lg_blue_From, $lg_blue_To, left/bottom/etc.);

@mixin linear-gradient($from, $to, $direction) {
	background: $to;
	background: -moz-linear-gradient($direction, $from 0%, $to 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$from), color-stop(100%,$to));
	background: -webkit-linear-gradient($direction, $from 0%,$to 100%);
	background: -o-linear-gradient($direction, $from 0%,$to 100%);
	background: linear-gradient(to $direction, $from 0%,$to 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$from}, endColorstr=#{$to})
}