﻿/*------------------------------------
  Clients
------------------------------------*/
.u-clients {
  outline: none;

	&__item {
		border: {
			width: 1px 1px 1px 1px;
			color: $g-grey;
			style: solid;
		}

		padding: 2rem 0;
	}

	&__image {
		width: 250px;
		margin-left: auto;
		margin-right: auto;
		filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
		filter: grey;
		-webkit-filter: grayscale(100%);
		opacity: .3;
		transition: all 0.3s ease;

		&:hover {
      filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
			-webkit-filter: grayscale(0%);
			opacity: 1;
		}
	}
}

.sectionpartnerslider {
  .container-fluid {
    padding: 0;
	}
}
