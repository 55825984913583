﻿/*======================================
  #Main navigation
======================================*/
.main-nav {
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: block;
      position: relative;
      transition: all .2s ease;

      &:hover {
        background: rgba(255, 255, 255, 0.03);
      }

      a {
        color: #333;
        display: block;
        font-size: 1.5rem;
        transition: all .2s ease;
        position: relative;
        white-space: nowrap;
        line-height: $g-headerHeight + ($g-headerPadding * 2);
        padding: 0 20px;
        font-weight: 400;

        i.fa-chevron-down {
          font-size: 70%;
          color: rgba(255, 255, 255, 0.5);
          margin-left: 3px;
        }
      }
      //2.Level submenu-dropdown
      ul {
        position: absolute;
        top: 100%;
        left: 0;
        text-align: left;
        background-color: $g-dark;
        list-style: none;
        margin: 0;
        padding: 0;
        min-width: 250px;
        opacity: 0;
        visibility: hidden;
        transition: all .125s ease;

        li {
          display: block;
          transition: all .2s ease;
          position: relative;

          &:hover {
            background: rgba(255, 255, 255, 0.03);
          }

          a {
            display: block;
            line-height: 1 !important;
            font-size: 1.2rem;
            color: white;
            padding: 10px 20px;
            font-weight: 300;

            i {
              font-size: 70%;
              color: rgba(255, 255, 255, 0.5);
              position: absolute;
              right: 15px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }

        .go-right {
          left: auto;
          right: 0;
        }
      }
    }
  }

  .expand {
    display: none;

    @include media-breakpoint-down(md) {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 50px;
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.08);

      i {
        transition: transform 0.2s linear;
      }

      &.open i {
        transform: rotate(45deg);
        color: $g-primary;
      }
    }
  }

  @include media-breakpoint-down(md) {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    top: $g-headerHeight + ($g-headerPadding * 2) + 30px;
    background-color: $g-dark;
    overflow-y: auto;
    transform: translateX(100%);
    transition: transform .35s ease;

    ul {
      flex-flow: row wrap;

      li {
        width: 100%;

        a {
          border-top: 1px solid rgba(255, 255, 255, 0.08);
          line-height: 1 !important;
          padding: 15px 25px;
          font-weight: 300;

          i.fa-chevron-down {
            display: none;
          }
        }
      }
    }
  }
}

//Transparent
.top--light.transparent--bg {
  .main-nav {
    .level-1 {
      &:hover {
        background: rgba(255, 255, 255, 0.03);
      }

      a {
        color: $g-light;

        i.fa-chevron-down {
          color: $g-light;
        }
      }
    }

    .level-2, .level-3 {
      a {
        color: $g-dark;

        i.fa-chevron-down {
          color: $g-dark;
        }
      }
    }
  }
}


.mobile-nav-visible .top--light {
  .main-nav {
    .level-1 {
      &:hover {
        background: rgba(255, 255, 255, 0.03);
      }

      a {
        color: $g-dark;

        i.fa-chevron-down {
          color: $g-dark;
        }
      }

      .level-2, .level-3 {
        a {
          color: $g-light;
        }
      }
    }
  }
}


.scroll .top--light {
  .main-nav {
    .level-1 {
      a {
        color: $g-dark;
        line-height: $g-scrollHeaderHeight;

        i.fa-chevron-down {
          color: $g-dark;
        }
      }
    }
  }
}


.top--dark.transparent--bg {
  .main-nav {
    .level-1 {
      &:hover {
        background: rgba(255, 255, 255, 0.03);
      }

      a {
        color: $g-dark;

        i.fa-chevron-down {
          color: $g-dark;
        }
      }
    }

    .level-2, .level-3 {
      a {
        color: $g-light;

        i.fa-chevron-down {
          color: $g-light;
        }
      }
    }
  }
}

.scroll .top--dark {
  .main-nav {
    .level-1 {
      &:hover {
        background: rgba(255, 255, 255, 0.03);
      }

      a {
        color: $g-light;

        i.fa-chevron-down {
          color: $g-light;
        }
      }
    }
  }
}


.mobile-nav-visible .top--dark {
  .main-nav {
    .level-1 {
      &:hover {
        background: rgba(255, 255, 255, 0.03);
      }

      a {
        color: $g-light;

        i.fa-chevron-down {
          color: $g-light;
        }
      }
    }
  }
}



@include media-breakpoint-down(md) {

  .main-nav li.mobile-contact {
    display: block;
    width: 50%;
    flex-grow: 1;
  }

  .main-nav li.mobile-contact a {
    text-align: center;
    padding: 15px 0;
    font-weight: normal;
  }

  .main-nav li.mobile-contact a i {
    margin-right: 3px;
    font-size: 100%;
  }

  .main-nav li.mobile-contact:nth-child(2) {
    border-left: 1px solid rgba(255, 255, 255, 0.08);
  }

  .main-nav > ul > li:hover {
    background-color: inherit;
  }

  .main-nav > ul > li:hover > a {
    color: white;
  }

  .main-nav > ul > li ul {
    position: static;
    visibility: visible;
    opacity: 1;
    display: none;
    transition: none;
  }

  .main-nav > ul > li ul li a {
    background-color: rgba(255, 255, 255, 0.08);
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    padding: 15px 25px;
    position: relative;
  }

  .main-nav > ul > li ul li a > i {
    display: none;
  }

  .main-nav > ul > li ul li:hover {
    background: inherit;
  }

  .main-nav > ul > li ul li:hover a {
    color: white;
  }

  .main-nav > ul > li ul li ul {
    background-color: rgba(255, 255, 255, 0.08);
  }

  .main-nav > ul > li ul li ul li:hover {
    background: none;
  }

  .main-nav > ul > li ul li ul li:hover a {
    color: white;
  }
}

@include media-breakpoint-down(md) {
  .mobile-nav-visible {
    overflow: hidden;
  }

  .mobile-nav-visible .main-nav {
    transform: translateX(0);
  }
}
/*.main-nav > ul > li:hover,
	.main-nav > ul > li.touch-open {
		background: lighten($g-light, 7.5%);
	}*/
/*.main-nav > ul > li.btn {
		padding: 0 0 0 10px;
		display: flex;
		align-items: center;
	}

	.main-nav > ul > li.btn > a {
		line-height: 1 !important;
		padding: 10px 20px;
	}

	.main-nav > ul > li.btn:hover {
		background: transparent !important;
	}

	@media only screen and (max-width: 992px) {
		.main-nav > ul > li.btn {
			padding: 20px 20px 0;
			display: block;
			text-align: center;
		}

		.main-nav > ul > li.btn > a {
			display: inline-block;
		}
	}

	.main-nav > ul > li.lang > a {
		padding: 0;
	}

	.main-nav > ul > li.lang > a span {
		display: none;
	}

	.main-nav > ul > li.lang > a img {
		max-width: 18px;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.main-nav > ul > li.lang ul li a img {
		max-width: 18px;
		position: relative;
		bottom: -1px;
		margin-right: 4px;
	}

	@media only screen and (max-width: 992px) {
		.main-nav > ul > li.lang > a {
			display: none;
		}

		.main-nav > ul > li.lang ul {
			display: block;
			display: flex;
			flex-flow: row wrap;
			border-bottom: 1px solid rgba(255, 255, 255, 0.08);
		}

		.main-nav > ul > li.lang ul li {
			display: block;
			width: 50%;
			flex-grow: 1;
			border-left: 1px solid rgba(255, 255, 255, 0.08);
		}

		.main-nav > ul > li.lang ul li a {
			text-align: center !important;
			background-color: #111 !important;
			padding: 15px 0;
		}
	}*/
//3.Level submenu-dropdown
.main-nav > ul > li ul ul {
  top: 0;
  left: 100%;
}

.main-nav > ul > li ul ul.go-right {
  left: auto;
  right: 100%;
}



.main-nav > ul > li:hover > ul,
.main-nav > ul > li.touch-open > ul {
  opacity: 1;
  visibility: visible;
}

.main-nav > ul > li:hover > ul li:hover > ul,
.main-nav > ul > li.touch-open > ul li:hover > ul,
.main-nav > ul > li:hover > ul li.touch-open > ul,
.main-nav > ul > li.touch-open > ul li.touch-open > ul {
  opacity: 1;
  visibility: visible;
}


.top--light .main-nav > ul > .active > a {
  color: $g-primary;
}

.top--light .main-nav > ul > li > ul > .active > a {
  font-weight: 700;
}

.top--light .main-nav > ul > li > ul > li > ul > .active > a {
  font-weight: 700;
}

.top--light .main-nav > ul > li a {
  color: $g-dark;
}

.top--light .main-nav > ul > li a i.fa-chevron-down,
.top--light .main-nav > ul > li a .fa-chevron-right {
  color: rgba(0, 0, 0, 0.5);
}

.top--light .main-nav > ul > li:hover,
.scroll .top--light .main-nav > ul > li:hover {
  background: rgba(0, 0, 0, 0.02);
}

.top--light .main-nav > ul > li ul {
  background: $g-secondary;
  box-shadow: 0 8px 20px -6px rgba(0, 0, 0, 0.15);
}

.top--light .main-nav > ul > li ul li a {
  color: $g-light;
}

.top--light .main-nav > ul > li ul li:hover {
  background: rgba(0, 0, 0, 0.02);
  color: $g-dark;
}

.top--scroll-light .main-nav > ul > li > a {
  color: white;
}

.top--scroll-light .main-nav > ul > li > a i.fa-chevron-down {
  color: rgba(255, 255, 255, 0.5);
}

@include media-breakpoint-down(md) {
  .top--scroll-light .main-nav > ul > li > a {
    color: inherit;
  }
}

.top--scroll-dark .main-nav > ul > li > a {
  color: #111;
}

.top--scroll-dark .main-nav > ul > li > a i.fa-chevron-down {
  color: rgba(0, 0, 0, 0.5);
}

@include media-breakpoint-down(md) {
  .top--scroll-dark .main-nav > ul > li > a {
    color: inherit;
  }
}

@include media-breakpoint-down(md) {
  .top--dark .main-nav > ul > li > a {
    color: white;
  }
}

.scroll .top--light .main-nav > ul > li > a {
  color: #111;
}

.scroll .top--light .main-nav > ul > li > a i.fa-chevron-down {
  color: rgba(0, 0, 0, 0.5);
}

.scroll .top--dark .main-nav > ul > li > a {
  color: white;
}

.scroll .top--dark .main-nav > ul > li > a i.fa-chevron-down {
  color: rgba(255, 255, 255, 0.5);
}

@include media-breakpoint-down(md) {
  .top--light .main-nav {
    background: white;
  }

  .top--light .main-nav li.mobile-contact:nth-child(2) {
    border-left-color: rgba(0, 0, 0, 0.08);
  }

  .top--light .main-nav .expand {
    background-color: rgba(0, 0, 0, 0.04);
  }

  .top--light .main-nav > ul > li > a {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }

  .top--light .main-nav > ul > li.lang ul {
    border-bottom-color: rgba(0, 0, 0, 0.08);
  }

  .top--light .main-nav > ul > li.lang ul li {
    border-left-color: rgba(0, 0, 0, 0.08);
  }

  .top--light .main-nav > ul > li.lang ul li a {
    background-color: white !important;
  }

  .top--light .main-nav > ul > li:hover {
    background: inherit;
  }

  .top--light .main-nav > ul > li:hover > a {
    color: #111;
  }

  .top--light .main-nav > ul > li ul {
    box-shadow: none;
  }

  .top--light .main-nav > ul > li ul li a {
    background-color: rgba(0, 0, 0, 0.04);
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }

  .top--light .main-nav > ul > li ul li ul {
    background-color: rgba(0, 0, 0, 0.04);
  }

  .top--light .main-nav > ul > li ul li:hover {
    background: inherit;
  }

  .top--light .main-nav > ul > li ul li:hover a {
    color: #111;
  }
}

@include media-breakpoint-up(lg) {
  .main-nav > ul > li ul {
    display: block !important;
  }
}
/*======================================
  #Search
======================================*/
.toggle-search a {
  font-size: 1.2rem !important;
  text-align: center;
  padding: 0 !important;
}

.mobile-actions {
  display: none;
  right: 50px;
  position: absolute;
  top: 0;

  @include media-breakpoint-down(md) {
    .mobile-actions {
      display: block;
    }
  }
}

.mobile-actions ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.mobile-actions ul li a {
  width: 50px;
  height: 50px;
  font-size: 19px !important;
  display: flex;
  color: white;
  line-height: 1;
  align-items: center;
  justify-content: center;
}

.top--light .mobile-actions ul li a {
  color: #111;
}

.top--scroll-dark .mobile-actions ul li a {
  color: #111;
}

.top--scroll-light .mobile-actions ul li a {
  color: white;
}

.scroll .top--light .mobile-actions ul li a {
  color: #111;
}

.scroll .top--dark .mobile-actions ul li a {
  color: white;
}
/*======================================
  #Toggle mobile nav (Hamburger)
======================================*/
.toggle-mobile-nav {
  width: 30px;
  position: relative;
  margin: 22px 0;
  display: none;
  z-index: 4;
  cursor: pointer;

  @include media-breakpoint-down(md) {
    display: block;
  }

  span {
    display: block;
    height: 3px;
    border-radius: 4px;
    background-color: $g-dark;
    margin-bottom: 5px;
    transition: all .4s;

    &:nth-child(2) {
      width: 80%;
    }

    &:last-child {
      margin-bottom: 0;
      width: 95%;
    }
  }
}




.top--light .toggle-mobile-nav span {
  background-color: $g-dark;
}

.top--scroll-dark .toggle-mobile-nav span {
  background-color: $g-dark;
}

.top--scroll-light .toggle-mobile-nav span {
  background-color: $g-light;
}

.scroll .top--light .toggle-mobile-nav span {
  background-color: $g-dark;
}

.scroll .top--dark .toggle-mobile-nav span {
  background-color: $g-light;
}

.mobile-nav-visible .toggle-mobile-nav span:nth-child(1) {
  transform: rotate(45deg) translateY(-3px);
  transform-origin: top left;
}

.mobile-nav-visible .toggle-mobile-nav span:nth-child(2) {
  opacity: 0;
  transform: translateX(-20px);
}

.mobile-nav-visible .toggle-mobile-nav span:nth-child(3) {
  transform: rotate(-45deg) translateY(3px);
  transform-origin: bottom left;
}


.top--light .main-nav, .scroll .top--light .main-nav {
  .level-2, .level-3 {
    background-color: $g-secondary;
    border-bottom: 1px solid white;

    &:last-child {
      border-bottom: none;
    }


    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    a {
      color: white;
      font-size: 1.1rem;
      padding: 10px 20px;

      i.fa-chevron-right {
        color: white;
        margin-left: 10px;
      }
    }
  }
}


.scroll {
  .main-nav {
    @include media-breakpoint-down(md) {
      top: $g-headerHeight + ($g-headerPadding * 2) + 8px;
    }
  }
}
