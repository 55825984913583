﻿/*======================================
  #Header
======================================*/
.top {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 500;
  transition: all .4s ease;

  &--light.transparent--bg {
    background: transparent;
    box-shadow: none;

    .logo--light {
      display: none;
    }

    .logo--dark {
      display: block;
    }
  }

  &--dark.transparent--bg {
    background: transparent;
    box-shadow: none;

    .logo--light {
      display: block;
    }

    .logo--dark {
      display: none;
    }
  }

  .top-inner {
    margin: 0 auto;
    padding: 0 40px;

    @include media-breakpoint-down(md) {
      padding: 0 20px;
    }
  }

  ul {
    list-style: none;
  }

  @include media-breakpoint-down(lg) {
    .container {
      max-width: none;
    }
  }
}

.top--light {
  background: $g-light;
  color: $g-dark;

  box-shadow: 0 0 35px rgba(0, 0, 0, 0.5);

  .logo--light {
    display: block;
    padding-left: 20px;

    @include media-breakpoint-down(md) {
      padding-left: 0px;
    }
  }

  .logo--dark {
    display: none;
  }
}

.top--dark {
  background: $g-dark;
  color: $g-light;

  .logo--dark {
    display: block;
  }

  .logo--light {
    display: none;
  }
}

.lang {
  justify-content: flex-end;

  input {
    padding-right: 5px;
    width: 25px;
    height: 25px;

    &:focus{
      outline:none;
    }
  }
}
/*======================================
  #Scroll
======================================*/
.scroll {
  .top {
    &--light {
      background: $g-light;
      box-shadow: 0 0 35px rgba(0, 0, 0, 0.5);


      .inner-wrapper {
        @include media-breakpoint-down(md) {
          padding-top: .8rem !important;
        }
      }

      .logo--light {
        display: block;
      }

      .logo--dark {
        display: none;
      }

      .logo {
        padding-left: 10px;

        @include media-breakpoint-down(md) {
          padding-left: 0px;
        }

        img {
          height: 60px;
          transform: scale(0.90) translateZ(0);
          transition: all .7s ease .1s;

          @include media-breakpoint-down(md) {
            height: 40px;
            transform: none;
          }
        }
      }
    }

    &--dark {
      background: $g-dark;
      box-shadow: 0 0 35px rgba(0, 0, 0, 0.5);

      .logo--light {
        display: none;
      }

      .logo--dark {
        display: block;
      }
    }
  }
}

.scroll-light {
  .logo--light {
    display: none;
  }

  .logo--dark {
    display: block;
  }
}

.scroll-dark {
  .logo--light {
    display: block;
  }

  .logo--dark {
    display: none;
  }
}
/*======================================
  #Logo
======================================*/
.logo {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all .3s ease;
  z-index: 5;

  img {
    height: 80px;
    display: block;
    max-width: 100%;

    @include media-breakpoint-down(md) {
      height: 50px;
    }
  }
}
/*======================================
  #Actions
======================================*/
.top {
  ul {
    list-style: none;
    margin: 0;
  }

  a {
    line-height: 6rem;
    outline: none;
  }

  .search-list {
    .toggle-search {
      @include media-breakpoint-down(md) {
        line-height: 4rem;
      }
    }

    a {
      @include media-breakpoint-down(md) {
        line-height: 3rem;
      }
    }
  }

  .btn {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1.1rem;
  }
}

  .actions {
    a:last-child {
      padding-right: 0;
    }

    @include media-breakpoint-down(lg) {
      position: absolute;
      bottom: 0px;
      right: 50px;

      a {
        line-height: 5.2rem;

        @include media-breakpoint-down(md) {
          line-height: 3rem;
        }

        i {
          font-size: 1.2rem;
        }
      }

      .btn {
        padding: 0.4rem;
      }
    }
  }

  .scroll {
    .actions, .search-list {
      a {
        line-height: 3rem;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .search-list {
      line-height: 3rem;
    }
  }
  /*======================================
  #No-Hero
======================================*/
  .no-hero .hero {
    min-height: $g-headerHeight + ($g-headerPadding * 2) + 30px;
    padding: 0px;

    .hero-inner {
      display: none;
    }
  }
